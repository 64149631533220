import React, {useEffect, useState} from "react";
import SideMenu from "../../components/SideMenu";
import Navbar from "../Navbar";
import { Box, Button, Grid, TextField } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import AgentMenu from "../../components/AgentMenu";
import { event, param } from "jquery";
import axios from "axios";
import { config } from "../../util/apiconfig";

// const initialValues = {
//   threshold: ''
// }
// const initialValues = [
//   { id: 1, APIthreshold: 55 },
//   { id: 2, facematchthres: 50},
//   { id: 3, bankcheque: 80},
// ];


function ApplicationConfig(){
  // var FormData = require('form-data');

    // const [passportThreshold, setPassportThreshold] = useState({
    //     threshold: ''
    // });
  

    const sailorInformation = yup.object().shape({
      passportThreshold: yup.string(),
      facematchThreshold: yup.string(),
      bankchequeTextThreshold: yup.string(),
    });

    
    

    const { register: sailorInfoHandler,
        handleSubmit: sailorInfoHandlerSubmit,
        reset: sailorInfoReset,
        setValue,
        control,        
        watch,
        formState: { errors: sailorInfoErrors } } = useForm({
          resolver: yupResolver(sailorInformation),
          // defaultValues: initialValues,
          mode: 'OnSubmit',         
          
        });

        // const handleSettingChange = (e) =>{
        //     console.log(e)
        //     // setPassportThreshold(event.target.value);
        // }

        // const onSubmitPersonalInfo = () =>{
        //     const allabove50percent = Object.values(passportThreshold).every((value)=>value >= 0.5);
        //     if(allabove50percent){
        //         alert("SAVED", passportThreshold);
        //     }
        //     else{
        //         alert('Some values are below 50%. Please adjust.');
        //     }
        // }

    const [thresholdData, setThresholdData] = useState('');
    const [companyno, setCompanyno] = useState('');
    const [passthres, setPassthes] = useState('');
    const [facematchthres, setFacematchthres] = useState('');
    const [bankchequethres, setBankchequethres] = useState('');
    
    

  //   const datas = () =>{
  //   initialValues.map((res)=>{
  //     console.log(res.APIthreshold)
  //     const resData = res.APIthreshold             
  //     setThresholdData(resData)        
  //   })
  // }

        // const onSubmitPersonalInfo = (data) =>{
        //       console.log('Settings Submitted:', data);
        //       // const allabove50percent = Object.values(data).map((val)=>{
        //       //     if(val >= 50){
        //       //       alert("SAVED")
        //       //     }
        //       //     else{
        //       //       alert('Some values are below 50%. Please adjust.');
        //       //     }
        //       // })
        //       // console.log(allabove50percent)
        //       }

        async function getThresholdData(){
          let getCompanycode = config.companyCode
          const thresin = {
            'companyCode':getCompanycode,    
         }
         console.log(thresin)
          await axios.post(config.mongo_endpoint+'api/v1/appconfig/all', thresin,
          { 
            headers: {'Content-Type' : 'application/x-www-form-urlencoded', 'x-access-token':localStorage.getItem('AgentToken')} }
            
          )
           .then(res=>{
            console.log(res.data)
            const resultthres = res.data;
            resultthres.map((resresult)=>{
              console.log(resresult)
              setPassthes(resresult.passport_threshold);
              setFacematchthres(resresult.facematch_threshold);
              setBankchequethres(resresult.bankchequeText_threshold);
            })
          })         
          
      }
        
      useEffect(()=>{
        getThresholdData();
      }, [])

        

        const onSubmit = async(data) =>{        
          
          try{           
            // thresholdData.append('companycode')          
            // let thresholddata = new FormData();
            // thresholdData.append('', )
      //       const companyc = await axios.post(config.companyCode, { headers: { 'Content-Type': 'multipart/form-data'} });
      // console.log(companyc)
      console.log("***COn", config.companyCode)
      const getCompanycode = config.companyCode
      // console.log(getCompanycode)
      // setCompanyno(getCompanycode);
            const thresinfo = {
              companyCode:getCompanycode,             
              passport_threshold :data["passportThreshold"],
              facematch_threshold :data["facematchThreshold"],
              bankchequeText_threshold :data["bankchequeTextThreshold"],
              updated_by:localStorage.getItem('userId'),
            }
            console.log(thresinfo)
            await axios.post(config.mongo_endpoint+'api/v1/appconfig/update', thresinfo, 
            { headers: {'Content-Type' : 'application/x-www-form-urlencoded', 'x-access-token':localStorage.getItem('AgentToken')} })
            .then(result =>{
              console.log("RESULT", result)
              if(result.status == 200 && result.data.status === "Success"){
                toast.message("Application Submitted successfully.", {
                  position: toast.POSITION.TOP_CENTER,
                  theme: "colored",
                });
              }else{
                toast.success("Some Thing went wrong try again later.", {
                  position: toast.POSITION.TOP_CENTER,
                  theme: "colored",
                });  
              }
            })            
          }
          catch(error){
            console.log(error)
          }
        }       

        
    return(
        <>
        <AgentMenu></AgentMenu>
        <div className="container-fluid pr-0" id="main-area">
<Navbar />
<div className="container-fluid">
    <div className="d-flex border-bottom pb-15 pt-15">
        <div className="me-auto ">
            <h2 className="page-title">Application Configuration</h2>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb  m-0">
                    {/* <li className="breadcrumb-item"><a href="#">breadcrumb 01</a></li>
                    <li className="breadcrumb-item"><a href="#">breadcrumb 02</a></li> */}
                    {/* <li className="breadcrumb-item active" aria-current="page">Update Details </li> */}
                </ol>
            </nav>
        </div>

    </div>
    <section className="voting-section">
        <div className="container">
          <div className="row">

            {/* <h1>Voting</h1> */}
            <Box sx={{ width: '100%' }}>    
                <>
                   <div className="stepper-content">
                    
                      <form onSubmit={sailorInfoHandlerSubmit(onSubmit)}>                      
                      <Box mb={3.5}>
                        <Grid container spacing={3}>
                          <Grid item sm={4} xs={12}>                        
                            {/* <input type="hidden" name="companycode" value="companyno" {...sailorInfoHandler("companyCode")} />                             */}
                            <TextField
                              size="small"
                              fullWidth
                              type="text"
                              value={passthres}
                              name="passportThreshold"
                              label="Passport API Threshold"                                                           
                              {...sailorInfoHandler("passportThreshold")}
                              id="outlined-error"
                              error={!!sailorInfoErrors['passportThreshold']}
                              helperText={sailorInfoErrors['passportThreshold'] ? sailorInfoErrors['passportThreshold'].message : ''}
                              onChange={(e)=>setPassthes(e.target.value)}
                            />
                          </Grid>     

                          <Grid item sm={4} xs={12}>                            
                            <TextField
                              size="small"
                              fullWidth
                              type="text"
                              value={facematchthres}
                              name="facematchThreshold"
                              label="FaceMatch API Threshold"                                                          
                              {...sailorInfoHandler("facematchThreshold")}
                              id="outlined-error"
                              error={!!sailorInfoErrors['facematchThreshold']}
                              helperText={sailorInfoErrors['facematchThreshold'] ? sailorInfoErrors['facematchThreshold'].message : ''}
                              onChange={(e)=>setFacematchthres(e.target.value)}
                            />
                          </Grid>

                          <Grid item sm={4} xs={12}>                            
                            <TextField
                              size="small"
                              fullWidth
                              type="text"
                              value={bankchequethres}
                              name="bankchequeTextThreshold"
                              label="Bankcheque Text Threshold"                                                          
                              {...sailorInfoHandler("bankchequeTextThreshold")}
                              id="outlined-error"
                              error={!!sailorInfoErrors['bankchequeTextThreshold']}
                              helperText={sailorInfoErrors['bankchequeTextThreshold'] ? sailorInfoErrors['bankchequeTextThreshold'].message : ''}
                              onChange={(e)=>setBankchequethres(e.target.value)}
                            />
                          </Grid>                    
                      
                        </Grid>
                      </Box>
                      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button variant="contained" color="primary" type="submit">
                          Save
                        </Button>
                      </div>
                      </form>                      
                     
                      {/* <ToastContainer /> */}
                    </div>
                 
                  
                </>
          
            </Box>
          </div>
        </div>
      </section>

</div>
</div>
        </>
    )
}

export default ApplicationConfig;