import React, { useState, useEffect, useContext } from "react";
import { Box } from "@mui/material";
import AgentMenu from "../../components/AgentMenu";
import NoteContext from "../../context/NoteContext";
import Button from '@mui/material/Button';
import { FormHelperText, Grid, TextField } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useForm } from "react-hook-form";
import 'react-phone-input-2/lib/material.css'
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import Navbar from "../Navbar";
import axios from 'axios';
import { config } from "../../util/apiconfig";
import swal from 'sweetalert';





function AddAgents() {
    // const { AgentId } = match.params;
    // const isAddMode = !AgentId;
    // =================== Voter Info Tab-1 Validation  ================

    const sailorInformation = yup.object().shape({
        first_name: yup.string().required("First Name Required"),
        phone: yup.string().required("Phone Required"),
        password: yup.string().required("Password Required"),
        email: yup.string().required("Email Required"),
        company_name: yup.string().required("Company Name Required"),
        user_role: yup.string().required("Role Required"),

    })

    // =================== Voter Info Tab-1 Validation  ================
    // =================== Voter Info Yup Resolver Starts  ================
    const { register: sailorInfoHandler,
        handleSubmit: sailorInfoHandlerSubmit,
        reset: voterInfoReset,
        setValue,
        formState: { errors: sailorInfoErrors } } = useForm({
            resolver: yupResolver(sailorInformation),
            mode: 'OnSubmit',
        });





    const obj = useContext(NoteContext)
    const [data, setData] = useState([]);
    const [roleData, setRoleData] = useState([]);
    const [readonlyCompany, setReadonlyCompany] = useState(false);
    const [companyName, setCompanyName] = useState('');


    const getCompanyList = async () => {
        let companyData = await fetch(config.mongo_endpoint + "api/v1/company/getall", {
            method: "GET",
            headers: {
                "x-access-token": obj.AgentToken,
                "Authorization" : obj.AgentToken,
                // "x-access-token" : "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyaWQiOiI2NGRhMjA1NWVlOWQyYzIxNTBhMWQyMzkiLCJpYXQiOjE2OTc2MTk2MjR9.AywLCafWb2JxXVUfDcwAaewGxQ9wFXj_-kUG2lyNz08"
            }
        });

        let resultCompanyData = await companyData.json();
        if (resultCompanyData.status === 'Success') {
            const companylist = resultCompanyData.data;
            setData(companylist);
        }
        //   console.log(resultCompanyData)
    }

    const getRoleList = async () => {
        let roleData = await fetch(config.mongo_endpoint + "api/v1/roles/getRoles", {
            method: "GET",
            headers: {
                "x-access-token": obj.AgentToken,
                "Authorization":obj.AgentToken,
                // "x-access-token" : "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyaWQiOiI2NGRhMjA1NWVlOWQyYzIxNTBhMWQyMzkiLCJpYXQiOjE2OTc2MTk2MjR9.AywLCafWb2JxXVUfDcwAaewGxQ9wFXj_-kUG2lyNz08"
            }
        });

        let resultRoleData = await roleData.json();
        if (resultRoleData.status === 'Success') {
            //const rolelist = resultRoleData.data;
            const rolelist = resultRoleData.data.filter(role => role.roleId !== 0);
        
            setRoleData(rolelist);
        }
        //   console.log(resultCompanyData)
    }


    useEffect(() => {
        getCompanyList();
        getRoleList();
        const logintype = obj.type;
        const logincompany = localStorage.getItem('company_code');
        if (logintype !== 0) {
            setCompanyName(logincompany);
            setValue('company_name',logincompany);
            setReadonlyCompany(true);
        }
    }, [])


    const navigate = useNavigate();
    const LandingScreen = () => {
        navigate("/ManageAgent");
    }


    const onSubmit = async (data) => {
        console.log(data)
        const datainfo = {
            CompanyCode: data["company_name"],
            CompanyName: data["company_name"],
            First_Name: data["first_name"],
           // Middle_name: data["middle_name"],
            Last_Name: data["last_name"],
            Phone: data["phone"],
            Password: data["password"],
            Email: data["email"],
            Type: data["user_role"],

            userId: localStorage.getItem("userId"),
        }
        console.log(datainfo)
        await axios.post(config.mongo_endpoint + 'api/v1/staff/CreateStaff', datainfo,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': obj.AgentToken,
                    'x-access-token': obj.AgentToken
                }
            })
            .then(result => {
                console.log(result)
                if (result.status == 200 && result.data.status == "Success") {
                    
                    swal("User added Successfully....!", {
                        icon: "success",
                    });
                    LandingScreen();

                } else {
                    
                    swal("User create Failed ....!", {
                        icon: "warning",
                    });
                }


            })
            .catch(error => {
                // handle the error
                console.error(error);
                if (error) {

                    //     toast.error("Some thing When wrong. Please try again Later....", {
                    //     position: toast.POSITION.TOP_CENTER,
                    //     theme: "colored",
                    //   });
                    swal("Some thing When wrong. Please try again Later....", {
                        icon: "warning",
                    });

                }

            });

    }



    const handleCompanyChange = (event) => {
//console.log(event.target.value, 'afterchange')
        setCompanyName(event.target.value);
    };

    return (
        <>
            <AgentMenu></AgentMenu>
            <div className="container-fluid pr-0" id="main-area">
                <Navbar />
                <div className="container-fluid">
                    <div className="d-flex border-bottom pb-15 pt-15">
                        <div className="me">
                            <h2 className="page-title">Add Staff</h2>
                        </div>

                    </div>
                    <section className="voting-section">
                        <div className="container">
                            <div className="row">

                                {/* <h1>Voting</h1> */}
                                <Box sx={{ width: '100%' }}>
                                    <>
                                        <div className="mto stepper-content">

                                            <form onSubmit={sailorInfoHandlerSubmit(onSubmit)}>
                                                <Box mb={3.5}>
                                                
                                                    <Grid container spacing={3} marginTop={1}>
                                                    <Grid item sm={4} xs={12}>
                                                            <FormControl fullWidth>
                                                                <InputLabel id="demo-simple-select-label">Role *</InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    size="small"
                                                                    name="user_role"
                                                                    label="Role *"
                                                                    {...sailorInfoHandler("user_role")}
                                                                    error={!!sailorInfoErrors['user_role']}
                                                                    helperText={sailorInfoErrors['user_role'] ? sailorInfoErrors['user_role'].message : ''}
                                                                >
                                                                    {roleData.map((res) => (
                                                                        <MenuItem value={res.roleId}>{res.name}</MenuItem>
                                                                    ))}

                                                                </Select>
                                                                {sailorInfoErrors.user_role && <FormHelperText error={!!sailorInfoErrors['user_role']}>{sailorInfoErrors.user_role.message}</FormHelperText>}
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item sm={4} xs={12}>
                                                            <FormControl fullWidth>
                                                                <InputLabel id="demo-simple-select-label">Company *</InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    size="small"
                                                                    name="company_name"
                                                                    label="Company Name *"
                                                                    
                                                                    value={companyName}
                                                                    readOnly={readonlyCompany}

                                                                    {...sailorInfoHandler("company_name")}
                                                                    error={!!sailorInfoErrors['company_name']}
                                                                    helperText={sailorInfoErrors['company_name'] ? sailorInfoErrors['company_name'].message : ''}
                                                                   onChange={handleCompanyChange}
                                                                >
                                                                    {data.map((res) => (
                                                                        <MenuItem value={res.company_code}>{res.company_name}</MenuItem>
                                                                    ))}

                                                                </Select>
                                                                {sailorInfoErrors.company_name && <FormHelperText error={!!sailorInfoErrors['company_name']}>{sailorInfoErrors.company_name.message}</FormHelperText>}
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item sm={4} xs={12}>
                                                            <TextField
                                                                fullWidth
                                                                size="small"
                                                                type="text"
                                                                name="first_name"
                                                                label="First Name *"
                                                                {...sailorInfoHandler("first_name")}
                                                                id="outlined-error"
                                                                error={!!sailorInfoErrors['first_name']}
                                                                helperText={sailorInfoErrors['first_name'] ? sailorInfoErrors['first_name'].message : ''}
                                                            />
                                                        </Grid>
                                                        {/* <Grid item sm={4} xs={12}>
                                                            <TextField
                                                                fullWidth
                                                                size="small"
                                                                type="text"
                                                                name="middle_name"
                                                                label="Middle Name "
                                                                {...sailorInfoHandler("middle_name")}
                                                                id="outlined-error"
                                                                error={!!sailorInfoErrors['middle_name']}
                                                                helperText={sailorInfoErrors['middle_name'] ? sailorInfoErrors['middle_name'].message : ''}
                                                            />
                                                        </Grid> */}
                                                        <Grid item sm={4} xs={12}>
                                                            <TextField
                                                                fullWidth
                                                                size="small"
                                                                type="text"
                                                                name="last_name"
                                                                label="Last Name "
                                                                {...sailorInfoHandler("last_name")}
                                                                id="outlined-error"
                                                                error={!!sailorInfoErrors['last_name']}
                                                                helperText={sailorInfoErrors['last_name'] ? sailorInfoErrors['last_name'].message : ''}
                                                            />
                                                        </Grid>



                                                        <Grid item sm={4} xs={12}>
                                                            <TextField
                                                                fullWidth
                                                                size="small"
                                                                type="text"
                                                                name="phone"
                                                                label="Phone *"
                                                                {...sailorInfoHandler("phone")}
                                                                id="outlined-error"
                                                                error={!!sailorInfoErrors['phone']}
                                                                helperText={sailorInfoErrors['phone'] ? sailorInfoErrors['phone'].message : ''}
                                                            />
                                                        </Grid>

                                                        <Grid item sm={4} xs={12}>
                                                            <TextField
                                                                fullWidth
                                                                size="small"
                                                                type="email"
                                                                name="email"
                                                                label="Email *"
                                                                {...sailorInfoHandler("email")}
                                                                id="outlined-error"
                                                                error={!!sailorInfoErrors['email']}
                                                                helperText={sailorInfoErrors['email'] ? sailorInfoErrors['email'].message : ''}
                                                            />
                                                        </Grid>
                                                        

                                                        <Grid item sm={4} xs={12}>
                                                            <TextField
                                                                fullWidth
                                                                size="small"
                                                                type="text"
                                                                name="password"
                                                                label="Password *"
                                                                {...sailorInfoHandler("password")}
                                                                id="outlined-error"
                                                                error={!!sailorInfoErrors['password']}
                                                                helperText={sailorInfoErrors['password'] ? sailorInfoErrors['password'].message : ''}
                                                            />
                                                        </Grid>

                                                    </Grid>



                                                </Box>

                                                <div sx={{ display: 'flex', flexDirection: 'row', padding: '10px 10px', background: "#f5f5f5" }}>

                                                    <Button type="submit" variant="contained" color="success" sx={{ marginTop: "20px", marginLeft: "18px" }}>
                                                        SUBMIT
                                                    </Button>
                                                </div>
                                            </form>


                                        </div>


                                    </>

                                </Box>
                            </div>
                        </div>
                    </section>

                </div>
            </div>
        </>
    );
}

export default AddAgents;