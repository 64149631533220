import React, { useState, useEffect, useContext } from "react";
import { Box } from "@mui/material";
import AgentMenu from "../../components/AgentMenu";
import NoteContext from "../../context/NoteContext";
import Button from '@mui/material/Button';
import { FormHelperText, Grid, TextField } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useForm } from "react-hook-form";
import 'react-phone-input-2/lib/material.css'
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import Navbar from "../Navbar";
import axios from 'axios';
import { config } from "../../util/apiconfig";
import swal from 'sweetalert';
import handleApiError from "../../util/apiUtil";





function AddRoles() {
    // const { AgentId } = match.params;
    // const isAddMode = !AgentId;
    // =================== Voter Info Tab-1 Validation  ================

    const sailorInformation = yup.object().shape({

        role_name: yup.string().required("Name Required"),

    })

    // =================== Voter Info Tab-1 Validation  ================
    // =================== Voter Info Yup Resolver Starts  ================
    const { register: sailorInfoHandler,
        handleSubmit: sailorInfoHandlerSubmit,
        reset: voterInfoReset,
        setValue,
        formState: { errors: sailorInfoErrors } } = useForm({
            resolver: yupResolver(sailorInformation),
            mode: 'OnSubmit',
        });





    const obj = useContext(NoteContext)
    const [data, setData] = useState([]);


    const getCompanyList = async () => {
        try {
            const response = await axios.get(config.mongo_endpoint + "api/v1/company/getall", {
                headers: {
                    "Authorization": obj.AgentToken
                }
            });

            if (response.status === 200) {
                const resultCompanyData = response.data;

                if (resultCompanyData.status === 'Success') {
                    const companylist = resultCompanyData.data;
                    setData(companylist);
                } else {
                    throw new Error('Request was not successful: ' + resultCompanyData.message);
                }
            } else {
                throw new Error('Failed to fetch company data: ' + response.status);
            }
        } catch (error) {
            console.error('Error fetching company data:', error);
            // handleApiError(error, navigate);
        }
    }

    useEffect(() => {
        getCompanyList();
    }, [])


    const navigate = useNavigate();
    const LandingScreen = () => {
        navigate("/Roles");
    }


    const onSubmit = async (data) => {

        const rolesdatainfo = {
            //company_code : data["company_name"],

            role_name: data["role_name"],

            userId: localStorage.getItem("userId"),
        }
        //console.log(rolesdatainfo)
        await axios.post(config.mongo_endpoint + 'api/v1/roles/addRole', rolesdatainfo,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'x-access-token': obj.AgentToken
                }
            })
            .then(result => {
                //console.log(result)
                if (result.status == 200 && result.data.status == "Success") {

                    swal("Role added Successfully....!", {
                        icon: "success",
                    });
                    LandingScreen();

                } else {

                    swal("Role create Failed ....!", {
                        icon: "warning",
                    });
                }


            })
            .catch(error => {
                // handle the error
                console.error(error);
                handleApiError(error, navigate)
                if (error) {


                    swal("Some thing When wrong. Please try again Later....", {
                        icon: "warning",
                    });

                }

            });

    }




    {

    }







    return (
        <>
            <AgentMenu></AgentMenu>
            <div className="container-fluid pr-0" id="main-area">
                <Navbar />
                <div className="container-fluid">
                    <div className="d-flex border-bottom pb-15 pt-15">
                        <div className="me">
                            <h2 className="page-title">Add Role</h2>
                        </div>

                    </div>
                    <section className="voting-section">
                        <div className="container">
                            <div className="row">

                                {/* <h1>Voting</h1> */}
                                <Box sx={{ width: '100%' }}>
                                    <>
                                        <div className="mto stepper-content">

                                            <form onSubmit={sailorInfoHandlerSubmit(onSubmit)}>
                                                <Box mb={3.5}>
                                                    <Grid container spacing={3} marginTop={1}>
                                                        {/*  <Grid item sm={4} xs={12}>
                                                            <FormControl fullWidth>
                                                                <InputLabel id="demo-simple-select-label">Company *</InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    size="small"
                                                                    name="company_name"
                                                                    label="Company Name *"
                                                                    {...sailorInfoHandler("company_name")}
                                                                    error={!!sailorInfoErrors['company_name']}
                                                                    helperText={sailorInfoErrors['company_name'] ? sailorInfoErrors['company_name'].message : ''}
                                                                  >
                                                                    {data.map((res) => (
                                                                      <MenuItem value={res.company_code}>{res.company_name}</MenuItem>
                                                                    ))}
                              
                                                                  </Select>
                                                                  {sailorInfoErrors.company_name && <FormHelperText error={!!sailorInfoErrors['company_name']}>{sailorInfoErrors.company_name.message}</FormHelperText>}
                                                                </FormControl>
                                                        </Grid>                                                 */}

                                                        <Grid item sm={4} xs={12}>
                                                            <TextField
                                                                fullWidth
                                                                size="small"
                                                                type="text"
                                                                name="role_name"
                                                                label="Role Name *"
                                                                {...sailorInfoHandler("role_name")}
                                                                id="outlined-error"
                                                                error={!!sailorInfoErrors['role_name']}
                                                                helperText={sailorInfoErrors['role_name'] ? sailorInfoErrors['role_name'].message : ''}
                                                            />
                                                        </Grid>



                                                    </Grid>



                                                </Box>

                                                <div sx={{ display: 'flex', flexDirection: 'row', padding: '10px 10px', background: "#f5f5f5" }}>

                                                    <Button type="submit" variant="contained" color="success" sx={{ marginTop: "20px", marginLeft: "18px" }}>
                                                        SUBMIT
                                                    </Button>
                                                </div>
                                            </form>


                                        </div>


                                    </>

                                </Box>
                            </div>
                        </div>
                    </section>

                </div>
            </div>
        </>
    );
}

export default AddRoles;