import React, { useState, useEffect, useContext } from "react";
import { Box } from "@mui/material";
import AgentMenu from "../../components/AgentMenu";
import NoteContext from "../../context/NoteContext";
import Button from '@mui/material/Button';
import { FormHelperText, Grid, TextField } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useForm } from "react-hook-form";
import 'react-phone-input-2/lib/material.css'
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import Navbar from "../Navbar";
import axios from 'axios';
import { config } from "../../util/apiconfig";
import swal from 'sweetalert';
import handleApiError from "../../util/apiUtil";


function UpdateAgents() {
    // const { AgentId } = match.params;
    // const isAddMode = !AgentId;
    // =================== Voter Info Tab-1 Validation  ================

    const sailorInformation = yup.object().shape({
        first_name: yup.string().required("First Name Required"),
        phone: yup.string().required("Phone Required"),
        //password: yup.string().required("Password Required"),
        email: yup.string().required("Email Required"),
        company_name: yup.string().required("Company Name Required"),
        user_role: yup.string().required("Role Required"),

    })

    // =================== Voter Info Tab-1 Validation  ================
    // =================== Voter Info Yup Resolver Starts  ================
    const { register: sailorInfoHandler,
        handleSubmit: sailorInfoHandlerSubmit,
        reset: voterInfoReset,
        setValue,
        formState: { errors: sailorInfoErrors } } = useForm({
            resolver: yupResolver(sailorInformation),
            mode: 'OnSubmit',
        });

    //   console.log(sailorInfoErrors)

    const [activeStep, setActiveStep] = React.useState(0);
    const [Agree, setAgree] = useState('')
    const [loader, setLoader] = useState(false)
    const [user, setUser] = useState({});


    const [updatedata, setUpdatedata] = useState('');
    const [activestatus, setActivestatus] = useState('');





    const [companyData, setcompanyData] = useState([]);
    const [roleData, setRoleData] = useState([]);
    const [rolevalue, setRolevalue] = useState('');


    const getCompanyList = async () => {
        try {
            const response = await axios.get(config.mongo_endpoint + "api/v1/company/getall", {
                headers: {
                    "Authorization": obj.AgentToken
                }
            });
    
            const resultCompanyData = response.data;
    
            if (resultCompanyData.status === 'Success') {
                const companyList = resultCompanyData.data;
                setcompanyData(companyList); 
            }
    
        } catch (error) {
            
             handleApiError(error, navigate); 
        }
    };

    const getRoleList = async () => {
        try {
            const response = await axios.get(config.mongo_endpoint + "api/v1/roles/getRoles", {
                headers: {
                    "x-access-token": obj.AgentToken,
                    "Authorization": obj.AgentToken
                }
            });
    
            const resultRoleData = response.data;
    
            if (resultRoleData.status === 'Success') {
                const roleList = resultRoleData.data.filter(role => role.roleId !== 0);
                setRoleData(roleList); 
            }
        } catch (error) {

             handleApiError(error, navigate); 
        }
    };

    useEffect(() => {
        getCompanyList();
        getRoleList();
    }, [])




    const obj = useContext(NoteContext)
    const [data, setData] = useState([]);
    const [update_company, setUpdate_company] = useState(obj.update_company)
    const [company, setCompany] = useState('');
    const [readonlyCompany, setReadonlyCompany] = useState(false);

    //console.log("UPDATECOMPANY=>", update_company)


    const navigate = useNavigate();
    const LandingScreen = () => {
        navigate("/ManageAgent");
    }

    useEffect(() => {
        const logintype = obj.type;
        const logincompany = localStorage.getItem('company_code');
        
        setValue('first_name', update_company.First_Name);
        //setValue('middle_name', update_company.Middle_Name);
        setValue('last_name', update_company.Last_Name);
        setValue('email', update_company.Email);
        setValue('phone', update_company.Phone);
        setValue('user_role', update_company.Type);
        setUpdatedata(update_company.CompanyCode);
        setValue('company_name', update_company.CompanyCode);
        setValue('staffId',update_company.staffId);
        setCompany(update_company.CompanyCode);
        setRolevalue(update_company.Type);

        if (logintype !== 0) {
            setCompany(logincompany);
            setValue('company_name',logincompany);
            setReadonlyCompany(true);
        }

        setValue('active_status', update_company.active_status);
        setActivestatus(update_company.active_status);

    }, [])

    //console.log(company)

    const handleCompanyChange = (event) => {
        //console.log(event.target.value, 'afterchange')
        setCompany(event.target.value);
    };

    const handleRoleChange = (event) => {
        setRolevalue(event.target.value);
    }

    const onSubmit = async (data) => {
        try {
            const companyInformation = {
                CompanyCode: data["company_name"],
                CompanyName: data["company_name"],
                First_Name: data["first_name"],
               // middle_name: data["middle_name"],
                Last_Name: data["last_name"],
                Phone: data["phone"],
                Password: data["password"],
                Email: data["email"],
                Type: data["user_role"],
                staffId : data['staffId'],
                activeStatus: activestatus,
            }
           // console.log(companyInformation)

            await axios.post(config.mongo_endpoint + 'api/v1/staff/update', companyInformation, { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': obj.AgentToken } })
                .then(result => {
                    //console.log(result,'res');
                    if ( result.data.status == 'Success') {
                        // toast.success("Department Updated Successfully.", {
                        //     position: toast.POSITION.TOP_CENTER,
                        //     theme: "colored",
                        // });
                        swal("User updated Successfully....!", {
                            icon: "success",
                        });
                        LandingScreen();
                    } else {
                        // toast.success("Duplicate Data Exist", {
                        //     position: toast.POSITION.TOP_CENTER,
                        //     theme: "colored",
                        // });
                        swal("User update Failed ....!", {
                            icon: "warning",
                        });
                    }
                })





        }
        catch (error) {
            handleApiError(error,navigate)
            setLoader(false);
            if (error) {
                alert("Something went wrong. Please try again Later....")
            }
            

        }

    }


    return (
        <>
            <AgentMenu></AgentMenu>
            <div className="container-fluid pr-0" id="main-area">
                <Navbar />
                <div className="container-fluid">
                    <div className="d-flex border-bottom pb-15 pt-15">
                        <div className="me">
                            <h2 className="page-title">Update Staff</h2>
                        </div>

                    </div>
                    <section className="voting-section">
                        <div className="container">
                            <div className="row">

                                {/* <h1>Voting</h1> */}
                                <Box sx={{ width: '100%' }}>
                                    <>
                                        <div className="mto stepper-content">

                                            <form onSubmit={sailorInfoHandlerSubmit(onSubmit)}>
                                                <Box mb={3.5}>
                                                    <Grid container spacing={3} marginTop={1}>
                                                        
                                                    <Grid item sm={4} xs={12}>
                                                            <FormControl fullWidth>
                                                                <InputLabel id="demo-simple-select-label">Role *</InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    size="small"
                                                                    name="user_role"
                                                                    label="Role *"
                                                                    value={rolevalue}
                                                                    {...sailorInfoHandler("user_role")}
                                                                    error={!!sailorInfoErrors['user_role']}
                                                                    helperText={sailorInfoErrors['user_role'] ? sailorInfoErrors['user_role'].message : ''}
                                                                    onChange={handleRoleChange}
                                                                >
                                                                    {roleData.map((res) => (
                                                                        <MenuItem value={res.roleId}>{res.name}</MenuItem>
                                                                    ))}

                                                                </Select>
                                                                {sailorInfoErrors.user_role && <FormHelperText error={!!sailorInfoErrors['user_role']}>{sailorInfoErrors.user_role.message}</FormHelperText>}
                                                            </FormControl>
                                                        </Grid>

                                                        <Grid item sm={4} xs={12}>
                                                            <input type="hidden" name="active_status" value="hiddenValue" {...sailorInfoHandler("active_status")} />
                                                            <input type="hidden" name="staffId" value=""  />

                                                            <FormControl fullWidth>
                                                                <InputLabel id="demo-simple-select-label">Company *</InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    size="small"
                                                                    name="company_name"
                                                                    value={company}
                                                                    readOnly={readonlyCompany}
                                                                    
                                                                    label="Company Name *"
                                                                    {...sailorInfoHandler("company_name")}
                                                                    error={!!sailorInfoErrors['company_name']}
                                                                    helperText={sailorInfoErrors['company_name'] ? sailorInfoErrors['company_name'].message : ''}
                                                                    onChange={handleCompanyChange}
                                                                >
                                                                    {companyData.map((list) => (
                                                                        <MenuItem key={list.company_code} value={list.company_code}>
                                                                            {list.company_name}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                                {sailorInfoErrors.company_name && <FormHelperText error={!!sailorInfoErrors['company_name']}>{sailorInfoErrors.company_name.message}</FormHelperText>}

                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item sm={4} xs={12}>
                                                            <TextField
                                                                fullWidth
                                                                size="small"
                                                                type="text"
                                                                name="first_name"
                                                                label="First Name *"
                                                                {...sailorInfoHandler("first_name")}
                                                                id="outlined-error"
                                                                error={!!sailorInfoErrors['first_name']}
                                                                helperText={sailorInfoErrors['first_name'] ? sailorInfoErrors['first_name'].message : ''}
                                                            />
                                                        </Grid>
                                                        {/* <Grid item sm={4} xs={12}>
                                                            <TextField
                                                                fullWidth
                                                                size="small"
                                                                type="text"
                                                                name="middle_name"
                                                                label="Middle Name "
                                                                {...sailorInfoHandler("middle_name")}
                                                                id="outlined-error"
                                                                error={!!sailorInfoErrors['middle_name']}
                                                                helperText={sailorInfoErrors['middle_name'] ? sailorInfoErrors['middle_name'].message : ''}
                                                            />
                                                        </Grid> */}
                                                        <Grid item sm={4} xs={12}>
                                                            <TextField
                                                                fullWidth
                                                                size="small"
                                                                type="text"
                                                                name="last_name"
                                                                label="Last Name "
                                                                {...sailorInfoHandler("last_name")}
                                                                id="outlined-error"
                                                                error={!!sailorInfoErrors['last_name']}
                                                                helperText={sailorInfoErrors['last_name'] ? sailorInfoErrors['last_name'].message : ''}
                                                            />
                                                        </Grid>



                                                        <Grid item sm={4} xs={12}>
                                                            <TextField
                                                                fullWidth
                                                                size="small"
                                                                type="text"
                                                                name="phone"
                                                                label="Phone *"
                                                                {...sailorInfoHandler("phone")}
                                                                id="outlined-error"
                                                                error={!!sailorInfoErrors['phone']}
                                                                helperText={sailorInfoErrors['phone'] ? sailorInfoErrors['phone'].message : ''}
                                                            />
                                                        </Grid>

                                                        <Grid item sm={4} xs={12}>
                                                            <TextField
                                                                fullWidth
                                                                size="small"
                                                                type="email"
                                                                name="email"
                                                                label="Email *"
                                                                {...sailorInfoHandler("email")}
                                                                id="outlined-error"
                                                                error={!!sailorInfoErrors['email']}
                                                                helperText={sailorInfoErrors['email'] ? sailorInfoErrors['email'].message : ''}
                                                            />
                                                        </Grid>

                                                        <Grid item sm={4} xs={12}>
                                                            <TextField
                                                                fullWidth
                                                                size="small"
                                                                type="text"
                                                                name="password"
                                                                label="Password "
                                                                {...sailorInfoHandler("password")}
                                                                id="outlined-error"
                                                                error={!!sailorInfoErrors['password']}
                                                                helperText={sailorInfoErrors['password'] ? sailorInfoErrors['password'].message : ''}
                                                            />
                                                        </Grid>

                                                    </Grid>


                                                </Box>

                                                <div sx={{ display: 'flex', flexDirection: 'row', padding: '10px 10px', background: "#f5f5f5" }}>

                                                    <Button type="submit" variant="contained" color="success" sx={{ marginTop: "20px", marginLeft: "18px" }}>
                                                        SUBMIT
                                                    </Button>
                                                </div>
                                            </form>


                                        </div>


                                    </>

                                </Box>
                            </div>
                        </div>
                    </section>

                </div>
            </div>
        </>
    );
}

export default UpdateAgents;