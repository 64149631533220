import React, { useState, useEffect, useContext } from "react";
import Topnav from "../../common/topNav";
import { Link,useNavigate} from "react-router-dom";
// import Footer from "../../common/footer";
// import Banner from "../../components/banner";
import Ham from "../../assets/images/sailor/ham.svg";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AgentMenu from "../../components/AgentMenu";
import NoteContext from "../../context/NoteContext";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Button from '@mui/material/Button';
import { config } from "../../util/apiconfig";
import axios from 'axios';
import Navbar from "../Navbar";
import swal from 'sweetalert';
import Moment from 'react-moment';
import handleApiError from "../../util/apiUtil";


function ManageRoles() {
    const obj = useContext(NoteContext);
    const [data, setData] = useState([]);
    const navigate=useNavigate();

    const getRolesList = async () => {
        try {
            const response = await axios.get(config.mongo_endpoint + "api/v1/roles/getRoles", {
                headers: {
                    "x-access-token": obj.AgentToken,
                    "Authorization": obj.AgentToken
                }
            });
    
            if (response.status === 200) {
                const resultrolesData = response.data;
                if (resultrolesData.status === 'Success') {
                    const roleslist = resultrolesData.data.filter(role => role.roleId !== 0);
                    setData(roleslist);
                } else {
                    throw new Error('Failed to retrieve roles data: ' + resultrolesData.message);
                }
            } else {
                throw new Error('Failed to fetch roles data');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
             handleApiError(error, navigate);
        }
    }

    useEffect(() => {
        getRolesList();
    }, [])


    const deleteRole = async (id) => {
       // console.log(id)
        const rolesdatas = {
            role_id: id,
        }
       // console.log(rolesdatas)
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this imaginary file!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    axios.post(config.mongo_endpoint + "api/v1/roles/deleteRole", rolesdatas, {
                        headers: { "Content-type": "multipart/form-data", "Authorization": obj.AgentToken },
                    })

                    swal("Roles Name Deleted....!", {
                        icon: "success",
                    });
                    getRolesList();


                } else {
                    swal("Cancelled");
                }
                getRolesList();
            });

    }

    async function editCompany(resdata) {
       // console.log("ROWEDIT**", resdata)
        obj.setUpdate_company(resdata)
        obj.setUpdatingCompanyFlag(1);
    }

    return (
        <>

            <AgentMenu></AgentMenu>

            <div className="container-fluid pr-0" id="main-area">

                <Navbar />

                <div className="container-fluid">
                    <div className="d-flex border-bottom pb-15 pt-15">
                        <div className="me-auto ">
                            <h2 className="page-title">Manage Roles</h2>
                        </div>
                        <div>
                            <Link to="/AddRoles" className="nav-link" >
                                <Button variant="contained" color="success">
                                    Add Roles
                                </Button></Link></div>
                    </div>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table" >
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" style={{ background: '#ddd' }}># </TableCell>
                                    {/* <TableCell align="center" style={{ background: '#ddd' }}>Company Name </TableCell> */}
                                    <TableCell align="center" style={{ background: '#ddd' }}>Name</TableCell>

                                    <TableCell align="center" style={{ background: '#ddd' }}>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.map((resdata, index) => (
                                    <TableRow
                                        key={index}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell align="center" style={{ padding: '5px 15px' }}>{index + 1}</TableCell>
                                        {/* <TableCell align="center" style={{ padding: '5px 15px' }}>{resdata.company_name}</TableCell> */}
                                        <TableCell align="center" style={{ padding: '5px 15px' }}>{resdata.name}</TableCell>

                                        <TableCell align="center" style={{ padding: '5px 15px' }}>

                                            {/* <EditIcon onClick={() => {editAgent()}} /> */}
                                            <Link to="/UpdateRoles" onClick={() => { editCompany(resdata) }}><EditIcon /></Link>

                                            {resdata.roleId !== 1 && (
                                                <DeleteIcon onClick={() => deleteRole(resdata.roleId)} />
                                            )}

                                            {/* <VisibilityIcon></VisibilityIcon>                            */}

                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>






        </>
    );
}

export default ManageRoles;