import React, { useState, useEffect, useContext } from "react";
import { Box } from "@mui/material";
import AgentMenu from "../../components/AgentMenu";
import NoteContext from "../../context/NoteContext";
import Button from '@mui/material/Button';
import { FormHelperText, Grid, TextField } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useForm } from "react-hook-form";
import 'react-phone-input-2/lib/material.css'
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import Navbar from "../Navbar";
import axios from 'axios';
import { config } from "../../util/apiconfig";
import swal from 'sweetalert';
import handleApiError from "../../util/apiUtil";





function AddGate() {
    // const { AgentId } = match.params;
    // const isAddMode = !AgentId;
    // =================== Voter Info Tab-1 Validation  ================

    const sailorInformation = yup.object().shape({
        
        // company_code: yup.string().required("Company Code Required"),
        gate_name: yup.string().required("Gate Name Required"),
        company_name: yup.string().required("Company Name Required"),
        
    })

    // =================== Voter Info Tab-1 Validation  ================
    // =================== Voter Info Yup Resolver Starts  ================
    const { register: sailorInfoHandler,
        handleSubmit: sailorInfoHandlerSubmit,
        reset: voterInfoReset,
        setValue,
        formState: { errors: sailorInfoErrors } } = useForm({
            resolver: yupResolver(sailorInformation),
            mode: 'OnSubmit',
        });
  

    
    
    
    const obj = useContext(NoteContext) 
    const [data, setData] = useState([]);
    const [readonlyCompany, setReadonlyCompany] = useState(false);
    const [companyName, setCompanyName] = useState('');

   
    const getCompanyList = async () => {
        try {
            const response = await axios.get(config.mongo_endpoint + "api/v1/company/getall", {
                headers: {
                    "Authorization": obj.AgentToken
                }
            });
    
            const resultCompanyData = response.data;
    
            if (resultCompanyData.status === 'Success') {
                const companyList = resultCompanyData.data;
                setData(companyList); 
            } else {
                console.log('Failed to fetch company data: ' + resultCompanyData.message);
            }
        } catch (error) {
            
             handleApiError(error, navigate); 
        }
    };

    useEffect(()=>{
        getCompanyList();     
        const logintype = obj.type;
        const logincompany = localStorage.getItem('company_code');
        if (logintype !== 0) {
            setCompanyName(logincompany);
            setValue('company_name',logincompany);
            setReadonlyCompany(true);
        }

    },[])  


    const navigate = useNavigate();
    const LandingScreen = () => {
        navigate("/ManageGate");
    }   


    const onSubmit = async (data) => {    
        console.log(data)
        const Gatedatainfo = {
            company_code : data["company_name"],
            gate_name : data["gate_name"],
            is_authenticated:0,

            userId: localStorage.getItem("userId"),         
        } 
        console.log(Gatedatainfo)   
            await axios.post(config.mongo_endpoint+'api/v1/gate/CreateGate', Gatedatainfo, 
            { headers: { 'Content-Type': 'application/x-www-form-urlencoded',
                         'Authorization':obj.AgentToken} })
                        .then(result => {
                          console.log(result)
                          if(result.status == 200 && result.data.status == "Success"){
                            // toast.success("Gate added Successfully.", {
                            //   position: toast.POSITION.TOP_CENTER,
                            //   theme: "colored",
                            // });  
                            swal("Gate added Successfully....!", {
                                icon: "success",
                            });
                            LandingScreen();
    
                          } else {
                            // toast.success("Some Thing went wrong try again later.", {
                            //   position: toast.POSITION.TOP_CENTER,
                            //   theme: "colored",
                            // });  
                            swal("Gate create Failed ....!", {
                                icon: "warning",
                            });
                          }
                          
                          
                        })
                        .catch(error => {
                          handleApiError(error,navigate)
                      
                          if (error) {
                        //   swal("Some thing When wrong. Please try again Later....", {
                        //     icon: "warning",
                        // });
                  
                          }
                        
                        });

    }


  
    const handleCompanyChange = (event) => {
       // console.log(event.target.value, 'afterchange')
        setCompanyName(event.target.value);
    };







    return (
        <>
            <AgentMenu></AgentMenu>
            <div className="container-fluid pr-0" id="main-area">
                <Navbar />
                <div className="container-fluid">
                    <div className="d-flex border-bottom pb-15 pt-15">
                        <div className="me">
                            <h2 className="page-title">Add Gate</h2>
                        </div>

                    </div>
                    <section className="voting-section">
                        <div className="container">
                            <div className="row">

                                {/* <h1>Voting</h1> */}
                                <Box sx={{ width: '100%' }}>
                                    <>
                                        <div className="mto stepper-content">

                                            <form onSubmit={sailorInfoHandlerSubmit(onSubmit)}>
                                                <Box mb={3.5}>
                                                <Grid container spacing={3} marginTop={1}>    
                                                        <Grid item sm={4} xs={12}>
                                                            <FormControl fullWidth>
                                                                <InputLabel id="demo-simple-select-label">Company *</InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    size="small"
                                                                    name="company_name"
                                                                    label="Company Name *"
                                                                    value={companyName}
                                                                    readOnly={readonlyCompany}
                                                                    {...sailorInfoHandler("company_name")}
                                                                    error={!!sailorInfoErrors['company_name']}
                                                                    helperText={sailorInfoErrors['company_name'] ? sailorInfoErrors['company_name'].message : ''}
                                                                   onChange={handleCompanyChange}
                                                                 >
                                                                    {data.map((res) => (
                                                                      <MenuItem value={res.company_code}>{res.company_name}</MenuItem>
                                                                    ))}
                              
                                                                  </Select>
                                                                  {sailorInfoErrors.company_name && <FormHelperText error={!!sailorInfoErrors['company_name']}>{sailorInfoErrors.company_name.message}</FormHelperText>}
                                                                </FormControl>
                                                        </Grid>                                                


                                                        
                                                        <Grid item sm={4} xs={12}>
                                                            <TextField
                                                                fullWidth
                                                                size="small"
                                                                type="text"
                                                                name="gate_name"
                                                                label="Gate Name *"
                                                                {...sailorInfoHandler("gate_name")}
                                                                id="outlined-error"
                                                                error={!!sailorInfoErrors['gate_name']}
                                                                helperText={sailorInfoErrors['gate_name'] ? sailorInfoErrors['gate_name'].message : ''}
                                                            />
                                                        </Grid> 
                                                                                                                                                                      
                                                    </Grid>

                                       

                                                </Box>

                                                <div sx={{ display: 'flex', flexDirection: 'row', padding: '10px 10px', background: "#f5f5f5" }}>

                                                    <Button type="submit" variant="contained" color="success" sx={{ marginTop: "20px", marginLeft: "18px" }}>
                                                        SUBMIT
                                                    </Button>
                                                </div>
                                            </form>


                                        </div>


                                    </>

                                </Box>
                            </div>
                        </div>
                    </section>

                </div>
            </div>
        </>
    );
}

export default AddGate;