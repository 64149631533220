import React, { useState, useEffect, useContext } from "react";
import Topnav from "../../common/topNav";
import { Link, useNavigate } from "react-router-dom";
// import Footer from "../../common/footer";
// import Banner from "../../components/banner";
import ELogo from "../../assets/images/sailor/logo.svg";
import ELogo1 from "../../assets/images/sailor/sailoronboard.svg";
import Lbg from "../../assets/images/sailor/login-bg.svg";
import NoteContext from "../../context/NoteContext";
import { config } from "../../util/apiconfig";
import axios from 'axios';
import swal from 'sweetalert';
import {useSearchParams} from "react-router-dom";





function SailorResetPassword() {
	const [bannerConfig, setbannerConfig] = useState({ bannerType: 3 });
	const navigate = useNavigate();

	const obj = useContext(NoteContext)

	const [Email, setEmail] = useState("");
	const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [searchParams] = useSearchParams()

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    // Check if passwords match and update passwordMatch state
    setPasswordMatch(e.target.value === confirmPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    // Check if passwords match and update passwordMatch state
    setPasswordMatch(e.target.value === password);
  };

  


	async function submitData(password) {	
		if (password !== confirmPassword) {			
			swal("Passwords do not match.!", {
				icon: "success",
			  });
		  }		
		else if(password) 
		{	
			let paramsdata = searchParams.get('token')
			console.log(paramsdata)
			let userData = { password:password,  token:paramsdata}
			console.log(userData)        
            await axios.post(config.mongo_endpoint+"api/v1/sailorUser/resetPassword", userData, { headers: { 'Content-Type': 'application/json' } })
            .then(result => {
                console.log(result)
				if(result.data.success){
					swal("User password has been reset....!", {
						icon: "success",
					  });
				}
            })
				navigate("/");
			// let res = await data.json();
			// console.log(res);
			// if (res.status === "Success") {
			// 	obj.setAgentToken(res.data[0].token)
			// 	obj.setName(res.data[0].name);
			// 	obj.setType(res.data[0].Type)

			

			// } else {
			// 	// alert(res.message);

			// }
		}
	}
	return (
		<>

			<div class="login-section bg" style={{ backgroundImage: `url(${require("../../assets/images/sailor/login-bg.svg").default})` }}>
				<div class="row-2">
					<div class="login-white">
						<div class="login-lft">

							<div class="login-l">
								<div>
									<Link to="/"><img src={ELogo} className="img-fluid light-logo" alt="" width="70" />
										<img src={ELogo1} className="img-fluid logo-txt ml-10" alt="" width="100" height="93" />
									</Link>
									<h1><span>Welcome to </span> <br /> Sailor Onboarding</h1>

									{/* <div class="btn-sct">
									<div class="btn-lnks">
										 
										<div class="officer-lnk"><a href="javascript:void(0);">Manning Agent Login​</a></div>
									</div>
								</div> */}
								</div>
							</div>
						</div>

						<div class="login-rht">


							<div class="col-md-10 col-lg-10 col-xl-9 mx-auto">
								<div class="card-sigin">
									<div class="mb-2 d-flex">


									</div>
									<div class="card-sigin">
										<div class="main-signup-header">

											<h2 className="pt-30">Reset Password</h2>


											{/* <div class="form-group mt-3">
												<label>Enter your current password</label> <input class="form-control"
													placeholder="Enter your current password" type="text" value={Email} onChange={(e) => { setEmail(e.target.value) }} />
											</div> */}
											<div class="form-group mt-2">
												<label>Enter your new password</label> <input class="form-control"
													placeholder="Enter your new password" type="password" value={password} onChange={handlePasswordChange} />
											</div>
											<div class="form-group mt-2">
												<label>Confirm password</label> <input class="form-control"
													placeholder="Enter your new confirm password" type="password" value={confirmPassword} onChange={handleConfirmPasswordChange} />
											</div>
											 

											<button class="btn btn-main-primary btn-block mt-0" onClick={() => { submitData(password) }}> Submit</button>
 
										</div>
									</div>
								</div>
							</div>
						</div>



					</div>

				</div>
			</div>





		</>
	);
}

export default SailorResetPassword;