import React, { useState, useEffect, useContext } from "react";
import { Box } from "@mui/material";
import AgentMenu from "../../components/AgentMenu";
import NoteContext from "../../context/NoteContext";
import Button from '@mui/material/Button';
import { FormHelperText, Grid, TextField, Stack, Modal } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useForm } from "react-hook-form";
import 'react-phone-input-2/lib/material.css'
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import Navbar from "../Navbar";
import axios from 'axios';
import { config } from "../../util/apiconfig";
import DoneIcon from '@mui/icons-material/Done';
import swal from 'sweetalert';
import Moment from 'react-moment';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import dayjs, { Dayjs } from 'dayjs';
import RemoveDoneIcon from '@mui/icons-material/RemoveDone';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import handleApiError from "../../util/apiUtil";
function ViewVisitors() {


    const [activeStep, setActiveStep] = React.useState(0);
    const TodayDate = new Date();
    const [cityData, setCityData] = useState([]);
    const [visitor_date, setVisitordate] = useState(dayjs(TodayDate));

    const isotodaydate = new Date().toISOString().slice(0, 10);
    const obj = useContext(NoteContext)
    const [data, setData] = useState([]);
    const [checkoutOpen, setCheckoutOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [visitorId, setVisitorId] = useState(null);
    const [mincheckin, setmincheckin] = useState(null);
    const [update_company, setUpdate_company] = useState(obj.update_company)
    console.log("UPDATECOMPANY=>", update_company)

    const [visitorData, setVisitorData] = useState([]);

    useEffect(() => {
        const visitorId = update_company.visitorId;
        visitordata(visitorId)
    }, [])

    const navigate = useNavigate();
    const LandingScreen = () => {
        navigate("/WaitingVisitors");
    }

    const handleCheckoutOpen = (row) => {
        setCheckoutOpen(true);
        setVisitorId(row.visitorId);
        console.log(new Date(row.checkin).toISOString().slice(0, 10), 'check')
        setmincheckin(new Date(row.checkin).toISOString().slice(0, 10));

    };
    const handleCheckoutClose = () => {
        setCheckoutOpen(false);
        setSelectedDate(null);
        setVisitorId(null);
    };

    async function visitordata(visitorId) {

        try {

            const departmentData = new FormData();
            departmentData.append("visitorId", visitorId);

            const resultEmployeeData = await axios.post(config.mongo_endpoint + 'api/v1/visitor/visitorbyid', departmentData, {
                headers: {
                      "Authorization" : obj.AgentToken,  
                    'Content-Type': 'multipart/form-data',
                }
            });

            console.log(resultEmployeeData, 'res')
            if (resultEmployeeData.status === 200) {


                const visitorList = resultEmployeeData.data.data.map(visitor => ({
                    ...visitor,
                    status: determineStatus(visitor.checkin, visitor.checkout, visitor.approve_flag)
                }));
                setVisitorData(visitorList);

            }
        } catch (error) {

            console.error("Error fetching visitor data:", error);
            handleApiError(error, navigate);
            // Handle error as needed (e.g., show an error message)
        }
    }

    const determineStatus = (checkin, checkout, approve_flag) => {
        if (checkin !== null && checkout !== null) {
            return "Checked Out";
        }
        else if (checkin === null && approve_flag === '') {
            return "Visitor Waiting";
        } else if (checkin !== null && approve_flag === '1') {
            return "Checked In";
        } else if (checkin === null && approve_flag === '0') {
            return "Rejected";
        } else {
            return "Unknown";
        }
    };


    async function updateCheckout(data) {
        try {
            console.log(data, 'check');

            const visitorInformation = {
                "visitor_id": data.visitorId,
                'userId': localStorage.getItem("userId"),
            }
            await axios.post(config.mongo_endpoint + 'api/v1/visitor/checkOut', visitorInformation, {
                headers: {
                    "Authorization":obj.AgentToken,

                    'Content-Type': 'application/json',
                }
            })
                .then(result => {
                    if (result.status === 200 && result.data.status === "Success") {


                        swal("Checkout Success....!", {
                            icon: "success",
                        });

                        LandingScreen();
                    } else {

                        swal("Checkout failed....!", {
                            icon: "warning",
                        });
                    }
                    LandingScreen();
                })
            const filterDate = new Date(visitor_date);


        }
        catch (error) {
            console.error(error);
            handleApiError(error, navigate);

            if (error) {
                alert("Something went wrong. Please try again Later....")
            }
            console.log(error.code);

        }

    }

    const [companyValue, setCompanyValue] = useState('');


    async function updateAccept(data) {
        try {

            const visitorInformation = {
                "visitor_id": data.visitorId,
                "approval_flag": 1,
                'userId': localStorage.getItem("userId"),
            }
            await axios.post(config.mongo_endpoint + 'api/v1/employee/approval', visitorInformation, {
                headers: {
                     "Authorization" : obj.AgentToken,  
                    'Content-Type': 'multipart/form-data',
                }
            })
                .then(result => {

                    if (result.status === 200 && result.data.status === "Success") {


                        swal("CheckIn Success....!", {
                            icon: "success",
                        });
                        LandingScreen();

                        const filterDate = new Date(visitor_date);

                    } else {

                        swal("CheckIn failed....!", {
                            icon: "warning",
                        });
                    }
                })

        }
        catch (error) {
            console.error(error);
            handleApiError(error, navigate);

            if (error) {
                alert("Something went wrong. Please try again Later....")
            }
            console.log(error.code);

        }

    }

    async function updateReject(data) {
        try {

            const visitorInformation = {
                "visitor_id": data.visitorId,
                "approval_flag": 0,
                "remark": remarks,
                'userId': localStorage.getItem("userId"),
            }
            await axios.post(config.mongo_endpoint + 'api/v1/employee/approval', visitorInformation, {
                headers: {
                    'Authorization':obj.AgentToken,

                    'Content-Type': 'multipart/form-data',
                }
            })
                .then(result => {

                    if (result.status === 200 && result.data.status === "Success") {

                        swal("Rejected Updated....!", {
                            icon: "success",
                        });
                        LandingScreen();
                        const filterDate = new Date(visitor_date);

                    } else {

                        swal("Rejected failed....!", {
                            icon: "warning",
                        });
                    }
                })


        }
        catch (error) {
            console.error(error);
            handleApiError(error, navigate);
            if (error) {
                alert("Something went wrong. Please try again Later....")
            }
            console.log(error.code);

        }

    }
    const [modalOpen, setModalOpen] = useState(false);
    const [remarks, setRemarks] = useState('');
    const [visitorReject, setVisitorReject] = useState({});

    const openModal = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
        // Optionally, clear remarks state
        setRemarks('');
    };

    const handleReject = (data) => {
        setVisitorReject(data);
        openModal();
    };

    const handleSubmit = () => {
        // Call rejectUpdate function with row values and remarks
        updateReject(visitorReject);
        // Close the modal after submitting
        closeModal();
    };


    const handleDateChange = (date) => {
        console.log(date, 'e')
        setSelectedDate(date);
    };

    async function handleCheckoutSubmit() {

        const date = new Date(selectedDate)
        console.log("Submitting checkout for visitor ID:", visitorId, "Date:", date);
        try {

            const visitorInformation = {
                "visitor_id": visitorId,
                'date': date,
                'userId': localStorage.getItem("userId"),
            }
            await axios.post(config.mongo_endpoint + 'api/v1/visitor/checkOut', visitorInformation, {
                headers: {
                      "Authorization" : obj.AgentToken,  
                    'Content-Type': 'application/json',
                }
            })
                .then(result => {
                    if (result.status === 200 && result.data.status === "Success") {


                        swal("Checkout Success....!", {
                            icon: "success",
                        });
                        LandingScreen();
                    } else {

                        swal("Checkout failed....!", {
                            icon: "warning",
                        });
                    }
                    LandingScreen();
                })
            const filterDate = new Date(visitor_date);
            //getApprovedOnboardingList(filterDate)

        }
        catch (error) {
            console.error(error);
            handleApiError(error, navigate);
            // if (error)
            //   setLoader(false);
            if (error) {
                alert("Something went wrong. Please try again Later....")
            }
            console.log(error.code);

        }
        // You can add your actual form submission logic here
        handleCheckoutClose();
    };


    return (
        <>
            <AgentMenu></AgentMenu>
            <div className="container-fluid pr-0" id="main-area">
                <Navbar />
                <div className="container-fluid">
                    <div className="d-flex border-bottom pb-15 pt-15">
                        <div className="me">
                            <h2 className="page-title">Visitor Details</h2>
                        </div>

                    </div>
                    <section className="voting-section">
                        <div className="container">
                            <div className="row">
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 750 }} aria-label="visitors table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center" style={{ fontWeight: 'bold', backgroundColor: '#B2BEB5' }} colSpan={2}>
                                                    <Typography variant="h6">Visitor Details</Typography>

                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {visitorData.map(visitor => (
                                                <React.Fragment key={visitor._id}>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row" style={{ fontWeight: 'bold' }}>Company</TableCell>
                                                        <TableCell>{visitor.company_info}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row" style={{ fontWeight: 'bold' }}>Gate</TableCell>
                                                        <TableCell>{visitor.gate_name}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row" style={{ fontWeight: 'bold' }}>Department</TableCell>
                                                        <TableCell>{visitor.department_name}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row" style={{ fontWeight: 'bold' }}>Visitor </TableCell>
                                                        <TableCell>

                                                            {visitor.visitor_name}  &nbsp;&nbsp; &nbsp;&nbsp;
                                                            <img src={visitor.imagepath} style={{ width: '80px', height: '90px' }} alt="Visitor's Avatar" />
                                                        </TableCell>
                                                    </TableRow>
                                                    {/*                                
                                <TableRow>
                                    <TableCell component="th" scope="row" style={{ fontWeight: 'bold' }}>Visitor Name</TableCell>
                                    <TableCell>{visitor.visitor_name}
                                    
                                    </TableCell>
                                </TableRow> */}
                                                    <TableRow>
                                                        <TableCell component="th" scope="row" style={{ fontWeight: 'bold' }}>Email ID</TableCell>
                                                        <TableCell>{visitor.email_id}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row" style={{ fontWeight: 'bold' }}>Phone Number</TableCell>
                                                        <TableCell>{visitor.phone_no}</TableCell>
                                                    </TableRow>

                                                    <TableRow>
                                                        <TableCell component="th" scope="row" style={{ fontWeight: 'bold' }}>Meeting Person</TableCell>
                                                        <TableCell>{visitor.meeting_person}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row" style={{ fontWeight: 'bold' }}>Visitor Type</TableCell>
                                                        <TableCell>{visitor.visitor_type}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row" style={{ fontWeight: 'bold' }}>Purpose of Visit</TableCell>
                                                        <TableCell>{visitor.purpose_of_visit}</TableCell>
                                                    </TableRow>
                                                    {visitor.checkin !== null && visitor.checkin !== '' &&
                                                        <TableRow>
                                                            <TableCell component="th" scope="row" style={{ fontWeight: 'bold' }}>Checkin Date</TableCell>
                                                            <TableCell>
                                                                {/* {visitor.checkin} */}
                                                                {new Date(visitor.checkin).toISOString().slice(0, 10)} {new Date(visitor.checkin).toLocaleTimeString()}
                                                                </TableCell>
                                                        </TableRow>}
                                                    
                                                    {visitor.checkout !== null && visitor.checkout !== '' &&
                                                        <TableRow>
                                                            <TableCell component="th" scope="row" style={{ fontWeight: 'bold' }}>Checkout Date</TableCell>
                                                            <TableCell>{new Date(visitor.checkout).toISOString().slice(0, 10)} {new Date(visitor.checkout).toLocaleTimeString()}</TableCell>
                                                                            </TableRow>}
                                                    {visitor.approve_flag === '0' && visitor.remark !== '' &&
                                                        <TableRow>
                                                            <TableCell component="th" scope="row" style={{ fontWeight: 'bold' }}>Remark</TableCell>
                                                            <TableCell>{visitor.remark}</TableCell>
                                                        </TableRow>
                                                    }
                                                    <TableRow>
                                                        <TableCell component="th" scope="row" style={{ fontWeight: 'bold' }}>Status</TableCell>
                                                        <TableCell>

                                                            {visitor.status}   <br /><br />
                                                            {visitor.checkin !== null && visitor.checkout === null && (
                                                                <>
                                                                    {new Date(visitor.checkin).toISOString().slice(0, 10) === isotodaydate ? (
                                                                        <Button
                                                                            variant="contained"
                                                                            size="small"
                                                                            onClick={() => updateCheckout(visitor)}
                                                                        >
                                                                            Check Out
                                                                        </Button>
                                                                    ) : (
                                                                        <Button
                                                                            variant="contained"
                                                                            size="small"
                                                                            onClick={() => handleCheckoutOpen(visitor)}
                                                                        >
                                                                            CheckOut
                                                                        </Button>
                                                                    )}
                                                                    {' '}
                                                                </>
                                                            )}

                                                            {visitor.checkin === null && visitor.checkout === null && (
                                                                <>
                                                                    {visitor.approve_flag === '0' ? (
                                                                        // <RemoveDoneIcon />
                                                                        <></>
                                                                    ) : (
                                                                        <Stack direction="row" spacing={2}>
                                                                            <Button
                                                                                variant="contained"
                                                                                color="primary"
                                                                                size="small"
                                                                                onClick={() => updateAccept(visitor)}
                                                                            >
                                                                                Accept
                                                                            </Button>
                                                                            <Button
                                                                                variant="contained"
                                                                                color="secondary"
                                                                                size="small"
                                                                                onClick={() => handleReject(visitor)}
                                                                            >
                                                                                Reject
                                                                            </Button>
                                                                        </Stack>
                                                                    )}
                                                                </>
                                                            )}

                                                            {visitor.checkout !== null && (
                                                                <></>
                                                            )}

                                                        </TableCell>
                                                    </TableRow>
                                                    


                                                    <TableRow>
                                                        <TableCell></TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>
                                                </React.Fragment>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>                            </div>
                        </div>
                    </section>

                </div>


                {/* Reject Modal */}
                <Modal open={modalOpen} onClose={closeModal}>
                    <div style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        backgroundColor: 'white',
                        padding: '20px',
                        outline: 'none',
                        borderRadius: '8px',
                    }}>
                        <TextField
                            multiline
                            rows={4}
                            variant="outlined"
                            placeholder="Enter remarks"
                            value={remarks}
                            onChange={(e) => setRemarks(e.target.value)}
                            style={{ marginBottom: '10px', width: '100%' }}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            disabled={!remarks.trim()} // Disable submit if remarks are empty
                        >
                            Submit
                        </Button>
                    </div>
                </Modal>

                {/* Checkout Modal */}
                <Modal open={checkoutOpen} onClose={handleCheckoutClose}>
                    <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: '#fff', padding: '20px', outline: 'none' }}>
                        {/* <h2>Checkout </h2> */}
                        <Grid item xs={3}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Checkout Date"
                                    value={selectedDate}
                                    onChange={(date) => handleDateChange(date)}
                                    renderInput={(params) => <TextField {...params} />}
                                    inputFormat="DD-MM-YYYY"
                                    minDate={dayjs(mincheckin)}
                                    maxDate={dayjs(new Date())}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12}>&nbsp;&nbsp;</Grid>
                        <Grid container justifyContent="flex-end">


                            <Button variant="contained" color="primary" onClick={handleCheckoutSubmit}>Submit</Button>
                        </Grid>
                    </div>
                </Modal>
            </div>
        </>
    );
}

export default ViewVisitors;