import React, { useState, useEffect, useContext } from "react";
import Topnav from "../../common/topNav";
import { Link, useNavigate } from "react-router-dom";
// import Footer from "../../common/footer";
// import Banner from "../../components/banner";
import Button from '@mui/material/Button';

import Ham from "../../assets/images/sailor/ham.svg";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AgentMenu from "../../components/AgentMenu";
import NoteContext from "../../context/NoteContext";
import { Grid, Box } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { config } from "../../util/apiconfig";
import axios from 'axios';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Navbar from "../Navbar";
import swal from 'sweetalert';
import Moment from 'react-moment';
import dayjs from "dayjs";
import handleApiError from "../../util/apiUtil";
import ViewIcon from '@mui/icons-material/Visibility';

function VisitorReport() {
    const obj = useContext(NoteContext)
    const [data, setData] = useState([]);
    const TodayDate = new Date();

    const [companyData, setCompanyData] = useState([]);
    const [companyValue, setCompanyValue] = useState('');

    const [companyName, setCompanyName] = useState('');
    const [visitorType, setVisitorType] = useState('');
    const [departmentName, setDepartmentName] = useState('');
    const [visitingPerson, setVistingPerson] = useState('');


    const [visitingDate, setVisitingDate] = useState(dayjs(TodayDate));
    const [visitingEndDate, setVisitingEndDate] = useState(dayjs(TodayDate));
    const [readonlyCompany, setReadonlyCompany] = useState(false);
    const navigate = useNavigate();

    const handleCompanyChange = (event) => {
        setCompanyName(event.target.value);
        setDepartmentName('');
        DepartmentList(event.target.value);


    };

    const handleVisitorTypeChange = (event) => {

        setVisitorType(event.target.value);

    };
    const handleVistorPerson = (event) => {

        setVistingPerson(event.target.value);

    };


    const handleVisitingDateChange = (event) => {
        const filterdDate = new Date(event);
        setVisitingDate(dayjs(filterdDate));
    };

    const handleVisitingEndDateChange = (event) => {
        const filterdDate = new Date(event);
        setVisitingEndDate(dayjs(filterdDate));
    };




    const getCompanyList = async () => {
        try {
            const response = await axios.get(config.mongo_endpoint + "api/v1/company/getall", {
                headers: {
                    "Authorization": obj.AgentToken,
                }
            });
    
            if (response.data.status === 'Success') {
                const companylist = response.data.data;
                setCompanyData(companylist); 
            }
           
        } catch (error) {
           
            handleApiError(error, navigate); 
        }
    };

    useEffect(() => {
        getCompanyList();
        const logintype = obj.type;
        const logincompany = localStorage.getItem('company_code');
        //console.log(logincompany,'l')
        if (logintype !== 0) {
            setCompanyName(logincompany);
            DepartmentList(logincompany);
            setReadonlyCompany(true);
        }


        filterdata();

    }, [])

    const handleSearch = () => {
        setCompanyValue(companyName);
        //console.log("companyinfo", companyName);
        filterdata();

    }

    const [departmentData, setDepartmentData] = useState([]);
    const [employeeData, setEmployeeData] = useState([]);
    const getDepartmentList = async () => {
        try {
            const response = await axios.get(config.mongo_endpoint + "api/v1/department/all", {
                headers: {
                    "Authorization": obj.AgentToken,
                }
            });
    
            if (response.data.status === 'Success') {
                const departmentlist = response.data.data;
                setDepartmentData(departmentlist); 
            }
           
        } catch (error) {
           
           
            handleApiError(error, navigate);
        }
    };

    const handledepartment = (event) => {
        ////console.log(event)
        const department = event.target.value
        setDepartmentName(department);
        setVistingPerson('');
        setEmployeeData([]);
        ////console.log(department)
        getEmployeeList(department);
    }


    const getEmployeeList = async (department) => {

        const departmentCode = department;

        const departmentData = new FormData();
        departmentData.append("department_code", departmentCode);

        const resultEmployeeData = await axios.post(config.mongo_endpoint + 'api/v1/employee/department/departmentCode', departmentData, {
            headers: {
                "Authorization": obj.AgentToken,
                'Content-Type': 'multipart/form-data',
                
            }
        });
        //console.log(resultEmployeeData);



        if (resultEmployeeData.data.status === 'Success') {
            const employeelist = resultEmployeeData.data.data;
            setEmployeeData(employeelist);
            ////console.log(employeelist,'emp')
        }

    } 

const DepartmentList = async (company) => {
    try {
        const companyCode = company;

        const response = await axios.get(`${config.mongo_endpoint}api/v1/department/bycompanycode`, {
            params: {
                company_code: companyCode
            },
            headers: {
                "Authorization": obj.AgentToken,
            }
        });

        if (response.data.status === 'Success') {
            const departmentlist = response.data.data;
            setDepartmentData(departmentlist); 
           
        }
    } catch (error) {
       
        handleApiError(error, navigate); 
    }
};

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
    const formatEndDate = (dateString) => {
        const date = new Date(dateString);
        date.setDate(date.getDate() + 1); // Add one day

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    };
    async function filterdata() {

        try {
            //console.log(formatDate(visitingDate),'vd')
            const company_value = (obj.type === 0 ? companyName : (localStorage.getItem('company_code')))
            const departmentData = new FormData();
            departmentData.append("company_code", company_value);
            departmentData.append("department_code", departmentName);
            departmentData.append("meeting_person", visitingPerson);
            departmentData.append("purpose_of_visit", visitorType);
            departmentData.append("start_date", formatDate(visitingDate));
            departmentData.append("end_date", formatEndDate(visitingEndDate));


            const resultEmployeeData = await axios.post(config.mongo_endpoint + 'api/v1/visitor/filterVisitors', departmentData, {
                headers: {
                    "Authorization": obj.AgentToken,
                    'Content-Type': 'multipart/form-data',
                }
            });

            //console.log(resultEmployeeData.status, 'e');



            if (resultEmployeeData.status === 200) {

                const employeelist = resultEmployeeData.data;
                setData(employeelist);
                //console.log(employeelist, 'emp')
            }
        } catch (error) {
            handleApiError(error, navigate)

            //console.error("Error fetching visitor data:", error);
            // Handle error as needed (e.g., show an error message)
        }
    }


    async function editCompany(resdata){
        console.log("ROWEDIT**", resdata)
        obj.setUpdate_company(resdata)
       // obj.setUpdatingCompanyFlag(1);
      }

    return (
        <>

            <AgentMenu></AgentMenu>

            <div className="container-fluid pr-0" id="main-area">

                <Navbar />

                <div className="container-fluid">
                    <div className="d-flex border-bottom pb-15 pt-15">
                        <div className="me-auto ">
                            <h2 className="page-title">Visitor Report </h2>
                        </div>

                    </div>

                    <section className="voting-section">
                        <div className="container">
                            <div className="row">
                                <Box>

                                    <Grid container spacing={2}>
                                        <Grid item xs={3}>

                                            <Grid item xs={12}>
                                                <FormControl fullWidth style={{ marginTop: '15px' }}>
                                                    <InputLabel id="demo-simple-select-label">Company Name </InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        size="small"
                                                        name="company_name"
                                                        label="Company Name *"
                                                        value={companyName} // Set defaultValue to the desired default value
                                                        onChange={handleCompanyChange}
                                                        readOnly={readonlyCompany}
                                                    >
                                                        <MenuItem value={0}>Select Company</MenuItem>
                                                        {companyData.map((res) => (
                                                            <MenuItem key={res.company_code} value={res.company_code}>{res.company_name}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>


                                        <Grid item xs={3}>
                                            <FormControl fullWidth style={{ marginTop: '15px' }}>
                                                <InputLabel id="demo-simple-select-label">Department </InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    size="small"
                                                    name="department_name"
                                                    label="Company Name *"

                                                    onChange={handledepartment}
                                                >
                                                    {departmentData.map((res) => (
                                                        <MenuItem value={res.department_code}>{res.department_name}</MenuItem>
                                                    ))}

                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={3}>
                                            <FormControl fullWidth style={{ marginTop: '15px' }}>
                                                <InputLabel id="demo-simple-select-label">Employee  </InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    size="small"
                                                    name="meeting_person"
                                                    label="Employee*"
                                                    onChange={handleVistorPerson}

                                                >

                                                    {
                                                        employeeData.map((list) => (
                                                            <MenuItem value={`${list.empid}`}>{list.name}</MenuItem>
                                                        ))
                                                    }

                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <FormControl fullWidth style={{ marginTop: '15px' }}>
                                                <InputLabel id="demo-simple-select-label">Visitor Type </InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    size="small"
                                                    name="visitor_type"
                                                    label="Purpose of Visit *"

                                                    onChange={handleVisitorTypeChange}

                                                >
                                                    <MenuItem value="Meeting">Meeting</MenuItem>
                                                    <MenuItem value="Enquiry">Enquiry</MenuItem>
                                                    <MenuItem value="Courier">Courier </MenuItem>
                                                    <MenuItem value="Interview">Interview </MenuItem>
                                                    <MenuItem value="Suppliers">Suppliers</MenuItem>
                                                    <MenuItem value="Maintenance">Maintenance</MenuItem>
                                                    <MenuItem value="Business">Business</MenuItem>
                                                    <MenuItem value="Personal">Personal</MenuItem>
                                                    <MenuItem value="Others">Others</MenuItem>
                                                </Select>

                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={3}>
                                            {/* Left Section */}
                                            <Grid item xs={12} sx={{ marginTop: "10px" }}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer components={['DatePicker']}>
                                                        <DatePicker
                                                            label="Start Date "
                                                            name="visiting_date"
                                                            value={visitingDate}
                                                            // {...sailorInfoHandler("date_of_birth")}
                                                            //defaultValue={visitingDate}
                                                            slotProps={{ textField: { size: 'small' } }}


                                                            onChange={handleVisitingDateChange}
                                                            format="DD-MM-YYYY"
                                                        />
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={3}>
                                            {/* Left Section */}
                                            <Grid item xs={12} sx={{ marginTop: "10px" }}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer components={['DatePicker']}>
                                                        <DatePicker
                                                            label="End Date "
                                                            value={visitingEndDate}
                                                            // {...sailorInfoHandler("date_of_birth")}
                                                            // defaultValue={visitor_date}
                                                            slotProps={{ textField: { size: 'small' } }}


                                                            onChange={handleVisitingEndDateChange}
                                                            format="DD-MM-YYYY"
                                                        />
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            </Grid>
                                        </Grid>

                                        <Grid container justifyContent="flex-end" >

                                            <Button
                                                type="submit"
                                                onClick={handleSearch}
                                                variant="contained"
                                                sx={{ marginTop: "20px" }} // Align to the right
                                            >
                                                Search
                                            </Button>
                                            &nbsp;
                                            &nbsp;
                                            &nbsp;
                                            {/*                                        
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                sx={{ marginTop: "20px" }} // Align to the right
                                            >
                                                Download PDF Report
                                            </Button> */}

                                        </Grid> &nbsp;

                                    </Grid>
                                </Box>
                            </div>
                        </div>
                    </section>

                    <TableContainer component={Paper} style={{ marginTop: '15px' }}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table" >
                            <TableHead>
                                <TableRow>

                                    <TableCell align="center" style={{ background: '#ddd' }}># </TableCell>
                                    <TableCell align="center" style={{ background: '#ddd' }}>Company</TableCell>
                                    <TableCell align="center" style={{ background: '#ddd' }}>Gate</TableCell>
                                    <TableCell align="center" style={{ background: '#ddd' }}>Department</TableCell>
                                    <TableCell align="center" style={{ background: '#ddd' }}>Vistor Name </TableCell>
                                    <TableCell align="center" style={{ background: '#ddd' }}>Phone No</TableCell>
                                    <TableCell align="center" style={{ background: '#ddd' }}>Employee</TableCell>
                                    <TableCell align="center" style={{ background: '#ddd' }}>Visitor Type</TableCell>
                                    <TableCell align="center" style={{ background: '#ddd' }}>Purpose of Visit</TableCell>
                                    <TableCell align="center" style={{ background: '#ddd' }}>Check In Time</TableCell>
                                    <TableCell align="center" style={{ background: '#ddd' }}>Check Out Time</TableCell>
                                    <TableCell align="center" style={{ background: '#ddd' }}>Action</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.length === 0 ? (<TableRow><TableCell align="center" colSpan={8} style={{ padding: '5px 15px' }}> No Record Found</TableCell></TableRow>) : ""}
                                {data.map((row, index) => (
                                    <TableRow
                                        key={index}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell align="center" style={{ padding: '5px 15px' }}> {index + 1}</TableCell>
                                        <TableCell align="center" style={{ padding: '5px 15px' }}> {row.company_name}</TableCell>
                                        <TableCell align="center" style={{ padding: '5px 15px' }}> {row.gate_name}</TableCell>
                                        <TableCell align="center" style={{ padding: '5px 15px' }}> {row.department_name}</TableCell>

                                        <TableCell align="center" style={{ padding: '5px 15px' }}> {row.visitor_name}</TableCell>
                                        <TableCell align="center" style={{ padding: '5px 15px' }}> {row.phone_no}</TableCell>
                                        <TableCell align="center" style={{ padding: '5px 15px' }}>{row.meeting_person} </TableCell>
                                        <TableCell align="center" style={{ padding: '5px 15px' }}>{row.visitor_type} </TableCell>
                                        <TableCell align="center" style={{ padding: '5px 15px' }}>{row.purpose_of_visit} </TableCell>
                                        <TableCell align="center" style={{ padding: '5px 15px' }}>{row.checkin ? (<Moment format="DD-MM-YYYY hh:mm A">{row.checkin}</Moment>) : ""} </TableCell>
                                        <TableCell align="center" style={{ padding: '5px 15px' }}>{row.checkout ? (<Moment format="DD-MM-YYYY hh:mm A">{row.checkout}</Moment>) : ""} </TableCell>
                                        <TableCell align="center" style={{ padding: '5px 15px' }}> <Link to="/ViewVisitors" onClick={()=>{editCompany(row)}}><ViewIcon size="small" /></Link></TableCell>
                    

</TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>






        </>
    );
}

export default VisitorReport;