import React, { useState, useEffect, useContext } from "react";
import Topnav from "../../common/topNav";
import { Link } from "react-router-dom";
// import Footer from "../../common/footer";
// import Banner from "../../components/banner";
import { Box } from "@mui/material";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

// import { TimePicker } from '@mui/lab';
import Ham from "../../assets/images/sailor/ham.svg";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AgentMenu from "../../components/AgentMenu";
import NoteContext from "../../context/NoteContext";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Typography from '@mui/material/Typography';
import VisibilityIcon from '@mui/icons-material/Visibility';
import LinearProgress from '@mui/material/LinearProgress';
import Button from '@mui/material/Button';
import { FormHelperText, Grid, TextField } from "@mui/material";
import Avatar from '@mui/material/Avatar';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import FormLabel from '@mui/material/FormLabel';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import PhoneIcon from "@mui/icons-material/Phone";
import axios from 'axios';
import { config } from "../../util/apiconfig";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import Navbar from "../Navbar";
import Webcam from "react-webcam";
import swal from "sweetalert";

const videoConstraints = {
  width: 400,
  height: 400,
  facingMode: "user"
};
const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 0,
  },
  preview: {
    marginTop: 0,
    display: "flex",
    flexDirection: "column",
  },
  image: { maxWidth: "100%", maxHeight: 320 },
  delete: {
    cursor: "pointer",
    padding: 15,
    background: "red",
    color: "white",
    border: "none",
  },
};


function AddAgent() {
  var FormData = require('form-data');
  // const { AgentId } = match.params;
  // const isAddMode = !AgentId;
  // =================== Voter Info Tab-1 Validation  ================

  const sailorInformation = yup.object().shape({
    visitor_name: yup.string().required("Enter Visitor Name").matches(/^[A-Za-z\s]*$/, "Only Alphabets allowed"),
    email_Id: yup.string(),
    mobile_number: yup
      .string()
      .required("Enter Contact No")
      .matches(
        /^[0-9]{10,15}$/,
        'Phone number must be between 10 to 15 digits'
      ), // Use regex pattern to validate phone number format,
    meeting_person: yup.string().required("Enter Meeting Person"),
    visitor_type: yup.string().required("Select Visitor Type"),
    purpose_of_visit: yup.string().required("Enter Purpose of Visit"),
    business_flag :yup.number(),
  
    
  })

  const visitorCheck = yup.object().shape({
    mobile_number: yup
      .string()
      .required("Enter Contact No")
      .matches(
        /^[0-9]{10,15}$/,
        'Phone number must be between 10 to 15 digits'
      ), // Use regex pattern to validate phone number format,
    profile_image: yup.mixed().required("Capture Visitor Image")
  })


  // =================== Voter Info Tab-1 Validation  ================
  // =================== Voter Info Yup Resolver Starts  ================
  const { register: sailorInfoHandler,
    handleSubmit: sailorInfoHandlerSubmit,
    setValue: setSailorValue,
    reset: voterInfoReset,
    formState: { errors: sailorInfoErrors } } = useForm({
      resolver: yupResolver(sailorInformation),
      mode: 'onChange',
    });

  const { register: visitorCheckHandler,
    handleSubmit: visitorCheckHandlerSubmit,
    reset: visitorCheckReset,
    setValue,
    formState: { errors: visitorCheckErrors } } = useForm({
      resolver: yupResolver(visitorCheck),
      mode: 'OnSubmit',
    });

  const [selectedImage, setSelectedImage] = useState();

  useEffect(() => {

    getCompanyList();
  }, []);

  const removeSelectedImage = () => {
    setSelectedImage();
  };

  const obj = useContext(NoteContext)
  const [data, setData] = useState([]);
 
 
  const navigate = useNavigate();
  const LandingScreen = () => {
    navigate("/ManageVisitors");
  }



  const [picture, setPicture] = useState('')
  const [faceMatchStatus, setfaceMatchStatus] = useState(false)
  const [showVisitEntry, setshowVisitEntry] = useState(false);
  const [showVisitEntrys, setshowVisitEntrys] = useState(true);
  const [visitorStatus, setvisitorStatus] = useState(false);
  const [loader, setLoader] = useState(false);
  const [NewVisitor, setNewVisitor] = useState();
  const [faceMatchChecking, setfaceMatchChecking] = useState(false);
  
  const webcamRef = React.useRef(null)

  const capture = React.useCallback(() => {
    const pictureSrc = webcamRef.current.getScreenshot()
    setPicture(pictureSrc);
    const base64Data = pictureSrc;
    const [imageType, imageData] = base64Data.split(";base64,");
    const blob = base64ToBlob(imageData, imageType);
    const temporaryFile = new File([blob], "image.jpg", { type: imageType });
    setValue('profile_image', temporaryFile);
    setNewVisitor(temporaryFile);
    dateTimeCalc();
  })

  const dateTimeCalc = () => {
    const date = new Date(); // Assuming the input date is in UTC
    const options = {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
      timeZoneName: 'short',
    };
    const formattedDate = date.toLocaleString('en-IN', options); // Using 'en-IN' for timezone 'GMT +05:30' 
    // setcaptureDateTime(formattedDate);
  }
  const handleDateChange = (formdate) => {
    if (formdate) {
      const date = new Date(formdate);
      // Format the date to YYYY-MM-DD
      const formattedDate = date.toLocaleDateString('en-GB', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }).split('/').reverse().join('-');
      return formattedDate;
    }
  }


  const getCompanyList = async () => {
    let companyData = await fetch(config.mongo_endpoint + "api/v1/company/getall", {
      method: "GET",
      headers: {

        "Authorization": obj.AgentToken,
      }
    });

    let resultCompanyData = await companyData.json();
    if (resultCompanyData.status === 'Success') {
      const companylist = resultCompanyData.data;
      // companylist.map((res) => {
      //   setComcode(res.company_code)
      //   setComname(res.company_name)
      // })
      setData(companylist);

    }
    // console.log(resultCompanyData)
  }

  const [visitorData, setVisitorData] = useState({});
  const [visitFlag, setvisitFlag] = useState(0);
  const base64ToBlob = (base64Data, contentType) => {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

const onVisitSubmit = async (data) => {
  try {
    console.log(data);

  setfaceMatchChecking(true);
  const faceMatchData = new FormData();
  faceMatchData.append("file",NewVisitor);
  faceMatchData.append("phonenumber",data["mobile_number"]);
   const faceMatchCheck = await axios.post(config.face_match_api + '/find_face', faceMatchData);
   console.log(faceMatchCheck);
  
     if (faceMatchCheck.status === 200 && faceMatchCheck.data.status === "success" && faceMatchCheck.data.matched_face.length > 0) {
      setvisitFlag(1);
      const visitorInformation = {
        "mobile_number": faceMatchCheck.data.matched_face[0],
     }
       const visitorSearch = await axios.post(config.mongo_endpoint + 'api/v1/visitor/search', visitorInformation,{  headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization':obj.AgentToken,
      }});
       console.log(visitorSearch);
      if(visitorSearch.status === 200 && visitorSearch.data.status === "Success"){
        setfaceMatchChecking(false);
       setshowVisitEntry(true);
       setshowVisitEntrys(false);
       setvisitorStatus(false)
       setVisitorData(visitorSearch.data.data);
      //  console.log("VisitorData",visitorData);
      } else if(visitorSearch.status === 200 && visitorSearch.data.status === "Failed"){
        setfaceMatchChecking(false);
        setvisitorStatus(true);
          setshowVisitEntry(true);
          setshowVisitEntrys(false);          
         setVisitorData( {
          "phone_no": faceMatchCheck.data.matched_face[0],
       });
        
      } else {
        swal("Something Went Wrong! Try Again");
      }
     } else if (faceMatchCheck.status === 200 && faceMatchCheck.data.status === "Success" && faceMatchCheck.data.matched_face.length === 0) {
      setvisitFlag(2);
      setfaceMatchChecking(false);
          setvisitorStatus(true);
          setshowVisitEntry(true);
          setshowVisitEntrys(false);          
         setVisitorData( {"phone_no": data["mobile_number"]});
    } else {
      swal("FaceMatch Failed! Try Again");
      setfaceMatchChecking(false);
     }

  }
  catch (error) {
    console.error(error);
    if (error) {
      alert("Something went wrong. Please try again Later....")
    }
    console.log(error.code);

  }
}

  const onSubmit = async (data) => {
    try {
       console.log(data);
      const company_name = localStorage.getItem('company_name');
      const company_code = localStorage.getItem('company_code');
      console.log()
      // const visitorInformation = {
      //  "visitor_name":data["visitor_name"],
      //   "email_id": data["email_Id"],
      //   "phone_no": data["mobile_number"],
      //   "company_id": company_code,
      //   "company_name": company_name,
      //   "meeting_person": data["meeting_person"],
      //   "visitor_type": data["visitor_type"],
      //   "purpose_of_visit": data["purpose_of_visit"],
      //   "department_code" : data["department_name"],
      //   "checkflag": visitFlag,
      // }
        // const faceData = new FormData();
        // faceData.append("file",NewVisitor);
        // faceData.append("phonenumber",data["mobile_number"]);

        const visitorInformation = new FormData();
        //   "email_id": data["email_Id"],
        visitorInformation.append("email_id",data["email_Id"]);
        visitorInformation.append("visitor_name",data["visitor_name"]);
        visitorInformation.append("phone_no",data["mobile_number"]);
        visitorInformation.append("company_id",company_code);
        visitorInformation.append("company_name",company_name);
        visitorInformation.append("meeting_person",data["meeting_person"]);
        visitorInformation.append("visitor_type",data["visitor_type"]);
        visitorInformation.append("purpose_of_visit",data["purpose_of_visit"]);
        visitorInformation.append("checkflag", visitFlag);
        visitorInformation.append("department_code",data["department_name"]);
        visitorInformation.append("business_flag",businessFlag);
        visitorInformation.append("visitor_company",data["visitor_company"]);
        visitorInformation.append("gate_id",data["gate_name"]);
       


     
          await axios.post(config.mongo_endpoint + 'api/v1/visitor/existingcheckIn', visitorInformation,{headers: {                       
              "Authorization" : obj.AgentToken,  
           'Content-Type':'multipart/form-data',      
              }})
        .then(result => {
          // console.log(result);
          if (result.status === 200 && result.data.status === "Success") {
            swal("Check In Succes....!", {
              icon: "success",
          });
            LandingScreen();
            // alert();
          } else {
            swal("Visitor Entry failed. Try Again....!", {
              icon: "warning",
          });
          }
        })
       

     


    }
    catch (error) {
      console.error(error);
      // if (error)
      // setLoader(false);
      if (error) {
        alert("Something went wrong. Please try again Later....")
      }
      console.log(error.code);

    }

  }
 
  const [MobileNo, setMobileNo] = useState();
  async function handleMobileNo(e) {
    // console.log(e.target.value);
    setMobileNo(e.target.value);
  }

  //console.log(localStorage,'local')

  const [departmentData, setDepartmentData] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [gateData, setGateData] = useState([]);
    const getDepartmentList = async () => {

    //  let departmentData = await fetch(config.mongo_endpoint+"api/v1/department/all", {
    //     method: "GET",
    //     headers: {                       
    //         "x-access-token" : obj.AgentToken,            
    //       },
      
      
    //   });
    const companyCode = localStorage.getItem('company_code');

      const departmentData = await fetch(`${config.mongo_endpoint}api/v1/department/bycompanycode?company_code=${companyCode}`, {
          method: "GET",
          headers: {
              "Content-Type": "application/json", // assuming JSON response
              "Authorization": obj.AgentToken,
          },
      });
     
      let resultDepartmentData = await departmentData.json();
      if(resultDepartmentData.status === 'Success'){
        const departmentlist = resultDepartmentData.data;
        setDepartmentData(departmentlist);
      }
       //console.log(resultDepartmentData,'dep')
    }


    const gateList = async () => {

      const companyCode = localStorage.getItem('company_code');
  
        
  const companyData = new FormData();
  companyData.append("company_code",companyCode);
  
   const GateData = await axios.post(config.mongo_endpoint + 'api/v1/gate/gateByCompanyCode', companyData,{headers: {                       
     "Authorization" : obj.AgentToken,  
   'Content-Type':'multipart/form-data',      
      }});
       
        //console.log(GateData,'data')
        if(GateData.data.status === "Success"){
          const gatelist = GateData.data.data;
         // console.log(gatelist,'gate')
          setGateData(gatelist);
        }
         
      }

    useEffect(()=>{
      getDepartmentList(); 
      gateList();    
    },[])  

    
const handledepartment = (event) =>{
 // console.log(event)
  const department = event.target.value
 // console.log(department)
  getEmployeeList(department);
}
   
const handlegate = (event) =>{
  // console.log(event)
   const department = event.target.value
 
 }

const getEmployeeList = async (department) => {

  const departmentCode = department; 

  const departmentData = new FormData();
  departmentData.append("department_code",departmentCode);
  
   const resultEmployeeData = await axios.post(config.mongo_endpoint + 'api/v1/employee/department/departmentCode', departmentData,{headers: {                       
      "Authorization" : obj.AgentToken,  
   'Content-Type':'multipart/form-data',      
      }});
   console.log(resultEmployeeData);
  
  
  
  if(resultEmployeeData.data.status === 'Success'){
    const employeelist = resultEmployeeData.data.data;
    setEmployeeData(employeelist);
    //console.log(employeelist,'emp')
  }
  
}
const [businessFlag, setBusinessFlag] = useState('1'); // Default to Individual
const [visitorCompany, setVisitorCompany] = useState('');
const handleBusinessFlagChange = (event) => {
  setBusinessFlag(event.target.value);
  setValue("business_flag",event.target.value)
  //console.log(businessFlag,'b')
  // Clear visitor company when switching back to Individual
  if (event.target.value === '1') {
    setVisitorCompany('');
  }
};


  return (
    <>
      <AgentMenu></AgentMenu>
      <div className="container-fluid pr-0" id="main-area">
        <Navbar />
        <div className="container-fluid">
          <div className="d-flex border-bottom pb-15 pt-15">
            <div className="me">
              <h2 className="page-title">Add Visitor</h2>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb  m-0">
                  {/* <li className="breadcrumb-item"><a href="#">breadcrumb 01</a></li>
                <li className="breadcrumb-item"><a href="#">breadcrumb 02</a></li> */}
                  {/* <li className="breadcrumb-item active" aria-current="page">Update Details </li> */}
                </ol>
              </nav>
            </div>

          </div>
          <section className="voting-section">
            <div className="container">
              <div className="row">

                {/* <h1>Voting</h1> */}
                <Box sx={{ width: '100%', marginBottom: "5%" }}>
                  <>


                    
                      <Box>
                        <Grid container spacing={2}>
                        {
                            showVisitEntrys === true && (
                          <Grid item xs={12}>
                          <form onSubmit={visitorCheckHandlerSubmit(onVisitSubmit)}>
                            <Grid item xs={12}>


                              <div className="video-content">
                                <div className="video-div">
                                  <div className="webcam-out">
                                    {faceMatchStatus === false ? (
                                      <div className="webcam-image">
                                        {picture === "" ? (
                                          <Webcam
                                            audio={false}
                                            height={280}
                                            ref={webcamRef}
                                            width={600}
                                            screenshotFormat="image/jpeg"
                                            videoConstraints={videoConstraints}
                                          />
                                        ) : (
                                          <img src={picture} {...sailorInfoHandler("purpose_of_visit")} />
                                        )}
                                        <div className="video-btn">
                                          <div className="video-btn-lft">
                                            <button
                                              className="btn-black"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                setPicture("");
                                                setfaceMatchChecking(false);
                                                setValue('profile_image', "");
                                              }}
                                            >
                                              Retake
                                            </button>
                                            <button
                                              className="btn-org"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                capture();
                                              }}
                                            >
                                              Capture
                                            </button>
                                          </div>
                                        </div>
                                      </div>

                                    ) : (<div style={styles.container}>
                                      {selectedImage && (
                                        <div style={styles.preview}>
                                          <img
                                            src={URL.createObjectURL(selectedImage)}
                                            style={styles.image}
                                            alt="Thumb"
                                          />
                                          <button onClick={removeSelectedImage} style={styles.delete}>
                                            Remove This Image
                                          </button>
                                        </div>
                                      )}

                                    </div>)}

                                  </div>
                                </div>
                                {visitorCheckErrors.profile_image && <FormHelperText error={!!visitorCheckErrors['profile_image']}>{visitorCheckErrors.profile_image.message}</FormHelperText>}

                              </div>


                            </Grid>
                            <Grid item xs={12}>
                                  <TextField
                                    style={{ marginTop: '15px' }}
                                    fullWidth
                                    size="small"
                                    type="text"
                                    name="mobile_number"
                                    label="Phone *"
                                    {...visitorCheckHandler("mobile_number")}
                                    onChange={handleMobileNo}
                                    id="outlined-error"
                                    error={!!visitorCheckErrors['mobile_number']}
                                    helperText={visitorCheckErrors['mobile_number'] ? visitorCheckErrors['mobile_number'].message : ''}
                                    inputProps={{ maxLength: 10 }}
                                  />
                                </Grid>
                            <Grid container justifyContent="center">
                              {
                                faceMatchChecking === true ? (<Box sx={{ width: '100%' }}>
                                <LinearProgress />
                                <Typography variant="body1" align="center" mt={2}>Face Match Checking...</Typography>
                              </Box>) : (<Box display="flex" justifyContent="center" style={{ marginTop: '15px' }}>
                                <Button
                                      type="submit"
                                      variant="contained"
                                      sx={{ marginTop: "20px", marginLeft: "auto" }} // Align to the right
                                    >
                                      Check Visitor Info
                                    </Button>
                                    
                                </Box>)
                              }
                              
                              
                            </Grid>
                            </form>
                          </Grid>
                      )}
                          {
                            showVisitEntry === true && (
                            <>
                              <Grid item xs={8} >
                                {/* Left Section */}
                                <form onSubmit={sailorInfoHandlerSubmit(onSubmit)}>
                                <Grid item xs={12}>
                                  <TextField
                                    size="small"
                                    fullWidth
                                    type="text"
                                    name="visitor_name"
                                    label="Visitor Name *"
                                    defaultValue={visitorData.visitor_name ? visitorData.visitor_name : ""}
                                    {...sailorInfoHandler("visitor_name")}
                                    id="outlined-error"
                                    error={!!sailorInfoErrors['visitor_name']}
                                    helperText={sailorInfoErrors['visitor_name'] ? sailorInfoErrors['visitor_name'].message : ''}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField
                                    style={{ marginTop: '15px' }}
                                    size="small"
                                    fullWidth
                                    type="text"
                                    name="email_Id"
                                    label="Email ID *"
                                    defaultValue={visitorData.email_id ? visitorData.email_id : ""}
                                    ref={sailorInfoHandler}
                                    {...sailorInfoHandler("email_Id")}
                                    id="outlined-error"
                                    error={!!sailorInfoErrors['email_Id']}
                                    helperText={sailorInfoErrors['email_Id'] ? sailorInfoErrors['email_Id'].message : ''}
                                  />
                                </Grid>

                                <Grid item xs={12}>
                                  <TextField
                                    style={{ marginTop: '15px' }}
                                    fullWidth
                                    size="small"
                                    type="text"
                                    name="mobile_number"
                                    label="Phone *"
                                    defaultValue={visitorData.phone_no ? visitorData.phone_no : ""}
                                    {...sailorInfoHandler("mobile_number")}
                                    onChange={handleMobileNo}
                                    id="outlined-error"
                                    error={!!sailorInfoErrors['mobile_number']}
                                    helperText={sailorInfoErrors['mobile_number'] ? sailorInfoErrors['mobile_number'].message : ''}
                                    inputProps={{ maxLength: 10 }}
                                  />
                                </Grid>
                              
{/* 
                                <Grid item xs={12}>
                                  <TextField
                                    style={{ marginTop: '15px' }}
                                    fullWidth
                                    size="small"
                                    type="text"
                                    name="meeting_person"
                                    label="Meeting Person *"
                                    {...sailorInfoHandler("meeting_person")}
                                    id="outlined-error"
                                    error={!!sailorInfoErrors['meeting_person']}
                                    helperText={sailorInfoErrors['meeting_person'] ? sailorInfoErrors['meeting_person'].message : ''}
                                    inputProps={{ maxLength: 10 }}
                                  />
                                </Grid> */}
                                <Grid item xs={12}>
                                <FormControl fullWidth style={{ marginTop: '15px' }}>
                                                                <InputLabel id="demo-simple-select-label">Gate </InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    size="small"
                                                                    name="gate_name"
                                                                    label="Gate Name "
                                                                    {...sailorInfoHandler("gate_name")}
                                                                    error={!!sailorInfoErrors['gate_name']}
                                                                    helperText={sailorInfoErrors['gate_name'] ? sailorInfoErrors['gate_name'].message : ''}
                                                                  
                                                                    onChange={handlegate}
                                                                  >
                                                                    {gateData.map((res) => (
                                                                      <MenuItem value={res.gate_id}>{res.gate_name}</MenuItem>
                                                                    ))}
                              
                                                                  </Select>
                                                                  {sailorInfoErrors.department_name && <FormHelperText error={!!sailorInfoErrors['gate_name']}>{sailorInfoErrors.gate_name.message}</FormHelperText>}
                                                                </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                <FormControl fullWidth style={{ marginTop: '15px' }}>
                                                                <InputLabel id="demo-simple-select-label">Department *</InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    size="small"
                                                                    name="department_name"
                                                                    label="Company Name *"
                                                                    {...sailorInfoHandler("department_name")}
                                                                    error={!!sailorInfoErrors['department_name']}
                                                                    helperText={sailorInfoErrors['department_name'] ? sailorInfoErrors['department_name'].message : ''}
                                                                  
                                                                    onChange={handledepartment}
                                                                  >
                                                                    {departmentData.map((res) => (
                                                                      <MenuItem value={res.department_code}>{res.department_name}</MenuItem>
                                                                    ))}
                              
                                                                  </Select>
                                                                  {sailorInfoErrors.department_name && <FormHelperText error={!!sailorInfoErrors['department_name']}>{sailorInfoErrors.department_name.message}</FormHelperText>}
                                                                </FormControl>
                                </Grid>
                                

                                <Grid item  xs={12}>
                                                            <FormControl fullWidth style={{ marginTop: '15px' }}>
                                                                <InputLabel id="demo-simple-select-label">Employee  *</InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    size="small"
                                                                    name="meeting_person"
                                                                    label="Employee*"
                                                                    {...sailorInfoHandler("meeting_person")}
                                                                    error={!!sailorInfoErrors['meeting_person']}
                                                                    helperText={sailorInfoErrors['meeting_person'] ? sailorInfoErrors['meeting_person'].message : ''}
                                                                    
                                                                >
                                                                   
                                                                    {
                                                                        employeeData.map((list) => (
                                                                            <MenuItem value={`${list.empid}`}>{list.name}</MenuItem>
                                                                        ))
                                                                    }

                                                                </Select>
                                                                {sailorInfoErrors.meeting_person && <FormHelperText error={!!sailorInfoErrors['meeting_person']}>{sailorInfoErrors.meeting_person.message}</FormHelperText>}
                                                            </FormControl>
                                                        </Grid>



                                <Grid item xs={12}>
                                  <FormControl fullWidth style={{ marginTop: '15px' }}>
                                    <InputLabel id="demo-simple-select-label">Visitor Type*</InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      size="small"
                                      name="visitor_type"
                                      label="Visitor Type *"
                                      {...sailorInfoHandler("visitor_type")}
                                      error={!!sailorInfoErrors['visitor_type']}
                                      helperText={sailorInfoErrors['visitor_type'] ? sailorInfoErrors['visitor_type'].message : ''}
                                    >
                                      {/* <MenuItem value="1">Meeting</MenuItem>
                                      <MenuItem value="2">Enquiry</MenuItem>
                                      <MenuItem value="3">Courier </MenuItem>
                                      <MenuItem value="4">Interview </MenuItem>
                                      <MenuItem value="5">Suppliers</MenuItem>
                                      <MenuItem value="6">Maintenance</MenuItem>
                                      <MenuItem value="7">Others</MenuItem> */}
                                                    <MenuItem value="Meeting">Meeting</MenuItem>
                                                    <MenuItem value="Enquiry">Enquiry</MenuItem>
                                                    <MenuItem value="Courier">Courier </MenuItem>
                                                    <MenuItem value="Interview">Interview </MenuItem>
                                                    <MenuItem value="Suppliers">Suppliers</MenuItem>
                                                    <MenuItem value="Maintenance">Maintenance</MenuItem>
                                                    <MenuItem value="Business">Business</MenuItem>
                                                    <MenuItem value="Personal">Personal</MenuItem>
                                                    <MenuItem value="Others">Others</MenuItem>
                                    </Select>
                                    {sailorInfoErrors.visitor_type && <FormHelperText error={!!sailorInfoErrors['visitor_type']}>{sailorInfoErrors.visitor_type.message}</FormHelperText>}
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField
                                    style={{ marginTop: '15px' }}
                                    fullWidth
                                    name="address"
                                    label="Purpose Of Visit *"
                                    multiline
                                    minRows={3}
                                    maxRows={10}
                                    {...sailorInfoHandler("purpose_of_visit")}
                                    id="outlined-error"
                                    error={!!sailorInfoErrors['purpose_of_visit']}
                                    helperText={sailorInfoErrors['purpose_of_visit'] ? sailorInfoErrors['purpose_of_visit'].message : ''}
                                  />
                                  
                                </Grid>

                                <Grid item xs={12}>
                                <InputLabel id="radio-label">Business Type *</InputLabel>
                                                                
                                  
      <RadioGroup
        aria-label="business_flag"
        name="business_flag"
        value={businessFlag}
        labelId="radio-label"
        label="Business Type"
        onChange={handleBusinessFlagChange}
        style={{ marginTop: '15px' }}
      >
        <FormControlLabel value="1" control={<Radio />} label="Individual" />
        <FormControlLabel value="2" control={<Radio />} label="Organization" />
      </RadioGroup>
    
      {businessFlag === '2' && (
        <TextField
          style={{ marginTop: '15px' }}
          fullWidth
          name="visitor_company"
          label="Visitor Company"
          inputProps={{ maxLength: 10 }}
         
         // value={visitorCompany}
          onChange={(e) => setVisitorCompany(e.target.value)}
          {...sailorInfoHandler("visitor_company")}
          id="outlined-error"
          error={!!sailorInfoErrors['visitor_company']}
          helperText={sailorInfoErrors['visitor_company'] ? sailorInfoErrors['visitor_company'].message : ''}
        
        />
        
      )}
                              
    </Grid>
                                <Grid item xs={6}>
                                  <Button
                                    type="submit"
                                    variant="contained"
                                    sx={{ marginTop: "20px", marginLeft: "auto" }} // Align to the right
                                  >
                                    SUBMIT
                                  </Button>
                                </Grid>
                                </form>
                              </Grid>
                             <Grid item xs={4} >
                             
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                              <Avatar variant={"rounded"} alt="The image" src={picture} style={{ width: 200, height: 200 }} />
                            </div>

                            <Stack sx={{ width: '100%',marginTop: '15px' }} spacing={2}>
                              {visitorStatus === true ? (<Alert variant="filled" severity="success">
                                New Visitor
                              </Alert>) : (<Alert variant="filled" severity="info">
                                Already Visitor
                              </Alert>)}
                            </Stack>
                            
                              </Grid>
                              </>
                            )}
                        </Grid>


                      </Box>

                  



                  </>

                </Box>
              </div>
            </div>
          </section>

        </div >
      </div >
    </>
  );
}

export default AddAgent;