import React, { useState, useEffect, useContext } from "react";
import Topnav from "../../common/topNav";
import { Link , useNavigate} from "react-router-dom";
// import Footer from "../../common/footer";
// import Banner from "../../components/banner";
import ELogo from "../../assets/images/sailor/logo.svg";
import ELogo1 from "../../assets/images/aes-logo.png";
import Lbg from "../../assets/images/sailor/login-bg.svg";
import NoteContext from "../../context/NoteContext";
import { config } from "../../util/apiconfig";
 

import swal from 'sweetalert';


function Manninglogin() {
    const [bannerConfig, setbannerConfig] = useState({ bannerType: 3 });
	const navigate = useNavigate();

	const obj = useContext(NoteContext)

	const [Email ,setEmail] = useState("");
	const [Password ,setPassword] = useState("");
	const [userId, setUserId] = useState(""); //for static purpose userID

	//start for static purpose userID

	useEffect(() => {
		// Check if a user ID is saved in localStorage
		const savedUserId = localStorage.getItem("userId");
	
		if (savedUserId) {
		  setUserId(savedUserId);
		  setUserId('AU001');
		}else {
			// Set a default user ID if not found in localStorage
			const defaultUserId = "AU001";
			setUserId(defaultUserId);
			localStorage.setItem("userId", defaultUserId);
		  }
	  }, []);
//end for static purpose userID
	
	async function submitData(Email , Password){
		if (Email.length === 0) {
			// alert("Enter a Email");
		  }
		else if (Password.length <= 5) {
			// alert("Password length must 6 char");
		  }  else {
			let userData = {Email, Password}

			let data = await fetch(config.mongo_endpoint+"api/v1/manningAgent/logIn", {
			  method: "POST",
			  headers: {
				"Content-type": "application/json",
			  },
			  body: JSON.stringify(userData),
			});
			let res = await data.json();
			console.log("res",res);
			if (res.status === "Success") {
				localStorage.setItem("AgentToken",res.data[0].token);
				localStorage.setItem("name",res.data[0].name);
				localStorage.setItem("type",res.data[0].role_id);
				localStorage.setItem("role_name",res.data[0].role_name);
				localStorage.setItem("company_code",res.data[0].company_code);
				localStorage.setItem("company_name",res.data[0].company_name);
			  obj.setAgentToken(res.data[0].token)
			  obj.setName(res.data[0].name);
			  obj.setType(res.data[0].role_id)	
			  console.log(localStorage.type,'localstorage')
			  //start for static purpose userID
			  localStorage.setItem("userId", userId);
    		// console.log(`User with ID ${userId} logged in.`);	  
			//end for static purpose userID
				navigate("/VisitorDashboard");

			} else {
				 
				 swal("Invalid Username or Password", {
					                    icon: "warning",
					                });

			}
		  }
	}
    return (
        <>

		<div class="login-section bg" style={{backgroundImage: `url(${require("../../assets/images/sailor/login-bg.svg").default})`}}>
			<div class="row-2">
				<div class="login-white">
					<div class="login-lft">
						 
						<div class="login-l">
							<div>
                            {/* <Link to="/"><img src={ELogo} className="img-fluid light-logo" alt="" width="70" /> */}
              				<img src={ELogo1} className="img-fluid logo-txt ml-10" alt="" width="100" height="100" />
            {/* </Link> */}
								<h1><span>Welcome to </span> <br/> Visitor Management System</h1>

								{/* <div class="btn-sct">
									<div class="btn-lnks">
										 
										<div class="officer-lnk"><a href="javascript:void(0);">Manning Agent Login​</a></div>
									</div>
								</div> */}
							</div>
						</div>
					</div>

					<div class="login-rht">
					 

						<div class="col-md-10 col-lg-10 col-xl-9 mx-auto">
							<div class="card-sigin">
								<div class="mb-2 d-flex">


								</div>
								<div class="card-sigin">
									<div class="main-signup-header">

										<h2>Login​</h2>

										
											<div class="form-group">
											<input
        type="hidden"
        placeholder="User ID"
        value={userId}
        onChange={(e) => setUserId(e.target.value)}
      />
												<label>Email address</label> <input class="form-control"
													placeholder="Enter your email" type="text" value = {Email} onChange={(e)=>{setEmail(e.target.value)}}/>
											</div>
											<div class="form-group">
												<label>Password</label> <input class="form-control"
													placeholder="Enter your Password" type="password" value = {Password} onChange={(e)=>{setPassword(e.target.value)}}/>
											</div>
									 
											<button class="btn btn-main-primary btn-block" onClick={()=>{submitData(Email,Password)}}> Login</button>
										
										{/* <div class="main-signin-footer mt-3"> 
											<p>Didn't have an account ? 
												<a href="javascript:void(0);" class="resend">
													Create Account</a>
													</p>
										</div> */}
									</div>
								</div>
							</div>
						</div>
					</div>



				</div>

			</div>
		</div>





        </>
    );
}

export default Manninglogin;