import React, { useState, useEffect, useRef } from "react";
import SideMenu from "../../components/SideMenu";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import PhoneInput from 'react-phone-input-2'
import dayjs from 'dayjs';
import 'react-phone-input-2/lib/material.css'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FormHelperText } from '@mui/material';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { Grid, TextField } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { config } from "../../util/apiconfig";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'
import { Controller, useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useNavigate, useLocation } from "react-router-dom";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useParams } from "react-router";
import ELogo from "../../assets/images/sailor/logo.svg";
import Ham from "../../assets/images/sailor/ham.svg";
import Navbar from "../Navbar";
const countrycitystateHandler = require('countrycitystatejson');
const sailorInformation = yup.object().shape({
  // first_name: yup.string().required("First Name Required").matches(/^[A-Za-z\s]*$/,"Only Alphabets allowed"),
  // last_name: yup.string().required("Last Name Required").matches(/^[A-Za-z\s]*$/,"Only Alphabets allowed"),
  // date_of_birth: yup.string().required("Date of birth Required"),
  // place_of_birth: yup.string().required("Place of birth Required").matches(/^[A-Za-z\s]*$/,"Only Alphabets allowed"),
  // blood_group: yup.string().required("Blood Group Required"),
  // marital_status: yup.string().required("Marital Status Required"),
  // country: yup.string().required("Country Required"),
  // nationality: yup.string().required("Nationality Required"),
  // mobile_number: yup
  //       .string()
  //       .required("Phone Number is required")
  //       .matches(
  //         /^[0-9]{10,15}$/,
  //         'Phone number must be between 10 to 15 digits'
  //       ), // Use regex pattern to validate phone number format,
  // emergency_person: yup.string().required("Emergency Contact Person Required"),
  // emergency_number: yup.string().required("Emergency Contact Number Required"),
  // address: yup.string().required("Address Required"),
  // permanent_address: yup.string().required("Permanent Address Required"),
  
});

const accountInformation = yup.object().shape({
  old_password: yup.string().required("Old password Required"),
  Email: yup.string().required("Old password Required"),
  // password: yup.string()
  //         .required('Please Enter your password')
  //         .matches(
  //           /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
  //           "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
  //         ),
  password: yup
  .string()
  .required('Password is required')
  .min(8, 'Password must be at least 8 characters'),
confirm_password: yup
  .string()
  .required('Confirm Password is required')
  .oneOf([yup.ref('password'), null], 'Passwords must match'),

});

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:before': {
    display: 'none',
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,

  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,

  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '17px' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },

}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));


const steps = ['Personal Information'];


function Updatedetails() {
  let CryptoJS = require("crypto-js");
  var FormData = require('form-data');
  const baseurl = useLocation();
  const [sailor_info, setsailor_info] = useState([])
  const [loader, setLoader] = useState(false)
  const [state_list, setStatelist] = useState([])

  const [country, setCountry] = useState('');
  const [first_name, setFirstname] = useState('');
  const [last_name, setLastname] = useState('');
  const [middle_name, setMiddlename] = useState('');
  const [emergency_person, setEperson] = useState('');
  const [emergency_number, setEnumber] = useState('');
  const [nationality, setNationality] = useState('');
  const [address, setAddress] = useState('');
  const [permanent_address, setPermanent] = useState('');
  const [place, setPlace] = useState('');
  const [dob, setDob] = React.useState(dayjs(''));
  const [maritalStatus, setmaritalStatus] = React.useState('');
  const [blood, setBlood] = React.useState('');
  const [mobile_number, setMobilenumber] = React.useState('');
  const [country_list, setCountrylist] = useState([])
  const [emaildata, setEmaildata] = useState('')
  const [applicant, setApplicant] = useState('')
  // const [mobile_number, setMobilenumber] = React.useState('+911234567890');
  
  useEffect(() => {
    getStateList();
    fetchApplicantData();
    getCountryList();   
  }, []);

 
  const getStateList = () => {
    let data = countrycitystateHandler.getStatesByShort('IN');
    console.log(data);
    let obj={};
    let stateListData=[];
    data.map((item,index) => {
      obj={
        value: `${item}`,
        label: `${item}`
      };
      stateListData.push(obj);
    })
    setStatelist(stateListData);
  }

  // Country List Starts
const getCountryList = () => {
  let data = countrycitystateHandler.getCountries();
  console.log(data);
  let obj={};
  let countryListData=[];
  data.forEach((country, index) => {
    obj={
      value: `${country.name}`,
      label: `${country.name}`
    };
    countryListData.push(obj);
  });
  setCountrylist(countryListData);
}


// Country List End


  

  


  
  // =================== Voter Info Tab-1 Validation  ================
  // =================== Voter Info Yup Resolver Starts  ================
  const prefill = {
    first_name: "raj",
    middle_name: "M",
    last_name: "M",
    country: "Goa",
    nationality: "Indian",
    mobile_number: "9874563251",
    emergency_person: "sam",
    emergency_number: "98745632154",
    address: "covai",
    permanent_address: "covai",
    blood_group: "o+",
    place_of_birth: "Tamil Nadu"
  }
  const { register: sailorInfoHandler,
    handleSubmit: sailorInfoHandlerSubmit,
    reset: sailorInfoReset,
    setValue,
    control,
    watch,
    formState: { errors: sailorInfoErrors } } = useForm({
      resolver: yupResolver(sailorInformation),
      mode: 'OnSubmit',
      defaultValues: prefill
      
    });
    // const maritalStatus = watch('marital_status');

    const { register: accountInfoHandler,
      handleSubmit: accountInfoHandlerSubmit,
      reset: accountInfoReset,
      formState: { errors: accountInfoErrors } } = useForm({
        resolver: yupResolver(accountInformation),
        mode: 'OnSubmit',
      });
     
      // React.useEffect(() => {
      //   setFocus("first_name")
      //   setFocus("last_name")
      //   setFocus("middle_name")
      //   setFocus("country")
      // }, [setFocus])
      const onSubmitAccountInfo = async(data) => {                
        try{
          const confirm_psw = {            
            Email: emaildata,
            oldpassword: data["old_password"],
            newpassword: data["password"],
          }
          console.log(confirm_psw)
          await axios.post(config.mongo_endpoint+"api/v1/sailorUser/changepassword", confirm_psw, { headers: {  'Content-Type': 'application/x-www-form-urlencoded'} })
          .then(result => {
            console.log("fetch",result);
            if(result.status == 200 && result.data.status === "success"){
              toast.success("Password Updated Successfully.", {
                position: toast.POSITION.TOP_CENTER,
                theme: "colored",               
              })
              accountInfoReset()
              // LandingScreen();
              
            } else if(result.data.status === "error"){
              toast.error("Old password doesn't match", {
                position: toast.POSITION.TOP_CENTER,
                theme: "colored",
              });  
            }           
            
          })
          .catch(error => {
            // handle the error
            console.error(error);
            if (error) {
              
              toast.error("Something went wrong. Please try again Later....", {
              position: toast.POSITION.TOP_CENTER,
              theme: "colored",
            });
    
            }
          
          });
        }catch (error) {
          console.error(error);
          // if (error)
          setLoader(false);
          if (error) {
            // alert("Something went wrong. Please try again Later....")
          }
          console.log(error.code);
          // setError(error.message);
    
          // }
        }
       
       
        

    }

    const handleDateChange = (formdate) => {
      if (formdate) {
        const date = new Date(formdate);
        // Format the date to YYYY-MM-DD
        const formattedDate = date.toLocaleDateString('en-GB', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        }).split('/').reverse().join('-');
        return formattedDate;
      }
     
    };

    const fetchApplicantData = async() => {

      const applicant_info = await axios.get(config.mongo_endpoint+"api/v1/sailorUser/viewApplication", {  headers: {
        "Content-type": "application/json",
        "x-access-token" : `${localStorage.getItem('Token')}`
  
        } });
  
        if(applicant_info.status === 200){
          let response = applicant_info.data.data[0];
          console.log("fetch",response);
          console.log(response);
          setApplicant(response)
          setEmaildata(response.Email); 
          setValue('first_name',response.First_Name);
          setValue('middle_name',response.Middle_Name);
          setValue('last_name',response.Last_Name);
          setValue('date_of_birth',response.Date_of_Birth);
          setDob(dayjs(handleDateChange(response.Date_of_Birth)));
          // setDob(dayjs(response.Date_of_Birth));
          setValue('place_of_birth',response.Place_of_Birth);
          setValue('blood_group',response.Blood_Group);
          setValue('marital_status',response.Maritial_Status);
          setValue('country',response.Country);
          setValue('nationality',response.Nationality);
          setValue('mobile_number',response.Phone);
          setValue('emergency_person',response.Emergency_Contact_Person);
          setValue('emergency_number',response.Emergency_Contact_Number);
          setValue('address',response.Corresponding_Address);
          setValue('permanent_address',response.Permanent_Address);

          // setValue('date_of_birth',dayjs('2022-04-17'));
          
          setmaritalStatus(response.Maritial_Status);
          setCountry(response.Country)
          setPlace(response.Place_of_Birth)
          
          
          // setValue('marital_status',response.Maritial_Status);
          setBlood(response.Blood_Group);
          // setCountry("Goa");
         
          setValue('last_name',response.Last_Name);
          // setValue('last_name',response.Last_Name);
          // setValue('last_name',response.Last_Name);
          setCountry(response.Country);
          // setFirstname(response.First_Name);
        }
  
        
      
        console.log("fetch",country);
    }
    const onSubmitPersonalInfo = async(data) => {

      console.log("personal",data);
      // let sailor_request = new FormData();
      try{
          const sailor_request = {
            Email:emaildata,
            First_Name:data["first_name"],
            Middle_Name:data["middle_name"],
            Last_Name:data["last_name"],
            Date_of_Birth:handleDateChange(dob),
            Place_of_Birth:data["place_of_birth"],
            Blood_Group:data["blood_group"],
            Maritial_Status:data["marital_status"],
            Country:data["country"],
            Nationality:data["nationality"],
            Phone:data["mobile_number"],
            Emergency_Contact_Person:data["emergency_person"],
            Emergency_Contact_Number:data["emergency_number"],
            Corresponding_Address:data["address"],
            Permanent_Address:data["permanent_address"],
          }
          console.log(sailor_request)

          await axios.post(config.mongo_endpoint+'api/v1/sailorUser/update', sailor_request, { headers: {  'Content-Type': 'application/x-www-form-urlencoded'} })
                    .then(result => {
                      console.log("fetch",result);
                      if(result.status == 200){
                        toast.success("Personal Info Updated Successfully.", {
                          position: toast.POSITION.TOP_CENTER,
                          theme: "colored",
                        });  
                        // LandingScreen();
                      } else {
                        toast.success("Personal Info Updated Failed", {
                          position: toast.POSITION.TOP_CENTER,
                          theme: "colored",
                        });  
                      }
                      
                      
                    })
                    .catch(error => {
                      // handle the error
                      console.error(error);
                      if (error) {
                        
                        toast.error("Something went wrong. Please try again Later....", {
                        position: toast.POSITION.TOP_CENTER,
                        theme: "colored",
                      });
              
                      }
                    
                    });

  
    } catch (error) {
      console.error(error);
      setLoader(false);
      if (error) {
        alert("Something went wrong. Please try again Later....")
      }
      console.log(error.code);       
      }      
     
          }
console.log("PLACEOFBIRTH", place)


  return (
    <>

<SideMenu></SideMenu>

<div className="container-fluid pr-0" id="main-area">
<Navbar />
<div className="container-fluid">
    <div className="d-flex border-bottom pb-15 pt-15">
        <div className="me-auto ">
            <h2 className="page-title">Update Details</h2>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb  m-0">
                    {/* <li className="breadcrumb-item"><a href="#">breadcrumb 01</a></li>
                    <li className="breadcrumb-item"><a href="#">breadcrumb 02</a></li> */}
                    {/* <li className="breadcrumb-item active" aria-current="page">Update Details </li> */}
                </ol>
            </nav>
        </div>

    </div>
    <section className="voting-section">
        <div className="container">
          <div className="row">

            {/* <h1>Voting</h1> */}
            <Box sx={{ width: '100%' }}>
              <Stepper>
             
                    <Step>
                      <StepLabel>Personal Information</StepLabel>
                    </Step>
                 
              </Stepper>

             
                <>
                  
                    <div className="stepper-content">
                    <form onSubmit={accountInfoHandlerSubmit(onSubmitAccountInfo)}>
                      <Typography variant="h6" mb={3} sx={{ color: "#333", textAlign: "left", }}>
                        User Account Details
                      </Typography>

                      <Box mb={3.5}>
                        <Grid container spacing={3}>
                          <Grid item sm={4} xs={12}>
                            <TextField
                              size="small"
                              fullWidth
                              // inputRef={passwordRef}
                              // value={sailorForm.first_name}
                              type="password"
                              name="old_password"
                              label="Old Password *"
                              {...accountInfoHandler("old_password")}
                              id="outlined-error"
                              error={!!accountInfoErrors['old_password']}
                              helperText={accountInfoErrors['old_password'] ? accountInfoErrors['old_password'].message : ''}
                            />
                            <input type="hidden" name="email" value="hiddenValue" {...accountInfoHandler("Email")} />

                          </Grid>
                          <Grid item sm={4} xs={12}>
                            <TextField
                              size="small"
                              fullWidth
                              type="password"
                              name="password"
                              label="Password *"
                              {...accountInfoHandler("password")}
                              id="outlined-error"
                              error={!!accountInfoErrors['password']}
                              helperText={accountInfoErrors['password'] ? accountInfoErrors['password'].message : ''}
                            />

                          </Grid>
                          <Grid item sm={4} xs={12}>
                            <TextField
                              size="small"
                              fullWidth
                              type="password"
                              name="confirm_password"
                              label="Confirm Password *"
                              {...accountInfoHandler("confirm_password")}
                              id="outlined-error"
                              error={!!accountInfoErrors['confirm_password']}
                              helperText={accountInfoErrors['confirm_password'] ? accountInfoErrors['confirm_password'].message : ''}
                            />

                          </Grid>
                        </Grid>
                       
                      </Box>
                      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button variant="contained" color="primary" type="submit">
                          Update Password
                        </Button>
                      </div>
                      </form>
                      <form onSubmit={sailorInfoHandlerSubmit(onSubmitPersonalInfo)}>
                      <Typography variant="h6" mb={3} sx={{ color: "#333", textAlign: "left", }}>
                        Personal Information
                      </Typography>
                      <Box mb={3.5}>
                        <Grid container spacing={3}>
                          <Grid item sm={4} xs={12}>
                            <TextField
                              size="small"
                              fullWidth
                              type="text"
                              name="first_name"
                              label="First Name *"
                              defaultValue={first_name}
                              {...sailorInfoHandler("first_name")}
                              id="outlined-error"
                              error={!!sailorInfoErrors['first_name']}
                              helperText={sailorInfoErrors['first_name'] ? sailorInfoErrors['first_name'].message : ''}
                            />
                          </Grid>
                          <Grid item sm={4} xs={12}>
                            <TextField                              
                              size="small"
                              fullWidth
                              type="text"
                              name="middle_name"
                              label="Middle Name"
                              defaultValue={middle_name}
                              {...sailorInfoHandler("middle_name")}
                              id="outlined-error"
                              error={!!sailorInfoErrors['middle_name']}
                              helperText={sailorInfoErrors['middle_name'] ? sailorInfoErrors['middle_name'].message : ''}
                              
                            />
                          </Grid>
                          <Grid item sm={4} xs={12}>
                            <TextField
                              fullWidth
                              type="text"
                              size="small"
                              name="last_name"
                              label="Last Name *"
                              defaultValue={last_name}
                              {...sailorInfoHandler("last_name")}
                              id="outlined-error"
                              error={!!sailorInfoErrors['last_name']}
                              helperText={sailorInfoErrors['last_name'] ? sailorInfoErrors['last_name'].message : ''}
                              
                            />
                          </Grid>
                          <Grid item sm={4} xs={12}>                            
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={['DatePicker', 'DatePicker', 'DatePicker']}>                           
                            <DatePicker
                              label="Date of Birth *"
                              value={dob}
                              // selected={dob}                              
                              // defaultValue={dob}
                              name="date_of_birth"
                              {...sailorInfoHandler("date_of_birth")}
                              slotProps={{ textField: { size: 'small' } }}
                              onChange={(date_of_birth) => { 
                                console.log(date_of_birth)                                                              
                                setDob(dayjs(date_of_birth))
                              }}
                              sx={{ width: '100%' }}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                          {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={['DatePicker', 'DatePicker', 'DatePicker']}>
                              <DatePicker label="Uncontrolled picker" defaultValue={dayjs('2022-04-17')} />
                                <DatePicker
                                  //  inputRef={date_of_birthRef}
                                   value={initialDate}
                                  label="Date of Birth *"
                                  {...sailorInfoHandler("date_of_birth")}
                                  slotProps={{ textField: { size: 'small' } }}
                                  onChange={(date_of_birth) => {
                                    setValue('date_of_birth', date_of_birth); 
                                  }}
                                />
                              </DemoContainer>
                            </LocalizationProvider> */}

                            {sailorInfoErrors.date_of_birth && <FormHelperText error={!!sailorInfoErrors['date_of_birth']}>{sailorInfoErrors.date_of_birth.message}</FormHelperText>}
                          </Grid>
                          <Grid item sm={4} xs={12}>
                            
                          <FormControl fullWidth>                         
                              <InputLabel id="demo-simple-select-label">Place of Birth *</InputLabel>                               
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"                                                           
                                size="small"
                                value={place}                               
                                name="place_of_birth"                                
                                label="Place of Birth *"                             
                                {...sailorInfoHandler("place_of_birth")}
                                error={!!sailorInfoErrors['place_of_birth']}
                                helperText={sailorInfoErrors['place_of_birth'] ? sailorInfoErrors['place_of_birth'].message : ''}
                                onChange={(place_of_birth) => {
                                  console.log(place_of_birth)
                                  setValue('place_of_birth', place_of_birth);
                                  setPlace(place_of_birth.target.value);
                                }}
                              >
                                <MenuItem value="0">Select</MenuItem>
                                {
                                  state_list.map((list) => (
                                    <MenuItem value={list.value}>{list.label}</MenuItem>
                                  ))
                                }

                              </Select>
                              {sailorInfoErrors.place_of_birth && <FormHelperText error={!!sailorInfoErrors['place_of_birth']}>{sailorInfoErrors.place_of_birth.message}</FormHelperText>}
                            </FormControl>
                           
                          </Grid>
                          <Grid item sm={4} xs={12}>                          
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">Blood Group *</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={blood}
                                size="small"
                                name="blood_group"                               
                                label="Blood Group *"
                                {...sailorInfoHandler("blood_group")}
                                onChange={(blood_group) => {
                                  console.log(blood_group)
                                  setValue('blood_group', blood_group);
                                  setBlood(blood_group.target.value);
                                }}
                                error={!!sailorInfoErrors['blood_group']}
                                helperText={sailorInfoErrors['blood_group'] ? sailorInfoErrors['blood_group'].message : ''}
                              >
                                <MenuItem value="A+" >A+</MenuItem>
                                <MenuItem value="O+">O+</MenuItem>
                                <MenuItem value="B+">B+</MenuItem>
                                <MenuItem value="AB+">AB+</MenuItem>
                                <MenuItem value="A-">A-</MenuItem>
                                <MenuItem value="O-">O-</MenuItem>
                                <MenuItem value="B-">B-</MenuItem>
                                <MenuItem value="AB">AB-</MenuItem>
                              </Select>
                              {sailorInfoErrors.blood_group && <FormHelperText error={!!sailorInfoErrors['blood_group']}>{sailorInfoErrors.blood_group.message}</FormHelperText>}
                            </FormControl>

                          </Grid>
                          <Grid item sm={4} xs={12}>
                           
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">Marital Status*</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                size="small"
                                value={maritalStatus}                                
                                name="marital_status"
                                label="Marital Status *"
                                {...sailorInfoHandler("marital_status")}
                                error={!!sailorInfoErrors['marital_status']}
                                helperText={sailorInfoErrors['marital_status'] ? sailorInfoErrors['marital_status'].message : ''}
                                onChange={(marital_status) => {
                                  console.log(marital_status)
                                  setValue('marital_status', marital_status);
                                  setmaritalStatus(marital_status.target.value);
                                }}
                              >
                                <MenuItem value="Single">Single</MenuItem>
                                <MenuItem value="Married">Married</MenuItem>
                                <MenuItem value="Widowed">Widowed </MenuItem>
                                <MenuItem value="Divorced">Divorced </MenuItem>
                              </Select>
                              {sailorInfoErrors.marital_status && <FormHelperText error={!!sailorInfoErrors['marital_status']}>{sailorInfoErrors.marital_status.message}</FormHelperText>}
                            </FormControl>

                          </Grid>
                          <Grid item sm={4} xs={12}>
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">Country *</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                size="small"
                                value={country}
                                name="country"
                                defaultValue={country}
                                label="Country *"
                                {...sailorInfoHandler("country")}
                                error={!!sailorInfoErrors['country']}
                                helperText={sailorInfoErrors['country'] ? sailorInfoErrors['country'].message : ''}
                                onChange={(country) => {
                                  console.log(country)
                                  setValue('country', country);
                                  setCountry(country.target.value);
                                }}
                              >
                                <MenuItem value="Country">Select Country</MenuItem>
                                {
                                  country_list.map((list) => (
                                    <MenuItem value={list.value}>{list.label}</MenuItem>
                                  ))
                                }

                              </Select>
                              {sailorInfoErrors.country && <FormHelperText error={!!sailorInfoErrors['country']}>{sailorInfoErrors.country.message}</FormHelperText>}
                            </FormControl>
                          </Grid>
                          <Grid item sm={4} xs={12}>
                            <TextField
                              fullWidth
                              type="text"
                              size="small"
                              defaultValue={nationality}
                              name="nationality"
                              label="Nationality *"
                              {...sailorInfoHandler("nationality")}
                              id="outlined-error"
                              error={!!sailorInfoErrors['nationality']}
                              helperText={sailorInfoErrors['nationality'] ? sailorInfoErrors['nationality'].message : ''}
                              inputProps={{ maxLength: 10 }}
                            />
                          </Grid>
                          <Grid item sm={4} xs={12}>

                          
      
                             <Controller
                               control={control}
                             
                              name="mobile_number"
                               render={({ field }) => (
                                 <PhoneInput
                                   country={'in'}
                                   {...field}
                                   inputProps={{
                                     name: "mobile_number",
                                     required: true,
                                     defaultValue: mobile_number,
                                   }}
                                 />
                               )}
                             />
                            {sailorInfoErrors.mobile_number && <FormHelperText error={sailorInfoErrors.mobile_number}>{sailorInfoErrors.mobile_number.message}</FormHelperText>}

                         </Grid>

                          <Grid item sm={4} xs={12}>
                            <TextField
                              fullWidth
                              size="small"
                              type="text"
                              defaultValue={emergency_person} 
                              name="emergency_person"
                              label="Emergency Contact Person *"
                              {...sailorInfoHandler("emergency_person")}
                              id="outlined-error"
                              error={!!sailorInfoErrors['emergency_person']}
                              helperText={sailorInfoErrors['emergency_person'] ? sailorInfoErrors['emergency_person'].message : ''}
                              inputProps={{ maxLength: 10 }}
                            />
                          </Grid>
                          <Grid item sm={4} xs={12}>
                            <TextField
                              fullWidth
                              defaultValue={emergency_number} 
                              size="small"
                              type="text"
                              name="emergency_number"
                              label="Emergency Contact Number *"
                              {...sailorInfoHandler("emergency_number")}
                              id="outlined-error"
                              error={!!sailorInfoErrors['emergency_number']}
                              helperText={sailorInfoErrors['emergency_number'] ? sailorInfoErrors['emergency_number'].message : ''}
                              inputProps={{ maxLength: 10 }}
                            />
                          </Grid>
                          <Grid item sm={6} xs={12}>
                            <TextField
                              fullWidth
                              defaultValue={address} 
                              name="address"
                              label="Corresponding Address *"
                              multiline
                              minRows={3}
                              maxRows={10}
                              {...sailorInfoHandler("address")}
                              id="outlined-error"
                              error={!!sailorInfoErrors['address']}
                              helperText={sailorInfoErrors['address'] ? sailorInfoErrors['address'].message : ''}
                            />
                          </Grid>
                          <Grid item sm={6} xs={12}>
                            <TextField
                              fullWidth
                              defaultValue={permanent_address} 
                              name="permanent_address"
                              label="Permanent Address*"
                              multiline
                              minRows={3}
                              maxRows={10}
                              {...sailorInfoHandler("permanent_address")}
                              id="outlined-error"
                              error={!!sailorInfoErrors['permanent_address']}
                              helperText={sailorInfoErrors['permanent_address'] ? sailorInfoErrors['permanent_address'].message : ''}
                            />
                          </Grid>
                      
                        </Grid>
                      </Box>
                      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button variant="contained" color="primary" type="submit">
                          Update Information
                        </Button>
                      </div>
                      </form>
                      {
                        loader === true && (<Box sx={{ width: '100%' }}>
                          <div className="loading-div">
                            <div className="loading-inn">
                              <img src={ELogo} />
                              <LinearProgress />
                              <p>Processing Please Wait...</p>
                            </div>
                          </div>
                        </Box>)
                      }
                     
                      <ToastContainer />
                    </div>
                 
                  
                </>
          
            </Box>
          </div>
        </div>
      </section>

</div>
</div>
 


      

   
   
    </>
  );
}

export default Updatedetails;