import React, { useState, useEffect,useContext } from "react";
import Topnav from "../../common/topNav";
import { Link ,useNavigate} from "react-router-dom";
// import Footer from "../../common/footer";
// import Banner from "../../components/banner";
import Ham from "../../assets/images/sailor/ham.svg";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AgentMenu from "../../components/AgentMenu";
import NoteContext from "../../context/NoteContext";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Button from '@mui/material/Button';
import { config } from "../../util/apiconfig";
import axios from 'axios';
import Navbar from "../Navbar";
import swal from 'sweetalert';
import Moment from 'react-moment';
import handleApiError from "../../util/apiUtil";



function ManageDepartment() {
    const obj = useContext(NoteContext);
    const [data, setData] = useState([]);
    const navigate=useNavigate();

   
    const getDepartmentList = async () => {
        try {
            const response = await axios.get(config.mongo_endpoint + "api/v1/department/all", {
                headers: {
                    "Authorization": obj.AgentToken
                }
            });
    
            const resultDepartmentData = response.data;
    
            if (resultDepartmentData.status === 'Success') {
                const departmentList = resultDepartmentData.data;
                const loginType = obj.type;
                const loginCompany = localStorage.getItem('company_code');
    
                if (loginType === 0) {
                    // Admin or unrestricted access
                    setData(departmentList);
                } else {
                    // Regular user, filter departments by company code
                    const filteredDepartments = departmentList.filter(dep => dep.company_code === loginCompany);
                    setData(filteredDepartments);
                }
            } else {
                throw new Error('Failed to fetch department data: ' + resultDepartmentData.message);
            }
        } catch (error) {
            console.error('Error fetching department data:', error);
            handleApiError(error, navigate);
        }
    };

    useEffect(()=>{
        getDepartmentList();     
    },[])
   

    const deleteCompany = async (departmentCode) =>{
       // console.log(departmentCode)
        const departmentdatas = {
                    id : departmentCode,              
                }
                //console.log(departmentdatas)
          swal({
              title: "Are you sure?",
              text: "Once deleted, you will not be able to recover this data!",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            })
            .then((willDelete) => {
              if (willDelete) {
                   axios.post(config.mongo_endpoint+"api/v1/department/delete", departmentdatas, {
                      headers: {"Content-type": "application/x-www-form-urlencoded", "x-access-token":obj.AgentToken} ,                   
                  })
                  getDepartmentList(); 
                  swal("Department  Deleted....!", {
                      icon: "success",
                    });
                    getDepartmentList();    
              } else {
                  swal("Cancelled");
              }              
            }); 
                    
      } 
      
      async function editCompany(resdata){
       // console.log("ROWEDIT**", resdata)
        obj.setUpdate_company(resdata)
        obj.setUpdatingCompanyFlag(1);
      }

    return (
        <>

            <AgentMenu></AgentMenu>

            <div className="container-fluid pr-0" id="main-area">
                      
                <Navbar />
                
                <div className="container-fluid">
                    <div className="d-flex border-bottom pb-15 pt-15">
                        <div className="me-auto ">
                            <h2 className="page-title">Manage Departments</h2>
                        </div>
                        <div>
                        <Link to="/AddDepartment" className="nav-link" >
                            <Button variant="contained" color="success">
  Add Departments
</Button></Link></div>
                    </div>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table" >
                            <TableHead>
                                <TableRow>                                    
                                    <TableCell align="center" style={{ background: '#ddd' }}># </TableCell>
                                    {/* <TableCell align="center" style={{ background: '#ddd' }}>Company Name </TableCell> */}
                                    <TableCell align="center" style={{ background: '#ddd' }}>Company </TableCell>
                                    <TableCell align="center" style={{ background: '#ddd' }}>Department Code</TableCell>
                                    <TableCell align="center" style={{ background: '#ddd' }}>Department</TableCell>
                                    <TableCell align="center" style={{ background: '#ddd' }}>Action</TableCell>                       
                                </TableRow>
                            </TableHead>
                            <TableBody>                                                               
                              {data.map((resdata,index)=>(
                                    <TableRow
                                    key={index}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell align="center" style={{ padding: '5px 15px' }}>{index + 1}</TableCell>
                                    {/* <TableCell align="center" style={{ padding: '5px 15px' }}>{resdata.company_name}</TableCell> */}
                                    <TableCell align="center" style={{ padding: '5px 15px' }}>{resdata.company_name}</TableCell>
                                    <TableCell align="center" style={{ padding: '5px 15px' }}>{resdata.department_code}</TableCell>
                                    <TableCell align="center" style={{ padding: '5px 15px' }}>{resdata.department_name}</TableCell>                                       
                                              
                                    <TableCell align="center" style={{ padding: '5px 15px' }}>   
                                   
                                    {/* <EditIcon onClick={() => {editAgent()}} /> */}
                                    <Link to="/UpdateDepartment" onClick={()=>{editCompany(resdata)}}><EditIcon /></Link>
                                    <DeleteIcon onClick={()=>deleteCompany(resdata.id)} /> 
                                    {/* <VisibilityIcon></VisibilityIcon>                            */}
                     
                                </TableCell>
                            </TableRow>
                              ))}                                                                                       
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                </div>
          





        </>
    );
}

export default ManageDepartment;