import React, { useState, useEffect, useContext } from "react";
import { Box } from "@mui/material";
import AgentMenu from "../../components/AgentMenu";
import NoteContext from "../../context/NoteContext";
import Button from '@mui/material/Button';
import { FormHelperText, Grid, TextField } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useForm } from "react-hook-form";
import 'react-phone-input-2/lib/material.css'
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import Navbar from "../Navbar";
import axios from 'axios';
import { config } from "../../util/apiconfig";
import swal from 'sweetalert';
import handleApiError from "../../util/apiUtil";





function UpdateUser() {
    // const { AgentId } = match.params;
    // const isAddMode = !AgentId;
    // =================== Voter Info Tab-1 Validation  ================

    const sailorInformation = yup.object().shape({
        company_name: yup.string().required("Company Required"),

        department_code: yup.string().required("Department Code Required"),
        name: yup.string().required("Name Required"),
        phone: yup.string().required("Phone Required"),
        //password: yup.string().required("Password Required"),
        email: yup.string().required("Email Required"),
    })

    // =================== Voter Info Tab-1 Validation  ================
    // =================== Voter Info Yup Resolver Starts  ================
    const { register: sailorInfoHandler,
        handleSubmit: sailorInfoHandlerSubmit,
        reset: voterInfoReset,
        setValue,
        formState: { errors: sailorInfoErrors } } = useForm({
            resolver: yupResolver(sailorInformation),
            mode: 'OnSubmit',
        });




    const obj = useContext(NoteContext)

    const [update_company, setUpdate_company] = useState(obj.update_company)
    const [company, setCompany] = useState('');
    const [department, setDepartment] = useState('');
    
    const [readonlyCompany, setReadonlyCompany] = useState(false);
    



    console.log("UPDATE=>", update_company)


    const navigate = useNavigate();
    const LandingScreen = () => {
        navigate("/ViewUsers");
    }


    const [companyData, setcompanyData] = useState([]);
    const [departmentData, setDepartmentData] = useState([]);


    const getCompanyList = async () => {
        let companyData = await fetch(config.mongo_endpoint + "api/v1/company/getall", {
            method: "GET",
            headers: {
                "Authorization": obj.AgentToken,
                 }
        });

        let resultCompanyData = await companyData.json();
        if (resultCompanyData.status === 'Success') {
            const companylist = resultCompanyData.data;
            setcompanyData(companylist);
            
        }
        //   console.log(resultCompanyData)
    }


    useEffect(() => {
        getCompanyList();  
        //getDepartmentList();
        DepartmentList(update_company.company_code);
        setValue('name', update_company.name);  
        setValue('department_code', update_company.department_code);
        setValue('phone', update_company.phone);
        setValue('email', update_company.email);
        setValue('company_name',update_company.company_code);
        setCompany(update_company.company_code);
        // getCompany(update_company.company_code);  // Fetches departments for the selected company
        setDepartment(update_company.department_code);  // Sets department code
        const logintype = obj.type;
        const logincompany = localStorage.getItem('company_code');

        if (logintype !== 0) {
            setCompany(logincompany);
            setValue('company_name',logincompany);
            setReadonlyCompany(true);
        }
    }, []);



    const onSubmit = async (data) => {
        const userinfo = {
            company_code: data["company_name"],
            department_code: data["department_code"],
            name: data["name"],
            phone: data["phone"],
            employee_id: update_company.empid,
            email: data["email"],
            Password : data["password"]
        }
        // if (data['password'] !== '') {
        //     userinfo.Password = data["password"];
        // }

        console.log(userinfo)
        await axios.post(config.mongo_endpoint + 'api/v1/employee/update', userinfo,
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': obj.AgentToken
                }
            })
            .then(result => {
                console.log(result.data.status, 'result')
                if (result.data.status == "Success") {
                    // toast.success("UserInformation updated Successfully.", {
                    //     position: toast.POSITION.TOP_CENTER,
                    //     theme: "colored",
                    // });
                    swal("UserInformation updated Successfully....!", {
                        icon: "success",
                    });
                    LandingScreen();

                } else {
                    // toast.success("Some Thing went wrong try again later.", {
                    //     position: toast.POSITION.TOP_CENTER,
                    //     theme: "colored",
                    // });
                    swal("Some Thing went wrong try again later....!", {
                        icon: "warning",
                    });
                }


            })
            .catch(error => {
                // handle the 
                handleApiError(error,navigate)
                console.error(error);
                if (error) {

                    // toast.error("Some thing When wrong. Please try again Later....", {
                    //     position: toast.POSITION.TOP_CENTER,
                    //     theme: "colored",
                    // });
                    swal("Some thing When wrong. Please try again Later....!", {
                        icon: "warning",
                    });

                }

            });

    }



    const handleCompanyChange = (event) => {
        const company_code = event.target.value;
        console.log('Company Code selected:', company_code);
        setCompany(company_code);
        DepartmentList(company_code); // Assuming DepartmentList is defined properly
    };

    const getDepartmentList = async () => {

        let departmentData = await fetch(config.mongo_endpoint + "api/v1/department/all", {
            method: "GET",
            headers: {
                "Authorization": obj.AgentToken,
            },


        });

        let resultDepartmentData = await departmentData.json();
        if (resultDepartmentData.status === 'Success') {
            const departmentlist = resultDepartmentData.data;
            setDepartmentData(departmentlist);
        }
        console.log(resultDepartmentData, 'dep')
    }

    const DepartmentList = async (company) => {
        const companyCode=company;
    
        const response = await fetch(`${config.mongo_endpoint}api/v1/department/bycompanycode?company_code=${companyCode}`, {
            method: "GET",
            headers: { 
                "Content-Type": "application/json", // assuming JSON response
                "Authorization": obj.AgentToken,
            },
        });
    
        // if (!response.ok) {
        //     throw new Error(`HTTP error! Status: ${response.status}`);
        // }
       
        let resultDepartmentData = await response.json();
        if(resultDepartmentData.status === 'Success'){
          const departmentlist = resultDepartmentData.data;
          setDepartmentData(departmentlist);
        }
         console.log(resultDepartmentData,'dep')
      }


    const handledepartment = (event) => {
        console.log(event, 'dep')
        const department = event.target.value
        setDepartment(department);
        console.log(department)
        // getEmployeeList(department);
    }

    return (
        <>
            <AgentMenu></AgentMenu>
            <div className="container-fluid pr-0" id="main-area">
                <Navbar />
                <div className="container-fluid">
                    <div className="d-flex border-bottom pb-15 pt-15">
                        <div className="me">
                            <h2 className="page-title">Update Employee</h2>
                        </div>

                    </div>
                    <section className="voting-section">
                        <div className="container">
                            <div className="row">

                                {/* <h1>Voting</h1> */}
                                <Box sx={{ width: '100%' }}>
                                    <>
                                        <div className="mto stepper-content">

                                            <form onSubmit={sailorInfoHandlerSubmit(onSubmit)}>
                                                <Box mb={3.5}>
                                                    <Grid container spacing={3} marginTop={1}>
                                                        

                                                        <Grid item sm={3} xs={12}>
                                                            <FormControl fullWidth>
                                                                <InputLabel id="demo-simple-select-label">Company *</InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    size="small"
                                                                    name="company_name"
                                                                    value={company}
                                                                                                                                     label="Company Name *"
                                                                    {...sailorInfoHandler("company_name")}
                                                                    error={!!sailorInfoErrors['company_name']}
                                                                    helperText={sailorInfoErrors['company_name'] ? sailorInfoErrors['company_name'].message : ''}
                                                                    onChange={handleCompanyChange}
                                                                    readOnly={readonlyCompany}
  
  
                                                                >
                                                                    {companyData.map((list) => (
                                                                        <MenuItem  value={list.company_code}>
                                                                            {list.company_name}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                                {sailorInfoErrors.company_name && <FormHelperText error={!!sailorInfoErrors['company_name']}>{sailorInfoErrors.company_name.message}</FormHelperText>}

                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item sm={3} xs={12}>
                                                            <FormControl fullWidth>
                                                                <InputLabel id="demo-simple-select-label">Department  *</InputLabel>

                                                                <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    size="small"
                                                                    name="department_code"
                                                                    value={department}
                                                                    label=" Name *"
                                                                    {...sailorInfoHandler("department_code")}
                                                                    error={!!sailorInfoErrors['department_code']}
                                                                    helperText={sailorInfoErrors['department_code'] ? sailorInfoErrors['department_code'].message : ''}

                                                                    onChange={handledepartment}
                                                                >
                                                                    {departmentData.map((res) => (
                                                                        <MenuItem value={res.department_code}>{res.department_name}</MenuItem>
                                                                    ))}

                                                                </Select>
                                                                {sailorInfoErrors.department_code && <FormHelperText error={!!sailorInfoErrors['department_code']}>{sailorInfoErrors.department_code.message}</FormHelperText>}
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item sm={3} xs={12}>
                                                            <TextField
                                                                fullWidth
                                                                size="small"
                                                                type="text"
                                                                name="name"
                                                                label="Employee Name *"
                                                                {...sailorInfoHandler("name")}
                                                                id="outlined-error"
                                                                error={!!sailorInfoErrors['name']}
                                                                helperText={sailorInfoErrors['name'] ? sailorInfoErrors['name'].message : ''}
                                                            />
                                                        </Grid>

                                                        <Grid item sm={3} xs={12}>
                                                            <TextField
                                                                fullWidth
                                                                size="small"
                                                                type="number"
                                                                name="phone"
                                                                label="Phone *"
                                                                {...sailorInfoHandler("phone")}
                                                                id="outlined-error"
                                                                error={!!sailorInfoErrors['phone']}
                                                                helperText={sailorInfoErrors['phone'] ? sailorInfoErrors['phone'].message : ''}
                                                            />
                                                        </Grid>

                                                        <Grid item sm={3} xs={12}>
                                                            <TextField
                                                                fullWidth
                                                                size="small"
                                                                type="email"
                                                                name="email"
                                                                label="Email *"
                                                                {...sailorInfoHandler("email")}
                                                                id="outlined-error"
                                                                error={!!sailorInfoErrors['email']}
                                                                helperText={sailorInfoErrors['email'] ? sailorInfoErrors['email'].message : ''}
                                                            />
                                                        </Grid>

                                                        <Grid item sm={3} xs={12}>
                                                            <TextField
                                                                fullWidth
                                                                size="small"
                                                                type="text"
                                                                name="password"
                                                                label="Password "
                                                                {...sailorInfoHandler("password")}
                                                                id="outlined-error"
                                                                error={!!sailorInfoErrors['password']}
                                                                helperText={sailorInfoErrors['password'] ? sailorInfoErrors['password'].message : ''}
                                                            />
                                                        </Grid>
                                                    </Grid>



                                                </Box>

                                                <div sx={{ display: 'flex', flexDirection: 'row', padding: '10px 10px', background: "#f5f5f5" }}>

                                                    <Button type="submit" variant="contained" color="success" sx={{ marginTop: "20px", marginLeft: "18px" }}>
                                                        SUBMIT
                                                    </Button>
                                                </div>
                                            </form>


                                        </div>


                                    </>

                                </Box>
                            </div>
                        </div>
                    </section>

                </div>
            </div>
        </>
    );
}

export default UpdateUser;