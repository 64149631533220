import React, { useState, useEffect, useContext } from "react";
import Topnav from "../../common/topNav";
import { Link } from "react-router-dom";
// import Footer from "../../common/footer";
// import Banner from "../../components/banner";

import Ham from "../../assets/images/sailor/ham.svg";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AgentMenu from "../../components/AgentMenu";
import NoteContext from "../../context/NoteContext";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Button from '@mui/material/Button';
import { config } from "../../util/apiconfig";
import axios from 'axios';
import Navbar from "../Navbar";
import swal from 'sweetalert';
import Moment from 'react-moment';

function ManageAgent() {
    const obj = useContext(NoteContext)
    const [data, setData] = useState([]);
    async function getApprovedOnboardingList() {
        // let requestData = {
        //     "Application_Status": "Approved"
        // };


        let data = await fetch(config.mongo_endpoint + "api/v1/staff/viewall", {
            method: "GET",
            headers: {
                "Content-type": "application/json",
                "Authorization" : obj.AgentToken,
                "x-access-token": `${localStorage.getItem('AgentToken')}`
                // "x-access-token" : "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyaWQiOiI2NGQxZGFhMWM4ZTE4MTEzODQ1YWY3N2QiLCJpYXQiOjE2OTE4NTIxNjV9.8et-s1ZMMB6NGuVQtiUCVgcopakWOSZmqN1Em3jqdAo"
            }
        });
        let res = await data.json();
        //console.log(res)
        if (res.status === "Success") {
            // console.log("manage_agent", res.data)

            const logintype = obj.type;
            const logincompany = localStorage.getItem('company_code');
            if (logintype === 0) {
                const filter = res.data.filter(res => res.Type !== 0);
                setData(filter);

            } else {
                const filter = res.data.filter(res => res.CompanyCode === logincompany && res.Type !== 0);
                setData(filter);
            }


        } else {
            alert(res.message);

        }

    }
    useEffect(() => {
        getApprovedOnboardingList()

    }, [])


    const deleteAgent = async (staff_id) =>{
       // console.log(staff_id)
        const datas = {
                    staffId : staff_id,              
                }
               // console.log(staff_id)
          swal({
              title: "Are you sure?",
              text: "Once deleted, you will not be able to recover this  file!",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            })
            .then((willDelete) => {
              if (willDelete) {
                   axios.post(config.mongo_endpoint+"api/v1/staff/delete", datas, {
                      headers: {"Content-type": "multipart/form-data", "x-access-token":obj.AgentToken,'Authorization':obj.AgentToken} ,                   
                  })
                 
                  swal("Staff  Deleted....!", {
                      icon: "success",
                    });
                    getApprovedOnboardingList(); 
                    
              } else {
                  swal("Cancelled");
              }    
              getApprovedOnboardingList();           
            }); 
                    
      } 

    // const editAgent =(_id)=>{
    //     console.log(_id)
    // }

    async function editAgent(row) {
       // console.log("ROW**", row)
        obj.setUpdate_company(row)
        obj.setUpdatingCompanyFlag(1);
    }



    return (
        <>

            <AgentMenu></AgentMenu>

            <div className="container-fluid pr-0" id="main-area">

                <Navbar />

                <div className="container-fluid">
                    <div className="d-flex border-bottom pb-15 pt-15">
                        <div className="me-auto ">
                            <h2 className="page-title">Manage Staff </h2>
                        </div>
                        <div>
                            <Link to="/AddAgents" className="nav-link" >
                                <Button variant="contained" color="success">
                                    Add
                                </Button></Link></div>
                    </div>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table" >
                            <TableHead>
                                <TableRow>

                                    <TableCell align="center" style={{ background: '#ddd' }}># </TableCell>
                                    <TableCell align="center" style={{ background: '#ddd' }}>Email </TableCell>
                                    <TableCell align="center" style={{ background: '#ddd' }}>First Name</TableCell>
                                    <TableCell align="center" style={{ background: '#ddd' }}>Phone No</TableCell>
                                    <TableCell align="center" style={{ background: '#ddd' }}>Company Name</TableCell>
                                    <TableCell align="center" style={{ background: '#ddd' }}>Role</TableCell>
                                    <TableCell align="center" style={{ background: '#ddd' }}>Action</TableCell>



                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.map((row, index) => (
                                    <TableRow
                                        key={index}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell align="center" style={{ padding: '5px 15px' }}> {index + 1}</TableCell>
                                        <TableCell align="center" style={{ padding: '5px 15px' }}> {row.Email}</TableCell>
                                        <TableCell align="center" style={{ padding: '5px 15px' }}> {row.First_Name}</TableCell>
                                        <TableCell align="center" style={{ padding: '5px 15px' }}>{row.Phone} </TableCell>
                                        <TableCell align="center" style={{ padding: '5px 15px' }}> {row.CompanyName}</TableCell>
                                        <TableCell align="center" style={{ padding: '5px 15px' }}> {row.roleName} </TableCell>
                                        <TableCell align="center" style={{ padding: '5px 15px' }}> 
                                            
                                            {/* <EditIcon onClick={() => {editAgent(row._id)}} /> */}
                                            <Link to="/UpdateAgents" onClick={() => { editAgent(row) }}><EditIcon /></Link>
                                            {row.Type !== 1 &&
                                             <DeleteIcon onClick={() => { deleteAgent(row.staffId) }} />
                                            }

                                        </TableCell>



                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>






        </>
    );
}

export default ManageAgent;