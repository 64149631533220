import swal from 'sweetalert';

const handleApiError = (error, navigate) => {
    console.error('Error fetching data:', error);

    if (error.response && error.response.status === 401) {
        swal("Unauthorized", "Please login again", "error").then(() => {
            localStorage.clear();
            navigate("/");
        });
    } else {
        //swal("Error", "Failed to fetch  data", "error");
        console.error('Error fetching data','error');
    }
};

export default handleApiError;
