import React, { useState, useEffect } from "react";
import Topnav from "../../common/topNav";
import { Link } from "react-router-dom";
// import Footer from "../../common/footer";
// import Banner from "../../components/banner";
import ELogo from "../../assets/images/sailor/logo.svg";
import ELogo1 from "../../assets/images/sailor/sailoronboard.svg";
import Mico1 from "../../assets/images/sailor/dash-icon.svg";
import Mico2 from "../../assets/images/sailor/boar-icon.svg";

import Dash1 from "../../assets/images/sailor/dash-ico-1.svg";
import Dash2 from "../../assets/images/sailor/dash-ico-2.svg";
import Dash3 from "../../assets/images/sailor/dash-ico-3.svg";
import Dash4 from "../../assets/images/sailor/dash-ico-4.svg";
import Ham from "../../assets/images/sailor/ham.svg";
import AgentMenu from "../../components/AgentMenu";
import { useContext } from "react";
import NoteContext from "../../context/NoteContext";
import Navbar from "../Navbar";
import axios from 'axios';
import { config } from "../../util/apiconfig";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { Grid, Box } from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import ReactApexChart from 'react-apexcharts';
import swal from 'sweetalert';
import { useNavigate } from "react-router-dom";
import handleApiError from "../../util/apiUtil";




function VisitorDashboard() {
    const [bannerConfig, setbannerConfig] = useState({ bannerType: 3 });
    const obj = useContext(NoteContext);
    const [companyDatas, setCompanyDatas] = useState([]);
    // //console.log(obj)
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [frequentData, setFrequentData] = useState([]);
    const [purposeData, setPurposeData] = useState([]);
    const [companywiseData, setCompanyWiseData] = useState([]);
    const [hostData, setHostData] = useState([]);
    const [pieData, setPieData] = useState({

        series: [],
        options: {
            chart: {
                width: 380,
                type: 'donut',
            },
            labels: [],
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]
        },


    });

    const [chartData, setChartData] = useState({
        series: [],
        options: {
            chart: {
                height: 350,
                type: 'bar',
                zoom: {
                    enabled: false
                },
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                }
            },
            dataLabels: {
                enabled: false
            },
            title: {
                text: 'Company/Unit Wise Visitors (this Month)',
                align: 'left'
            },
            legend: {
                tooltipHoverFormatter: function (val, opts) {
                    return val + ' - <strong>' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + '</strong>'
                }
            },
            markers: {
                size: 0,
                hover: {
                    sizeOffset: 6
                }
            },
            xaxis: {
                categories: [],

            },
            yaxis: {
                title: {
                    text: 'Visitors',
                    style: {
                        fontSize: '14px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 600,
                        cssClass: 'y-axis-title',
                    },
                }
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return val
                    }
                }
            },
            grid: {
                borderColor: '#f1f1f1',
            }
        }
    });

    

    const getCompanyList = async () => {
        try {
            const response = await axios.get(config.mongo_endpoint + "api/v1/company/getall", {
                headers: {
                    "x-access-token": obj.AgentToken,
                    "Authorization" :obj.AgentToken
                }
            });
    
            const resultCompanyData = response.data;
    
            if (resultCompanyData.status === 'Success') {
                const companylist = resultCompanyData.data;
                setCompanyDatas(companylist); // Assuming setCompanyDatas is a function to update state or perform some action
            } else {
                console.log('Request was not successful: ' + resultCompanyData.message);
            }
        } catch (error) {
            console.log('Error fetching company data:', error);
            handleApiError(error, navigate);
        }
    }
    useEffect(() => {
        const fetchData = async () => {
            try {
                if (obj.type === 0) {
                    const resDash = await axios.get(config.mongo_endpoint + 'api/v1/dashboard/report', {
                        headers: {
                            'Authorization': obj.AgentToken
                        }
                    });
                    let datas = [];
                    const resultData = resDash.data;
                    datas.push(resultData);
                    setData(datas);
                    getCompanyList();
                    frequentvisitors(0);
                    purposeofvisit(0);
                    companywise(0);
                    hostwise(0);
                    purposeofvisitgraph(0);
                    unitWiseCompanyVisit(0);
                } else {
                    const companyName = localStorage.getItem('company_code');
                    filterdata(companyName);
                    await frequentvisitors(companyName);
                    purposeofvisit(companyName);
                    companywise(companyName);
                    hostwise(companyName);
                    purposeofvisitgraph(companyName);
                    unitWiseCompanyVisit(companyName);
                }
            } catch (error) {
                //console.error('Error fetching data:', error);
                handleApiError(error, navigate);


            }
        };

        fetchData();

    }, []);


    //company wise data



    async function filterdata(companyName) {

        try {

            const data = new FormData();
            data.append("company_code", companyName);



            const visitorData = await axios.post(config.mongo_endpoint + 'api/v1/dashboard/reportbycompany', data, {
                headers: {
                    //  "x-access-token" : obj.AgentToken, 
                    'Authorization': obj.AgentToken,
                    'Content-Type': 'multipart/form-data',
                }
            });

           

            if (visitorData.status === 200) {
                let datas = [];
                const resultData = visitorData.data;
                // //console.log(resultData)
                datas.push(resultData)
                setData(datas)



            }
        } catch (error) {

            handleApiError(error, navigate);
        }
    }



    const handleCompanyChange = (event) => {
        filterdata(event.target.value);
        frequentvisitors(event.target.value);
        purposeofvisit(event.target.value);
        companywise(event.target.value);
        hostwise(event.target.value);
        purposeofvisitgraph(event.target.value)
        unitWiseCompanyVisit(event.target.value)
//console.log()
    };

    //Frequent Visitors
    async function frequentvisitors(companyName) {
        try {
            const data = new FormData();
            data.append("company_code", companyName);


            const visitorData = await axios.post(config.mongo_endpoint + 'api/v1/dashboard/frequentvisitors', data, {
                headers: {
                    'Authorization': obj.AgentToken,
                    'Content-Type': 'multipart/form-data',
                }
            });
            console.log(visitorData)

            if (visitorData.status === 200) {
                const resultData = visitorData.data.data;
                setFrequentData(resultData);  // Assuming setFrequentData is a function to update state
                //console.log(resultData, 'fd');
            }

        } catch (error) {
            handleApiError(error, navigate);
        }
    }
    //purpose of visit
    async function purposeofvisit(companyName) {

        try {

            const data = new FormData();
            data.append("company_code", companyName);

            const purposeData = await axios.post(config.mongo_endpoint + 'api/v1/dashboard/purposeofvisit', data, {
                headers: {
                    //  "x-access-token" : obj.AgentToken,
                    'Authorization': obj.AgentToken,
                    'Content-Type': 'multipart/form-data',
                }
            });



            if (purposeData.status === 200) {

                const resultData = purposeData.data.data;

                setPurposeData(resultData)
                //console.log(resultData, 'resultData')


                //console.log(pieData, 'pie')

            }
        } catch (error) {

            handleApiError(error, navigate);
        }
    }

    //companywise data
    async function companywise(companyName) {

        try {

            const data = new FormData();
            data.append("company_code", companyName);



            const companyvisitorData = await axios.post(config.mongo_endpoint + 'api/v1/dashboard/companywisevisitors', data, {
                headers: {
                    //  "x-access-token" : obj.AgentToken,
                    'Authorization': obj.AgentToken,
                    'Content-Type': 'multipart/form-data',
                }
            });

console.log(companyvisitorData,'companyvisitorData')

            if (companyvisitorData.status === 200) {

                const companyvisitorDatas = companyvisitorData.data.data;
console.log(companyvisitorDatas,'companyvisitorDatas')
                setCompanyWiseData(companyvisitorDatas)
                //console.log(companyvisitorDatas, 'cw')

            }
        } catch (error) {

            handleApiError(error, navigate);
        }
    }
    async function hostwise(companyName) {

        try {

            const data = new FormData();
            data.append("company_code", companyName);



            const hostData = await axios.post(config.mongo_endpoint + 'api/v1/dashboard/hostwisevisitors', data, {
                headers: {
                    //  "x-access-token" : obj.AgentToken,
                    'Authorization': obj.AgentToken,
                    'Content-Type': 'multipart/form-data',
                }
            });



            if (hostData.status === 200) {

                const hostDatas = hostData.data.data;

                setHostData(hostDatas)
                //console.log(hostDatas, 'hs')

            }
        } catch (error) {

            handleApiError(error, navigate);
        }
    }
    //purpose of visit graph
    async function purposeofvisitgraph(companyName) {

        try {

            const data = new FormData();
            data.append("company_code", companyName);

            const purposeData = await axios.post(config.mongo_endpoint + 'api/v1/dashboard/purposeofvisitgraph', data, {
                headers: {
                    //  "x-access-token" : obj.AgentToken, 
                    'Authorization': obj.AgentToken,
                    'Content-Type': 'multipart/form-data',
                }
            });


            if (purposeData.status === 200) {

                const resultData = purposeData.data.data;


                setPieData(prevState => ({
                    ...prevState,
                    series: resultData.series,
                    options: {
                        ...prevState.options,
                        labels: resultData.label

                    }
                }));

                //console.log(pieData, 'pie')

            }
        } catch (error) {

            handleApiError(error, navigate);
        }
    }


    async function unitWiseCompanyVisit(companyName) {

        try {

            const data = new FormData();
            data.append("company_code", companyName);
            const now = new Date();
            const year = now.getFullYear();
            const month = now.getMonth();

            // Get the first and last day of the current month
            const firstDay = new Date(year, month, 1);
            const lastDay = new Date(year, month + 1, 0);

            // Generate an array of all days in the current month
            const dayss = [];
            for (let day = firstDay; day <= lastDay; day.setDate(day.getDate() + 1)) {
                dayss.push(
                    new Date(day).toLocaleDateString('en-GB', {
                        day: '2-digit',
                        month: 'short',
                    }).replace(/ /g, '-') // Format to DD-MMM and replace spaces with hyphens
                );
            }


            const purposeData = await axios.post(config.mongo_endpoint + 'api/v1/dashboard/unitwiseVisitors', data, {
                headers: {
                    //  "x-access-token" : obj.AgentToken, 
                    'Authorization': obj.AgentToken,
                    'Content-Type': 'multipart/form-data',
                }
            });

            if (purposeData.status === 200) {

                const resultData = purposeData.data.chartData;



                setChartData(prevState => ({
                    ...prevState,
                    series: resultData,
                    options: {
                        ...prevState.options,
                        xaxis: {
                            ...prevState.options.xaxis,
                            categories: dayss  // Update categories with newLabelData
                        }
                    }
                }));
                //console.log(resultData, 'chart')

            }
        } catch (error) {

            handleApiError(error, navigate);
        }
    }

    // function unitWiseCompanyVisit(company_code) {

    //     const data = {
    //         company_code: company_code
    //     }

    //     // if(company_code !== 0){
    //         const now = new Date();
    //         const year = now.getFullYear();
    //         const month = now.getMonth();

    //         // Get the first and last day of the current month
    //         const firstDay = new Date(year, month, 1);
    //         const lastDay = new Date(year, month + 1, 0);

    //         // Generate an array of all days in the current month
    //         const dayss = [];
    //         for (let day = firstDay; day <= lastDay; day.setDate(day.getDate() + 1)) {
    //             dayss.push(
    //                 new Date(day).toLocaleDateString('en-GB', {
    //                   day: '2-digit',
    //                   month: 'short',
    //                 }).replace(/ /g, '-') // Format to DD-MMM and replace spaces with hyphens
    //               );
    //         }

    //         // //console.log(days);
    //     // }
    //     axios.post(config.mongo_endpoint + 'api/v1/dashboard/unitwiseVisitors', data).then((resDash) => {
    //         const newLabelData = [];
    //         const newSeriesData = [];
    //         const resultData = resDash.data;
    //         if(resultData.chartData.length > 0){
    //             const datas = resultData.chartData;
    //         //  //console
    //         //console.log(datas,'chart');
    //         setChartData(prevState => ({
    //             ...prevState,
    //             series: datas,
    //             options: {
    //               ...prevState.options,
    //               xaxis: {
    //                 ...prevState.options.xaxis,
    //                 categories: dayss  // Update categories with newLabelData
    //               }
    //             }
    //           }));
    //             // //console.log(labelData);

    //         }            

    //     })
    // }
    return (
        <>

            <AgentMenu></AgentMenu>

            <div class="container-fluid pr-0" id="main-area">
                <Navbar />
                <div className="container-fluid">
                    <div class="d-flex border-bottom pb-15 pt-15">
                        <div class="me-auto ">
                            <h2 class="page-title" style={{ fontSize: "20px" }}>Visitor Dashboard​</h2>

                        </div>

                    </div>
                    {obj.type === 0 &&

                        <Grid container spacing={2} justifyContent="flex-end" marginBottom={2}> {/* Adding marginBottom to create space */}
                            <Grid item xs={3}>
                                <Box>
                                    <FormControl fullWidth style={{ marginTop: '15px' }}>
                                        <InputLabel id="demo-simple-select-label">Company</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            size="small"
                                            name="company_name"
                                            label="Company"
                                            onChange={handleCompanyChange}
                                        >
                                            <MenuItem value={0}>Select Company</MenuItem>
                                            {companyDatas.map((res) => (
                                                <MenuItem key={res.company_code} value={res.company_code}>
                                                    {res.company_name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>
                        </Grid>


                    }

                    {/* {data.map((resReu)=>{
                        //console.log(resReu)
                })} */}
                    {data.map((resReu, index) => (
                        <div class="row g-6 mb-6">
                            <div class="col-xl-3 col-sm-6 col-12">
                                <div class="card  border-0">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col">
                                                <span class="font-semibold text-sm d-block mb-2">Total Month Visitors</span>
                                                {/* <span class="h3 font-bold mb-0">{resReu.totalVisitor}</span> */}
                                                <span class="h3 font-bold mb-0">{resReu.totalVisitorThisMonth}</span>
                                            </div>
                                            <div class="col-auto">
                                                <div class="icon icon-shape  text-white text-lg rounded-circle">
                                                    <img src={Dash1} alt="" />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-sm-6 col-12">
                                <div class="card  border-0">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col">
                                                <span class="font-semibold text-sm d-block mb-2">Today Visitors</span>
                                                <span class="h3 font-bold mb-0">{resReu.totalVisitorToday}</span>
                                            </div>
                                            <div class="col-auto">
                                                <div class="icon icon-shape  text-white text-lg rounded-circle">
                                                    <img src={Dash2} alt="" />

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div class="col-xl-3 col-sm-6 col-12">
                                <div class="card  border-0">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col">
                                                <span class="font-semibold text-sm d-block mb-2">CheckIn Visitors</span>
                                                <span class="h3 font-bold mb-0">{resReu.totalCheckInToday}</span>
                                            </div>
                                            <div class="col-auto">
                                                <div class="icon icon-shape  text-white text-lg rounded-circle">
                                                    <img src={Dash3} alt="" />

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>


                            <div class="col-xl-3 col-sm-6 col-12">
                                <div class="card  border-0">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col">
                                                <span class="font-semibold text-sm d-block mb-2">CheckOut Visitors</span>
                                                <span class="h3 font-bold mb-0">{resReu.totalCheckOutToday}</span>
                                            </div>
                                            <div class="col-auto">
                                                <div class="icon icon-shape  text-white text-lg rounded-circle">
                                                    <img src={Dash3} alt="" />

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}

                    <div class="row g-6 mb-6">
                        <div class="col-xl-6 col-sm-6 col-12">
                            <div class="d-flex border-bottom pb-15 pt-15">
                                <div class="me-auto ">
                                    <h6 class="page-title" style={{ fontSize: "20px" }}>Company  Wise Visitors (This Month)​</h6>

                                </div>

                            </div>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 200 }} aria-label="simple table" >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left" style={{ background: '#ddd' }}># </TableCell>
                                            <TableCell align="center" style={{ background: '#ddd' }}>Company  </TableCell>
                                            <TableCell align="center" style={{ background: '#ddd' }}>Total Visits</TableCell>


                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {companywiseData.length === 0 ? (
                                        <TableRow>
                                            <TableCell colSpan={3} align="center">No data found</TableCell>
                                        </TableRow>
                                    ) : (
                                        companywiseData.map((com, index) => (
                                            <TableRow>
                                                <TableCell align="left" style={{ padding: '5px 15px' }}>{index + 1}</TableCell>
                                                <TableCell align="center" style={{ padding: '5px 15px' }}>{com.company_info}</TableCell>
                                                <TableCell align="center" style={{ padding: '5px 15px' }}>{com.visit_count}</TableCell>

                                            </TableRow>
                                        ))
                                    )}

                                        

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                        <div class="col-xl-6 col-sm-6 col-12">
                            <div class="d-flex border-bottom pb-15 pt-15">
                                <div class="me-auto ">
                                    <h2 class="page-title" style={{ fontSize: "20px" }} >Frequent Visitors (This Month)​</h2>

                                </div>

                            </div>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 200 }} aria-label="simple table" >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left" style={{ background: '#ddd' }}># </TableCell>
                                            <TableCell align="center" style={{ background: '#ddd' }}>Visitor Name </TableCell>
                                            <TableCell align="center" style={{ background: '#ddd' }}>Company / Unit  </TableCell>
                                            <TableCell align="center" style={{ background: '#ddd' }}>Total Visits</TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {frequentData.length === 0 ? (
                                        <TableRow>
                                            <TableCell colSpan={4} align="center">No data found</TableCell>
                                        </TableRow>
                                    ) : (
                                        
                                        frequentData.map((row, index) => (
                                            <TableRow>
                                                <TableCell align="left" style={{ padding: '5px 15px' }}>{index + 1}</TableCell>
                                                <TableCell align="center" style={{ padding: '5px 15px' }}>{row.visitor_name}</TableCell>
                                                <TableCell align="center" style={{ padding: '5px 15px' }}>{row.company_info}</TableCell>
                                                <TableCell align="center" style={{ padding: '5px 15px' }}>{row.visit_count}</TableCell>

                                            </TableRow>
                                        ))
                                    )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                        <div class="col-xl-6 col-sm-6 col-12">
                            <div class="d-flex border-bottom pb-15 pt-15">
                                <div class="me-auto ">
                                    <h2 class="page-title" style={{ fontSize: "20px" }} >Purpose of Visits (This Month)​</h2>

                                </div>

                            </div>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 200 }} aria-label="simple table" >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left" style={{ background: '#ddd' }}># </TableCell>
                                            <TableCell align="center" style={{ background: '#ddd' }}>Company </TableCell>

                                            <TableCell align="center" style={{ background: '#ddd' }}>Purpose of Visit </TableCell>
                                            <TableCell align="center" style={{ background: '#ddd' }}>Total Visits</TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {purposeData.length === 0 ? (
                                        <TableRow>
                                            <TableCell colSpan={3} align="center">No data found</TableCell>
                                        </TableRow>
                                    ) : (
                                        purposeData.map((purpose, index) => (
                                            <TableRow key={index}>
                                                <TableCell align="left" style={{ padding: '5px 15px' }}>{index + 1}</TableCell>
                                                <TableCell align="center" style={{ padding: '5px 15px' }}>{purpose.company_info}</TableCell>
                                                <TableCell align="center" style={{ padding: '5px 15px' }}>
                                                    {/* {purpose._id.visitor_type} */}
                                                    {purpose._id && purpose._id.visitor_type ? purpose._id.visitor_type : purpose._id}


                                                </TableCell>
                                                <TableCell align="center" style={{ padding: '5px 15px' }}>{purpose.visit_count}</TableCell>
                                            </TableRow>
                                        ))
                                    )}

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                        {/* <div class="col-xl-6 col-sm-6 col-12">
                            <div class="d-flex border-bottom pb-15 pt-15">
                                <div class="me-auto ">
                                    <h2 class="page-title" style={{ fontSize: "20px" }} >Host Wise Visitors (This Month)​</h2>

                                </div>

                            </div>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 200 }} aria-label="simple table" >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left" style={{ background: '#ddd' }}># </TableCell>
                                            <TableCell align="center" style={{ background: '#ddd' }}>Host Name </TableCell>
                                            <TableCell align="center" style={{ background: '#ddd' }}>Company / Unit  </TableCell>
                                            <TableCell align="center" style={{ background: '#ddd' }}>Total Visits</TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {hostData.map((host, index) => (
                                            <TableRow>
                                                <TableCell align="left" style={{ padding: '5px 15px' }}>{index + 1}</TableCell>
                                                <TableCell align="center" style={{ padding: '5px 15px' }}>{host._id.meeting_person}</TableCell>
                                                <TableCell align="center" style={{ padding: '5px 15px' }}>{host.company_info}</TableCell>
                                                <TableCell align="center" style={{ padding: '5px 15px' }}>{host.visit_count}</TableCell>

                                            </TableRow>

                                        ))}

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div> */}
                        <div class="col-xl-4 col-sm-4 col-4"></div>
                        <div class="col-xl-8 col-sm-4 col-12">
                            <div class="d-flex border-bottom pb-15 pt-15">
                                <div class="me-auto ">
                                    <h2 class="page-title" style={{ fontSize: "20px" }} >Purpose Of Visits (This Month)​</h2>

                                </div>

                            </div>
                            <ReactApexChart options={pieData.options} series={pieData.series} type="pie" width={400} />
                        </div>

                        <div class="col-xl-12 col-sm-12 col-12">

                            <ReactApexChart options={chartData.options} series={chartData.series} type="bar" height={380} />
                        </div>
                    </div>
                </div>
            </div>





        </>
    );
}

export default VisitorDashboard;