import React, { useState, useEffect, useContext } from "react";
import { Box } from "@mui/material";
import AgentMenu from "../../components/AgentMenu";
import NoteContext from "../../context/NoteContext";
import Button from '@mui/material/Button';
import { FormHelperText, Grid, TextField } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useForm } from "react-hook-form";
import 'react-phone-input-2/lib/material.css'
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import Navbar from "../Navbar";
import axios from 'axios';
import { config } from "../../util/apiconfig";
import swal from 'sweetalert';
import handleApiError from "../../util/apiUtil";


function UpdateRoles() {
    // const { AgentId } = match.params;
    // const isAddMode = !AgentId;
    // =================== Voter Info Tab-1 Validation  ================

    const sailorInformation = yup.object().shape({
        // company_code: yup.string().required("Company Code Required"),
        role_name: yup.string().required("Role Name Required"),

    })

    // =================== Voter Info Tab-1 Validation  ================
    // =================== Voter Info Yup Resolver Starts  ================
    const { register: sailorInfoHandler,
        handleSubmit: sailorInfoHandlerSubmit,
        reset: voterInfoReset,
        setValue,
        formState: { errors: sailorInfoErrors } } = useForm({
            resolver: yupResolver(sailorInformation),
            mode: 'OnSubmit',
        });

    //   console.log(sailorInfoErrors)

    const [activeStep, setActiveStep] = React.useState(0);
    const [Agree, setAgree] = useState('')
    const [loader, setLoader] = useState(false)
    const [user, setUser] = useState({});


    const [updatedata, setUpdatedata] = useState('');
    const [activestatus, setActivestatus] = useState('');





    const [companyData, setcompanyData] = useState([]);


    const getCompanyList = async () => {
        try {
            const response = await axios.get(config.mongo_endpoint + "api/v1/company/getall", {
                headers: {
                    "Authorization": obj.AgentToken
                }
            });

            if (response.status === 200) {
                const resultCompanyData = response.data;

                if (resultCompanyData.status === 'Success') {
                    const companylist = resultCompanyData.data;
                    setcompanyData(companylist);
                } else {
                    throw new Error('Request was not successful: ' + resultCompanyData.message);
                }
            } else {
                throw new Error('Failed to fetch company data: ' + response.status);
            }
        } catch (error) {
            console.error('Error fetching company data:', error);
            // handleApiError(error, navigate);
        }
    }

    useEffect(() => {
        getCompanyList();
    }, [])




    const obj = useContext(NoteContext)
    const [data, setData] = useState([]);
    const [update_company, setUpdate_company] = useState(obj.update_company)
    const [company, setCompany] = useState('');

    //console.log("UpdateRoles=>", update_company)


    const navigate = useNavigate();
    const LandingScreen = () => {
        navigate("/Roles");
    }

    useEffect(() => {
        setValue('role_name', update_company.name);

        // setValue('company_name', update_company.company_code);
        // setCompany(update_company.company_code);

        setValue('active_status', update_company.active_status);
        setActivestatus(update_company.active_status);

    }, [])

    //console.log(company)

    const handleCompanyChange = (event) => {
        //console.log(event.target.value, 'afterchange')
        setCompany(event.target.value);
    };

    const onSubmit = async (data) => {
        try {
            const companyInformation = {
                role_id: update_company.roleId,
                role_name: data["role_name"],
                //company_code: data["company_name"],
                userId: localStorage.getItem('userId'),

            }
            //console.log(companyInformation)

            await axios.post(config.mongo_endpoint + 'api/v1/roles/updateRole', companyInformation, { headers: { 'Content-Type': 'application/json', 'Authorization': obj.AgentToken } })
                .then(result => {
                    //console.log(result);
                    if (result.status == 200 && result.data.status == 'Success') {
                        // toast.success("Department Updated Successfully.", {
                        //     position: toast.POSITION.TOP_CENTER,
                        //     theme: "colored",
                        // });
                        swal("Role updated Successfully....!", {
                            icon: "success",
                        });
                        LandingScreen();
                    } else {
                        // toast.success("Duplicate Data Exist", {
                        //     position: toast.POSITION.TOP_CENTER,
                        //     theme: "colored",
                        // });
                        swal("Role update Failed ....!", {
                            icon: "warning",
                            text: result.data.message
                        });
                    }
                })





        }
        catch (error) {
            console.error(error);
            handleApiError(error, navigate)
            setLoader(false);


        }

    }



    return (
        <>
            <AgentMenu></AgentMenu>
            <div className="container-fluid pr-0" id="main-area">
                <Navbar />
                <div className="container-fluid">
                    <div className="d-flex border-bottom pb-15 pt-15">
                        <div className="me">
                            <h2 className="page-title">Update Role</h2>
                        </div>

                    </div>
                    <section className="voting-section">
                        <div className="container">
                            <div className="row">

                                {/* <h1>Voting</h1> */}
                                <Box sx={{ width: '100%' }}>
                                    <>
                                        <div className="mto stepper-content">

                                            <form onSubmit={sailorInfoHandlerSubmit(onSubmit)}>
                                                <Box mb={3.5}>
                                                    <Grid container spacing={3} marginTop={1}>
                                                        <Grid item sm={3} xs={12}>
                                                            <input type="hidden" name="active_status" value="hiddenValue" {...sailorInfoHandler("active_status")} />
                                                            <TextField
                                                                fullWidth
                                                                size="small"
                                                                type="text"
                                                                name="role_name"
                                                                label="Role Name *"
                                                                {...sailorInfoHandler("role_name")}
                                                                id="outlined-error"
                                                                error={!!sailorInfoErrors['role_name']}
                                                                helperText={sailorInfoErrors['role_name'] ? sailorInfoErrors['role_name'].message : ''}
                                                            />
                                                        </Grid>

                                                        {/* <Grid item sm={3} xs={12}>
                                                            <FormControl fullWidth>
                                                                <InputLabel id="demo-simple-select-label">Company *</InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    size="small"
                                                                    name="company_name"
                                                                    value={company}
                                                                    onChange={handleCompanyChange}
                                                                    label="Company Name *"
                                      {...sailorInfoHandler("company_name")}
                                      error={!!sailorInfoErrors['company_name']}
                                      helperText={sailorInfoErrors['company_name'] ? sailorInfoErrors['company_name'].message : ''}
                                     
                                                                >
                                                                    {companyData.map((list) => (
                                                                        <MenuItem key={list.company_code} value={list.company_code}>
                                                                            {list.company_name}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                                {sailorInfoErrors.company_name && <FormHelperText error={!!sailorInfoErrors['company_name']}>{sailorInfoErrors.company_name.message}</FormHelperText>}
                                                                  
                                                            </FormControl>
                                                        </Grid> */}
                                                    </Grid>


                                                </Box>

                                                <div sx={{ display: 'flex', flexDirection: 'row', padding: '10px 10px', background: "#f5f5f5" }}>

                                                    <Button type="submit" variant="contained" color="success" sx={{ marginTop: "20px", marginLeft: "18px" }}>
                                                        SUBMIT
                                                    </Button>
                                                </div>
                                            </form>


                                        </div>


                                    </>

                                </Box>
                            </div>
                        </div>
                    </section>

                </div>
            </div>
        </>
    );
}

export default UpdateRoles;