import React, { useState, useEffect, useContext, useRef } from "react";
import Topnav from "../../common/topNav";
import { Link } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import BadgeIcon from '@mui/icons-material/Badge';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AgentMenu from "../../components/AgentMenu";
import NoteContext from "../../context/NoteContext";
import { Grid, Box } from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import DoneIcon from '@mui/icons-material/Done';
import { Button, Modal, TextField, Typography, Avatar, Stack } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import picture from '../../assets/images/avatar.jpg'
import newavatar from '../../assets/images/avatar-icon.png'
import { config } from "../../util/apiconfig";
import axios from 'axios';
import Navbar from "../Navbar";
import swal from 'sweetalert';
import Moment from 'react-moment';
import RemoveDoneIcon from '@mui/icons-material/RemoveDone';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';

function ManageVisitorCheckout() {
  const obj = useContext(NoteContext)
  const [data, setData] = useState([]);
  const TodayDate = new Date();
  const [visitor_date, setVisitordate] = useState(TodayDate);
  const [companyData, setCompanyData] = useState([]);
  const [readonlyCompany, setReadonlyCompany] = useState(false); // State for readonly flag
  const printRef = useRef();

  const handlePrint = () => {
    const printContent = printRef.current;
    const printWindow = window.open('', '', 'width=800,height=600');
    printWindow.document.write('<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd"><html xmlns="http://www.w3.org/1999/xhtml"><head><link href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap" rel="stylesheet"><title>Print</title>');
    // Add any styles needed for the print content
    printWindow.document.write(' <style>tr {line-height: 18px !important;}</style><style>@media print { body { -webkit-print-color-adjust: exact; border: "1px solid black"} }</style>');
    printWindow.document.write('</head><body >');
    printWindow.document.write(printContent.innerHTML);
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
  };


  const [visitorPass, setVisitorPasss] = useState({});
  const [open, setOpen] = useState(false);
  const [checkoutOpen, setCheckoutOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [visitorId, setVisitorId] = useState(null);

  const handleOpen = (resp) => {
    setOpen(true);
    setVisitorPasss(resp);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCheckoutOpen = (row) => {
    setCheckoutOpen(true);
    setVisitorId(row.visitorId);
  };

  const handleCheckoutClose = () => {
    setCheckoutOpen(false);
    setSelectedDate(null);
    setVisitorId(null);
  };

  const handleDateChange = (date) => {
    console.log(date,'e')
    setSelectedDate(date);
  };

  async function  handleCheckoutSubmit ()  {

    const date = new Date(selectedDate)
    console.log("Submitting checkout for visitor ID:", visitorId, "Date:", date);
    try {
      
      const visitorInformation = {
        "visitor_id": visitorId,
        'date' : date
      }
      await axios.post(config.mongo_endpoint + 'api/v1/visitor/checkOut', visitorInformation, {
        headers: {
          //  "x-access-token" : obj.AgentToken,  
          'Content-Type': 'application/json',
        }
      })
        .then(result => {
          if (result.status === 200 && result.data.status === "Success") {


            swal("Checkout Success....!", {
              icon: "success",
            });
          } else {

            swal("Checkout failed....!", {
              icon: "warning",
            });
          }
        })
      const filterDate = new Date(visitor_date);
      //getApprovedOnboardingList(filterDate)

    }
    catch (error) {
      console.error(error);
      // if (error)
      //   setLoader(false);
      if (error) {
        alert("Something went wrong. Please try again Later....")
      }
      console.log(error.code);

    }
    // You can add your actual form submission logic here
handleCheckoutClose();
  };





  useEffect(() => {
    const filterDate = new Date();
    // getApprovedOnboardingList(filterDate)
    getCompanyList();
    //getDepartmentList();   
    const logintype = obj.type;
    const logincompany = localStorage.getItem('company_code');
    console.log(logintype,'logintype')
    if (logintype !== 0) {
      setCompanyName(logincompany);

      DepartmentList(logincompany);
      setReadonlyCompany(true);
    }

    const start_date = new Date(visitingDate);
    const end_date = new Date(visitingEndDate);

    setVisitingDate(start_date);
    setVisitingEndDate((end_date));

  }, [])


  async function handleDate(event) {
    // console.log("ROW**", event)
    setVisitordate(event);
    const filterDate = new Date(event);
    //getApprovedOnboardingList(filterDate)

  }

  // async function updateCheckout(data) {
  //   try {
  //     console.log(data, 'check');
  //     // let visitor_request = new FormData();
  //     // visitor_request.append("Email" , data["email_Id"]);
  //     // visitor_request.append("Email" , data["email_Id"]);
  //     // visitor_request.append("Email" , data["email_Id"]);

  //     const visitorInformation = {
  //       "visitor_id": data.visitorId,
  //     }
  //     await axios.post(config.mongo_endpoint + 'api/v1/visitor/checkOut', visitorInformation, {
  //       headers: {
  //         //  "x-access-token" : obj.AgentToken,  
  //         'Content-Type': 'application/json',
  //       }
  //     })
  //       .then(result => {
  //         if (result.status === 200 && result.data.status === "Success") {


  //           swal("Checkout Success....!", {
  //             icon: "success",
  //           });
  //         } else {

  //           swal("Checkout failed....!", {
  //             icon: "warning",
  //           });
  //         }
  //       })
  //     const filterDate = new Date(visitor_date);
  //     //getApprovedOnboardingList(filterDate)

  //   }
  //   catch (error) {
  //     console.error(error);
  //     // if (error)
  //     //   setLoader(false);
  //     if (error) {
  //       alert("Something went wrong. Please try again Later....")
  //     }
  //     console.log(error.code);

  //   }

  // }

  const [companyValue, setCompanyValue] = useState('');


  async function updateAccept(data) {
    try {
      console.log(data, 'accept');
      // let visitor_request = new FormData();
      // visitor_request.append("Email" , data["email_Id"]);
      // visitor_request.append("Email" , data["email_Id"]);
      // visitor_request.append("Email" , data["email_Id"]);

      const visitorInformation = {
        "visitor_id": data.visitorId,
        "approval_flag": 1
      }
      await axios.post(config.mongo_endpoint + 'api/v1/employee/approval', visitorInformation, {
        headers: {
          //  "x-access-token" : obj.AgentToken,  
          'Content-Type': 'multipart/form-data',
        }
      })
        .then(result => {
          // console.log(result);
          if (result.status === 200 && result.data.status === "Success") {


            swal("CheckIn Success....!", {
              icon: "success",
            });

            const filterDate = new Date(visitor_date);
            //getApprovedOnboardingList(filterDate)
          } else {

            swal("CheckIn failed....!", {
              icon: "warning",
            });
          }
        })

    }
    catch (error) {
      console.error(error);
      // if (error)
      //   setLoader(false);
      if (error) {
        alert("Something went wrong. Please try again Later....")
      }
      console.log(error.code);

    }

  }

  async function updateReject(data) {
    try {
      console.log(data, 'reject');
      // let visitor_request = new FormData();
      // visitor_request.append("Email" , data["email_Id"]);
      // visitor_request.append("Email" , data["email_Id"]);
      // visitor_request.append("Email" , data["email_Id"]);

      const visitorInformation = {
        "visitor_id": data.visitorId,
        "approval_flag": 0,
        "remark": remarks
      }
      await axios.post(config.mongo_endpoint + 'api/v1/employee/approval', visitorInformation, {
        headers: {
          //  "x-access-token" : obj.AgentToken,  
          'Content-Type': 'multipart/form-data',
        }
      })
        .then(result => {
          // console.log(result);
          if (result.status === 200 && result.data.status === "Success") {

            swal("Rejected Updated....!", {
              icon: "success",
            });
            const filterDate = new Date(visitor_date);
            // getApprovedOnboardingList(filterDate)
          } else {

            swal("Rejected failed....!", {
              icon: "warning",
            });
          }
        })


    }
    catch (error) {
      console.error(error);
      // if (error)
      //   setLoader(false);
      if (error) {
        alert("Something went wrong. Please try again Later....")
      }
      console.log(error.code);

    }

  }

  const [modalOpen, setModalOpen] = useState(false);
  const [remarks, setRemarks] = useState('');
  const [visitorReject, setVisitorReject] = useState({});

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    // Optionally, clear remarks state
    setRemarks('');
  };

  const handleReject = (data) => {
    setVisitorReject(data);
    openModal();
  };

  const handleSubmit = () => {
    // Call rejectUpdate function with row values and remarks
    updateReject(visitorReject);
    // Close the modal after submitting
    closeModal();
  };


  const handleSearch = () => {
    setCompanyValue(companyName);
    console.log("companyinfo", companyName);
    filterdata();

  }
  const [departmentData, setDepartmentData] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);


  const [companyName, setCompanyName] = useState('');
  const [visitorType, setVisitorType] = useState('');
  const [departmentName, setDepartmentName] = useState('');
  const [visitingPerson, setVistingPerson] = useState('');


  const [visitingDate, setVisitingDate] = useState(TodayDate);
  const [visitingEndDate, setVisitingEndDate] = useState(TodayDate);
  const [companyDatas, setCompanyDatas] = useState([]);
  const getDepartmentList = async () => {

    let departmentData = await fetch(config.mongo_endpoint + "api/v1/department/all", {
      method: "GET",
      headers: {
        "x-access-token": obj.AgentToken,
      },


    });

    let resultDepartmentData = await departmentData.json();
    if (resultDepartmentData.status === 'Success') {
      const departmentlist = resultDepartmentData.data;
      setDepartmentData(departmentlist);
    }
   
  }



  const handledepartment = (event) => {
    //console.log(event)
    const department = event.target.value
    setDepartmentName(department);
    setVistingPerson('');
    setEmployeeData([]);
    //console.log(department)
    getEmployeeList(department);
  }

  const getEmployeeList = async (department) => {

    const departmentCode = department;

    const departmentData = new FormData();
    departmentData.append("department_code", departmentCode);

    const resultEmployeeData = await axios.post(config.mongo_endpoint + 'api/v1/employee/department/departmentCode', departmentData, {
      headers: {
        //  "x-access-token" : obj.AgentToken,  
        'Content-Type': 'multipart/form-data',
      }
    });
    


    if (resultEmployeeData.data.status === 'Success') {
      const employeelist = resultEmployeeData.data.data;
      setEmployeeData(employeelist);
      //console.log(employeelist,'emp')
    }

  }

  const DepartmentList = async (company) => {
    const companyCode = company;

    const response = await fetch(`${config.mongo_endpoint}api/v1/department/bycompanycode?company_code=${companyCode}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": obj.AgentToken,
      },
    });

   

    let resultDepartmentData = await response.json();
    if (resultDepartmentData.status === 'Success') {
      const departmentlist = resultDepartmentData.data;
      setDepartmentData(departmentlist);
    }
    console.log(resultDepartmentData, 'dep')
  }
  const handleCompanyChange = (event) => {
    setCompanyName(event.target.value);
    DepartmentList(event.target.value);
  };

  const handleVisitorTypeChange = (event) => {

    setVisitorType(event.target.value);

  };
  const handleVistorPerson = (event) => {

    setVistingPerson(event.target.value);

  };

  const handleVisitingDateChange = (event) => {
    const filterdDate = new Date(event);
    setVisitingDate(filterdDate);
  };

  const handleVisitingEndDateChange = (event) => {
    const filterdDate = new Date(event);
    setVisitingEndDate(filterdDate);
  };

  const getCompanyList = async () => {
    let companyData = await fetch(config.mongo_endpoint + "api/v1/company/getall", {
      method: "GET",
      headers: {

        "x-access-token": obj.AgentToken,

      }
    });

    let resultCompanyData = await companyData.json();
    if (resultCompanyData.status === 'Success') {
      const companylist = resultCompanyData.data;

      setCompanyDatas(companylist);


    }
    // console.log("CompanyData", resultCompanyData)
  }
  async function filterdata() {

    try {

      const departmentData = new FormData();
      departmentData.append("company_code", companyName);
      departmentData.append("department_code", departmentName);
      departmentData.append("meeting_person", visitingPerson);
      departmentData.append("purpose_of_visit", visitorType);
      departmentData.append("start_date", (visitingDate));
      departmentData.append("end_date", (visitingEndDate));


      const resultEmployeeData = await axios.post(config.mongo_endpoint + 'api/v1/visitor/filterVisitors', departmentData, {
        headers: {
          //  "x-access-token" : obj.AgentToken,  
          'Content-Type': 'multipart/form-data',
        }
      });


      if (resultEmployeeData.status === 200) {
        
        const employeelist = resultEmployeeData.data;
        setData(employeelist);
      
      }
    } catch (error) {
  
      console.error("Error fetching visitor data:", error);
      // Handle error as needed (e.g., show an error message)
    }
  }



  return (
    <>

      <AgentMenu></AgentMenu>

      <div className="container-fluid pr-0" id="main-area">

        <Navbar />

        <div className="container-fluid">
          <div className="d-flex border-bottom pb-15 pt-15">
            <div className="me-auto ">
              <h2 className="page-title">Manage Visitor Checkout </h2>
            </div>
            <div></div>
          </div>
          <section className="voting-section">
            <div className="container">
              <div className="row">
                {/* <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                    
                      <Grid item xs={12}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={['DatePicker']}>
                            <DatePicker
                              label="Visitor Date *"
                              slotProps={{ textField: { size: 'small' } }}
                              onChange={handleDate}
                              format="DD-MM-YYYY"
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box> */}
                <Box>

                  <Grid container spacing={2}>

                    <Grid item xs={3}>

                      <Grid item xs={12}>
                        <FormControl fullWidth style={{ marginTop: '15px' }}>
                          <InputLabel id="demo-simple-select-label">Company Name </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            size="small"
                            name="company_name"
                            label="Company Name *"
                            value={companyName} // Set defaultValue to the desired default value
                            onChange={handleCompanyChange}
                            readOnly={readonlyCompany}
                          >
                            <MenuItem value={0}>Select Company</MenuItem>
                            {companyDatas.map((res) => (
                              <MenuItem key={res.company_code} value={res.company_code}>{res.company_name}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>


                    <Grid item xs={3}>
                      <FormControl fullWidth style={{ marginTop: '15px' }}>
                        <InputLabel id="demo-simple-select-label">Department </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          size="small"
                          name="department_name"
                          label="Company Name *"

                          onChange={handledepartment}
                        >
                          {departmentData.map((res) => (
                            <MenuItem value={res.department_code}>{res.department_name}</MenuItem>
                          ))}

                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={3}>
                      <FormControl fullWidth style={{ marginTop: '15px' }}>
                        <InputLabel id="demo-simple-select-label">Employee  </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          size="small"
                          name="meeting_person"
                          label="Employee*"
                          onChange={handleVistorPerson}

                        >

                          {
                            employeeData.map((list) => (
                              <MenuItem value={`${list.empid}`}>{list.name}</MenuItem>
                            ))
                          }

                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                      <FormControl fullWidth style={{ marginTop: '15px' }}>
                        <InputLabel id="demo-simple-select-label">Visitor Type </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          size="small"
                          name="visitor_type"
                          label="Purpose of Visit *"

                          onChange={handleVisitorTypeChange}

                        >
                          <MenuItem value="Meeting">Meeting</MenuItem>
                          <MenuItem value="Enquiry">Enquiry</MenuItem>
                          <MenuItem value="Courier">Courier </MenuItem>
                          <MenuItem value="Interview">Interview </MenuItem>
                          <MenuItem value="Suppliers">Suppliers</MenuItem>
                          <MenuItem value="Maintenance">Maintenance</MenuItem>
                          <MenuItem value="Business">Business</MenuItem>
                          <MenuItem value="Personal">Personal</MenuItem>
                          <MenuItem value="Others">Others</MenuItem>
                        </Select>

                      </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                      {/* Left Section */}
                      <Grid item xs={12} sx={{ marginTop: "10px" }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={['DatePicker']}>
                            <DatePicker
                              label="Start Date "
                              // Value={visitingDate}
                              // {...sailorInfoHandler("date_of_birth")}
                              //defaultValue={visitingDate}
                              slotProps={{ textField: { size: 'small' } }}

                              format="DD-MM-YYYY"
                              onChange={handleVisitingDateChange}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                    <Grid item xs={3}>
                      {/* Left Section */}
                      <Grid item xs={12} sx={{ marginTop: "10px" }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={['DatePicker']}>
                            <DatePicker
                              label="End Date "
                              // Value={visitingEndDate}
                              // {...sailorInfoHandler("date_of_birth")}
                              // defaultValue={visitor_date}
                              slotProps={{ textField: { size: 'small' } }}

                              format="DD-MM-YYYY"
                              onChange={handleVisitingEndDateChange}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      </Grid>
                    </Grid>

                    <Grid container justifyContent="flex-end" >

                      <Button
                        type="submit"
                        onClick={handleSearch}
                        variant="contained"
                        sx={{ marginTop: "20px" }} // Align to the right
                      >
                        Search
                      </Button>
                      &nbsp;
                      &nbsp;
                      &nbsp;



                    </Grid> &nbsp;

                  </Grid>
                </Box>
              </div>
            </div>
          </section>
          <TableContainer component={Paper} style={{ marginTop: '15px' }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table" >
              <TableHead>
                <TableRow>

                  <TableCell align="center" style={{ background: '#ddd' }}># </TableCell>
                  <TableCell align="center" style={{ background: '#ddd' }}>Company </TableCell>
                  <TableCell align="center" style={{ background: '#ddd' }}>Vistor Name </TableCell>
                  <TableCell align="center" style={{ background: '#ddd' }}>Phone No</TableCell>
                  <TableCell align="center" style={{ background: '#ddd' }}>Host</TableCell>
                  <TableCell align="center" style={{ background: '#ddd' }}>Purpose of Visit</TableCell>
                  <TableCell align="center" style={{ background: '#ddd' }}>Check In Time</TableCell>
                  <TableCell align="center" style={{ background: '#ddd' }}>Check Out Time</TableCell>
                  <TableCell align="center" style={{ background: '#ddd' }}>Status</TableCell>
                  <TableCell align="center" style={{ background: '#ddd' }}>Badge</TableCell>



                </TableRow>
              </TableHead>
              <TableBody>
                {data.length === 0 ? (<TableRow><TableCell align="center" colSpan={8} style={{ padding: '5px 15px' }}> No Record Found</TableCell></TableRow>) : ""}
                {data.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="center" style={{ padding: '5px 15px' }}> {index + 1}</TableCell>
                    <TableCell align="center" style={{ padding: '5px 15px' }}> {row.company_name}</TableCell>
                    <TableCell align="center" style={{ padding: '5px 15px' }}> {row.visitor_name}</TableCell>
                    <TableCell align="center" style={{ padding: '5px 15px' }}> {row.phone_no}</TableCell>
                    <TableCell align="center" style={{ padding: '5px 15px' }}>{row.meeting_person} </TableCell>
                    <TableCell align="center" style={{ padding: '5px 15px' }}>{row.purpose_of_visit} </TableCell>
                    <TableCell align="center" style={{ padding: '5px 15px' }}>{row.checkin ? (<Moment format="DD-MM-YYYY hh:mm A">{row.checkin}</Moment>) : ""} </TableCell>
                    <TableCell align="center" style={{ padding: '5px 15px' }}>{row.checkout ? (<Moment format="DD-MM-YYYY hh:mm A">{row.checkout}</Moment>) : ""} </TableCell>
                    <TableCell align="center" style={{ padding: '5px 15px' }}>
                      {row.checkin !== null && row.checkout === null && (
                        <>
                          {/* <Button variant="contained" size="small" onClick={() => updateCheckout(row)}>CheckOut</Button>
                          {' '} */}

<Button variant="contained" size="small" onClick={() => handleCheckoutOpen(row)}>CheckOut</Button>
{' '}
                        </>
                      )}

                      {
                        row.checkin === null && row.checkout === null && (
                          <>
                            {row.approve_flag === '0' ? (
                              <RemoveDoneIcon />
                            ) : (
                              <Stack direction="row" spacing={2}>
                                <Button variant="contained" color="primary" size="small" onClick={() => updateAccept(row)}>
                                  Accept
                                </Button>
                                <Button variant="contained" color="secondary" size="small" onClick={() => handleReject(row)}>
                                  Reject
                                </Button>
                              </Stack>
                            )}
                          </>
                        )
                      }

                      {row.checkout !== null && (
                        <>
                          <DoneIcon />

                        </>
                      )}
                    </TableCell>


                    <TableCell align="center" style={{ padding: '5px 15px' }}> <BadgeIcon onClick={() => { handleOpen(row) }} />
                    </TableCell>

                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

        </div>

        <Modal
          size={'sm'}
          open={open}
          onClose={handleClose}
          aria-labelledby="visitor-pass-modal-title"
          aria-describedby="visitor-pass-modal-description"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <div style={{ width: '50%', maxHeight: '500px', background: '#fff', padding: '20px', boxShadow: '0 4px 8px rgba(0,0,0,0.2)', borderRadius: '8px' }}>
            <div ref={printRef} style={{ width: '100%', maxHeight: '500px', overflow: 'auto' }}>
              <table ref={printRef} style={{ border: '1px solid #000', width: '100%', marginTop: '20px', padding: '0px', fontFamily: 'Helvetica', background: '#fff', fontSize: '11px' }} className="brd">
                <thead>
                  <tr>
                    <th colSpan="4" style={{ borderBottom: '1px solid #ccc', color: 'black', textAlign: 'center', fontSize: '15px', borderRight: '1px solid #ccc' }}>
                      {/* AVC Product Limited */}
                      {companyData}
                    </th>
                  </tr>
                  <tr>
                    <th colSpan="4" style={{ borderBottom: '1px solid #ccc', color: 'black', textAlign: 'center', fontSize: '11px', borderRight: '1px solid #ccc' }}>
                      Visitor Pass Badge
                    </th>
                  </tr>
                  <tr>
                    <td style={{ borderBottom: '1px solid #ccc', color: 'black', fontSize: '11px', borderRight: '1px solid #ccc' }}>
                      <b>Visitor Name</b>
                    </td>
                    <td colSpan="2" style={{ borderBottom: '1px solid #ccc', color: 'black', fontSize: '11px', borderRight: '1px solid #ccc' }}>
                      {visitorPass.visitor_name}
                    </td>
                    <td rowSpan="5" style={{ borderBottom: '1px solid #ccc', color: 'black', textAlign: 'center', fontSize: '30px', fontWeight: '600', borderRight: '1px solid #ccc' }}>
                      <img src={visitorPass.imagepath} style={{ width: '100px', height: '120px' }} alt="Visitor's Avatar" />

                    </td>
                  </tr>
                  <tr>
                    <td style={{ borderBottom: '1px solid #ccc', color: 'black', fontSize: '11px', borderRight: '1px solid #ccc' }}>
                      <b>Date</b>
                    </td>
                    <td colSpan="2" style={{ borderBottom: '1px solid #ccc', color: 'black', fontSize: '11px', borderRight: '1px solid #ccc' }}>
                      {/* {visitorPass.checkin} */}
                      <Moment format="DD-MM-YYYY">{visitorPass.checkin}</Moment>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ borderBottom: '1px solid #ccc', color: 'black', fontSize: '11px', borderRight: '1px solid #ccc' }}>
                      <b>Contact Number</b>
                    </td>
                    <td colSpan="2" style={{ borderBottom: '1px solid #ccc', color: 'black', fontSize: '11px', borderRight: '1px solid #ccc' }}>
                      {visitorPass.phone_no}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ borderBottom: '1px solid #ccc', color: 'black', fontSize: '11px', borderRight: '1px solid #ccc' }}>
                      <b>Whom to meet</b>
                    </td>
                    <td colSpan="2" style={{ borderBottom: '1px solid #ccc', color: 'black', fontSize: '11px', borderRight: '1px solid #ccc' }}>
                      {visitorPass.meeting_person}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ borderBottom: '1px solid #ccc', color: 'black', fontSize: '11px', borderRight: '1px solid #ccc' }}>
                      <b>Purpose</b>
                    </td>
                    <td colSpan="2" style={{ borderBottom: '1px solid #ccc', color: 'black', alignContent: 'left', fontSize: '11px', padding: '15px', borderRight: '1px solid #ccc' }}>
                      {visitorPass.purpose_of_visit}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ borderBottom: '1px solid #ccc', color: 'black', fontSize: '11px', borderRight: '1px solid #ccc' }}>
                      <b>In Time</b> : <Moment format="hh:mm A">{visitorPass.checkin}</Moment>
                    </td>
                    <td colSpan="2" style={{ borderBottom: '1px solid #ccc', color: 'black', fontSize: '11px', borderRight: '1px solid #ccc' }}>
                      <b>Out Time</b> :
                    </td>
                  </tr>
                  <tr>
                    <td style={{ borderBottom: '1px solid #ccc', color: 'black', fontSize: '11px', borderRight: '1px solid #ccc' }}>
                      <b>Visitor Signature</b>
                    </td>
                    <td colSpan="2" style={{ borderBottom: '1px solid #ccc', color: 'black', fontSize: '11px', borderRight: '1px solid #ccc' }}>
                      <b>Contact Person Signature</b>
                    </td>
                  </tr>
                </thead>
                <thead>
                  <tr>
                    <td style={{ borderBottom: '1px solid #ccc', color: 'black', fontSize: '11px', borderRight: '1px solid #ccc' }}>

                    </td>
                    <td colSpan="2" style={{ borderBottom: '1px solid #ccc', color: 'black', fontSize: '11px', padding: '15px', borderRight: '1px solid #ccc' }}>

                    </td>
                  </tr>

                </thead>
              </table>
            </div>
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <Button variant="contained" className="no-print" onClick={handlePrint} size="small">Print Pass</Button>
            </Grid>
          </div>
        </Modal>


        {/* Reject Modal */}
        <Modal open={modalOpen} onClose={closeModal}>
          <div style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'white',
            padding: '20px',
            outline: 'none',
            borderRadius: '8px',
          }}>
            <TextField
              multiline
              rows={4}
              variant="outlined"
              placeholder="Enter remarks"
              value={remarks}
              onChange={(e) => setRemarks(e.target.value)}
              style={{ marginBottom: '10px', width: '100%' }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={!remarks.trim()} // Disable submit if remarks are empty
            >
              Submit
            </Button>
          </div>
        </Modal>

        {/* Checkout Modal */}
        <Modal open={checkoutOpen} onClose={handleCheckoutClose}>
        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: '#fff', padding: '20px', outline: 'none' }}>
          {/* <h2>Checkout </h2> */}
          <Grid item xs={3}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label="Checkout Date"
        value={selectedDate}
        onChange={(date) => handleDateChange(date)}
        renderInput={(params) => <TextField {...params} />}
        inputFormat="DD-MM-YYYY"
      />
    </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12}>&nbsp;&nbsp;</Grid>
                        <Grid container justifyContent="flex-end">
   
          
          <Button variant="contained" color="primary" onClick={handleCheckoutSubmit}>Submit</Button>
          </Grid>
        </div>
      </Modal>
      </div>






    </>
  );
}

export default ManageVisitorCheckout;