import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { ReactFormBuilder, ReactFormGenerator, ElementStore } from 'react-form-builder2';
import 'react-form-builder2/dist/app.css';
import AgentMenu from "../../components/AgentMenu";
import Navbar from "../Navbar";
import Button from '@mui/material/Button';
import { formGroupClasses } from '@mui/material';
import './index.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { config } from "../../util/apiconfig";
import 'font-awesome/css/font-awesome.min.css'


const mongoose = require('mongoose');
// const items = [

//     {
//         key: 'Header',
//     }, {
//         key: 'TextInput',
//     }, {
//         key: 'TextArea',
//     }, {
//         key: 'RadioButtons',
//     }, {
//         key: 'Checkboxes',
//     }, {
//         key: 'Image',
//     },
//     {
//         key: 'Dropdown'
//     }
// ];


function Formbuilder() {
    const [showPreview, setShowPreview] = useState(false)
    const navigate = useNavigate();
    const [data, setData] = useState([])

    const closePreview = () => {
        setShowPreview(false)
    }

   
    const location = useLocation();
    // const FormId = mongoose.Types.ObjectId(location.state.Id)
    // console.log(FormId)

    return (
        <>

            <AgentMenu></AgentMenu>

            <div className="container-fluid pr-0" id="main-area">

                <Navbar />

                <div> <button style={{marginBottom: '10px'}} className="btn btn-primary" onClick={() => navigate(-1)}>Back</button></div>

                <div >

                    <ReactFormBuilder
                       url={config.mongo_endpoint + `api/v1/manageForm/inputFields/${location.state.Id}`}// req.param.formid
                        saveUrl={config.mongo_endpoint + `api/v1/manageForm/inputFields/${location.state.Id}`}//{task_data: []}
                    />

                </div>
                {/* {showPreview &&
                <div>
                     <div className="modal show d-block" role="dialog">
            <div className="modal-dialog modal-lg"  role="document">
              <div className="modal-content" ></div>
                    <div className="modal-dialog">
                        <div className="modal-content" style={{paddingLeft: '30px' }}>
                            <ReactFormGenerator
                                download_path=""
                                back_action="/"
                                back_name="Back"
                                answer_data={{}}
                                action_name="Save"
                                form_action="/"
                                form_method="POST"
                                //   onSubmit={this._onSubmit}
                                //   variables={this.props.variables}
                                data={data} />

                            <div className="modal-footer">
                                <button type="button" className="btn btn-default" data-dismiss="modal" onClick={closePreview}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                </div>
            } */}
            </div>

           





        </>
    )
}

export default Formbuilder;