import React, { useState, useEffect, useContext } from "react";
import Topnav from "../../common/topNav";
import { Link, useNavigate } from "react-router-dom";
// import Footer from "../../common/footer";
// import Banner from "../../components/banner";
import ELogo from "../../assets/images/sailor/logo.svg";
import ELogo1 from "../../assets/images/sailor/sailoronboard.svg";
import Lbg from "../../assets/images/sailor/login-bg.svg";
import NoteContext from "../../context/NoteContext";
import { config } from "../../util/apiconfig";
import axios from 'axios';
import swal from 'sweetalert';







function SailorForgetPassword() {
	const [bannerConfig, setbannerConfig] = useState({ bannerType: 3 });
	const navigate = useNavigate();

	const obj = useContext(NoteContext)

	const [Email, setEmail] = useState("");
	const [Password, setPassword] = useState("");



	async function submitData(Email, Password) {
		if (Email.length === 0) {
			alert("Enter a Email");
		}
		 else {
			let userData = { Email }			
			console.log(userData)
			await axios.post(config.mongo_endpoint+"api/v1/sailorUser/forgotpassword", userData, { headers: { 'Content-Type': 'application/json' } })
            .then(result => {
				console.log(result)
                if(result.data.success === true){					
					swal("please check your inbox and reset password....!", {
						icon: "success",
					  });
					 
				}else if(result.data.success === false){
					
					swal("User doesn't exit....!", {
						icon: "success",
					  });				
				}
				
            })
			
		}
	}
	return (
		<>

			<div class="login-section bg" style={{ backgroundImage: `url(${require("../../assets/images/sailor/login-bg.svg").default})` }}>
				<div class="row-2">
					<div class="login-white">
						<div class="login-lft">

							<div class="login-l">
								<div>
									<Link to="/"><img src={ELogo} className="img-fluid light-logo" alt="" width="70" />
										<img src={ELogo1} className="img-fluid logo-txt ml-10" alt="" width="100" height="93" />
									</Link>
									<h1><span>Welcome to </span> <br /> Sailor Onboarding</h1>

									{/* <div class="btn-sct">
									<div class="btn-lnks">
										 
										<div class="officer-lnk"><a href="javascript:void(0);">Manning Agent Login​</a></div>
									</div>
								</div> */}
								</div>
							</div>
						</div>

						<div class="login-rht">


							<div class="col-md-10 col-lg-10 col-xl-9 mx-auto">
								<div class="card-sigin">
									<div class="mb-2 d-flex">


									</div>
									<div class="card-sigin">
										<div class="main-signup-header">

											<h2 className="pt-50">Forgot Password</h2>


											<div class="form-group mt-3">
												<label>Enter your mail Id we will send you a link to reset your password</label> <input class="form-control"
													placeholder="Enter your email" type="text" value={Email} onChange={(e) => { setEmail(e.target.value) }} />
											</div>
											 

											<button class="btn btn-main-primary btn-block mt-0" onClick={() => { submitData(Email) }}> Submit</button>
 
										</div>
									</div>
								</div>
							</div>
						</div>



					</div>

				</div>
			</div>





		</>
	);
}

export default SailorForgetPassword;