import React, { useState, useEffect, useContext } from "react";
import SideMenu from "../../components/SideMenu";
// import { Link } from "react-router-dom";
// import Footer from "../../common/footer";
// import Banner from "../../components/banner";
import Ham from "../../assets/images/sailor/ham.svg";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import NoteContext from "../../context/NoteContext";
import Navbar from "../Navbar";
import { Box, Grid, TextField } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Button from '@mui/material/Button';
import { Controller, useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FormHelperText } from '@mui/material'; 
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import { config } from "../../util/apiconfig";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DataArrayTwoTone } from "@mui/icons-material";
import Moment from 'react-moment';
import dayjs from 'dayjs';

function UpdateSailor() {

    // const [status, setStatus] = React.useState('');
    const [formData, setFormData] = useState({})
    const [data, setData] = useState([])
    const [edate, setEdate] = React.useState(dayjs(''));
    const [sdate, setSdate] = React.useState(dayjs(''));
    const [onboard, setOnboard] = useState('');
    const [serial, setSerial] = useState('');
    const [statusVal, setStatusVal] = useState('');

    // const handleChange = (event) => {
    //     setStatus(value);
    // };
    const navigate = new useNavigate();
   const obj = useContext(NoteContext)
  //  if(obj.sailor_info.length === 0){
  //   if(obj.onboardingFlag === ""){
  //     navigate(-1)
  //   }
  //  }

   const [sailor_info, setsailor_info] = useState(obj.sailor_info)
   console.log("SAILORINFO", sailor_info)

   
   const sailorInformation = yup.object().shape({
    sailor_from: yup.string().required("Sailor From Required"),
    sailor_to: yup.string().required("Sailor To Required"),
    sailor_type: yup.string().required("Sailor Type Required"),
    imo_no: yup.string().required("IMoNo Required"),
    start_date: yup.string().required("Start Date Required"),
    end_date: yup.string().required("End Date Required"),
    status: yup.string().required("Status Required"),  
  });
  
  const prefill = {    
    status: "complete"
  }

  const { register: sailorInfoHandler,
    handleSubmit: sailorInfoHandlerSubmit,
    reset: voterInfoReset,
    control,
    watch,
    setValue,
    formState: { errors: sailorInfoErrors } } = useForm({
      resolver: yupResolver(sailorInformation),
      mode: 'OnSubmit',
      
    });

    const handleDateChange = (formdate) => {
        if (formdate) {
          const date = new Date(formdate);
          // Format the date to YYYY-MM-DD
          const formattedDate = date.toLocaleDateString('en-GB', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
          }).split('/').reverse().join('-');
          return formattedDate;
        }
       
      };

    useEffect(()=>{
      setValue('sailor_from',sailor_info.sailorFrom);
      setValue('sailor_to',sailor_info.sailorTo);
      setValue('sailor_type',sailor_info.shipType);
      setValue('imo_no',sailor_info.IMoNumber);
      setValue('start_date',sailor_info.startDate);
      setSdate(dayjs(handleDateChange(sailor_info.startDate)));
      setValue('end_date',sailor_info.endDate);
      setEdate(dayjs(handleDateChange(sailor_info.endDate)));
      setValue('status',sailor_info.status);
      setStatusVal(sailor_info.status);
      setValue('onBoardingID',sailor_info.onBoardingID);
      setOnboard(sailor_info.onBoardingID);
      setValue('serialNumber',sailor_info.serialNumber);
      setSerial(sailor_info.serialNumber);    
    }, [])

    // async function findHis(){
    //   const response = {'_id': sailor_info._id}     
    //   let data = await fetch(config.mongo_endpoint+"api/v1/sailorUser/sailorHistory/findAll", response, {
    //       method: "GET",
    //       headers: {
    //       "Content-type": "application/json",
    //       "x-access-token" : `${localStorage.getItem('Token')}`
    //       }
    //   });
    //   let res = await data.json();
    //   if (res.status === "Success") {
    //       console.log("sailor_history",res.data)          
    //       const resResult = res.data;
    //       console.log(resResult)
    //       setData(resResult);     
    //         // const dataResult = resResult.filter((ress)=>{
    //         //     return ress._id === _id
    //         // })
    //         // console.log(dataResult)
          
    //   } else {
    //       alert(res.message);
  
    //   }    
      
      
  // }
  

    const onSubmit = async ( data ) => {
      const sailor_editinfo = {
        onBoardingID: onboard,
        sailorFrom : data["sailor_from"],
        sailorTo : data["sailor_to"],
        shipType : data["sailor_type"],
        IMoNumber : data["imo_no"],
        startDate : handleDateChange(sdate),
        endDate : handleDateChange(edate),        
        status : data["status"],
        userId: localStorage.getItem('userId'),
        serialNumber:serial,

    } 
    console.log(sailor_editinfo);  
        await axios.post(config.mongo_endpoint+'api/v1/sailorUser/sailorHistory/update', sailor_editinfo, 
        
        { headers: { 'Content-Type': 'application/x-www-form-urlencoded',
                     'x-access-token':localStorage.getItem('AgentToken')} })
                    .then(result => {
                      console.log(result)
                      if(result.status == 200 && result.data.status == "Success"){
                        toast.success("Sailor History Updated Successfully.", {
                          position: toast.POSITION.TOP_CENTER,
                          theme: "colored",
                        });  
                        navigate("/Sailorhistory");

                      } else {
                        toast.success("Some Thing went wrong try again later.", {
                          position: toast.POSITION.TOP_CENTER,
                          theme: "colored",
                        });  
                      }
                      
                      
                    })
                    .catch(error => {
                      // handle the error
                      console.error(error);
                      if (error) {
                        
                        toast.error("Some thing When wrong. Please try again Later....", {
                        position: toast.POSITION.TOP_CENTER,
                        theme: "colored",
                      });
              
                      }
                    
                    });

  
    } 


           
     
   

  return (
    <>
    <SideMenu></SideMenu>
    <div className="container-fluid pr-0" id="main-area">
<Navbar />
<div className="container-fluid">
<div className="d-flex border-bottom pb-15 pt-15">
    <div className="me-auto ">
        <h2 className="page-title">Update Sailor History</h2>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb  m-0">
                {/* <li className="breadcrumb-item"><a href="#">breadcrumb 01</a></li>
                <li className="breadcrumb-item"><a href="#">breadcrumb 02</a></li> */}
                {/* <li className="breadcrumb-item active" aria-current="page">Update Details </li> */}
            </ol>
        </nav>
    </div>

</div>
<section className="voting-section">
    <div className="container">
      <div className="row">

        {/* <h1>Voting</h1> */}
        <Box sx={{ width: '100%' }}>    
            <>
               <div className="stepper-content">
                
                  <form onSubmit={sailorInfoHandlerSubmit(onSubmit)}>                      
                  <Box mb={3.5}>
                    <Grid container spacing={3} marginTop={1}>
                        <Grid item sm={3} xs={12}>
                        <input type="hidden" name="serialNumber" value="hiddenValue" {...sailorInfoHandler("serialNumber")} />
                        <TextField
                              size="small"
                              fullWidth
                              type="text"
                              value={sailor_info.sailor_from}
                              name="sailor_from"
                              label="Sailor From *"
                              defaultValue={sailor_info.sailor_from}
                              {...sailorInfoHandler("sailor_from")}
                              id="outlined-error"
                              error={!!sailorInfoErrors['sailor_from']}
                              helperText={sailorInfoErrors['sailor_from'] ? sailorInfoErrors['sailor_from'].message : ''}
                            />
                            <input type="hidden" name="onBoardingID" value="hiddenValue" {...sailorInfoHandler("onBoardingID")} />

                        </Grid>
                        <Grid item sm={3} xs={12}>
                        <TextField
                              size="small"
                              fullWidth
                              type="text"
                              value={sailor_info.sailor_to}
                              name="sailor_to"
                              label="Sailor To *"
                              defaultValue={data.sailor_to}
                              {...sailorInfoHandler("sailor_to")}
                              id="outlined-error"
                              error={!!sailorInfoErrors['sailor_to']}
                              helperText={sailorInfoErrors['sailor_to'] ? sailorInfoErrors['sailor_to'].message : ''}
                            />
                        </Grid>
                        <Grid item sm={3} xs={12}>
                        <TextField
                              size="small"
                              fullWidth
                              type="text"                                                            
                              defaultValue={data.sailor_type}
                              name="sailor_type"
                              value={sailor_info.sailor_type}
                              label="Sailor Type *"
                              
                              {...sailorInfoHandler("sailor_type")}
                              id="outlined-error"
                              error={!!sailorInfoErrors['sailor_type']}
                              helperText={sailorInfoErrors['sailor_type'] ? sailorInfoErrors['sailor_type'].message : ''}
                            />
                        </Grid>

                        <Grid item sm={3} xs={12}>
                        <TextField
                              size="small"
                              fullWidth
                              type="text"
                              value={sailor_info.imo_no}                              
                              defaultValue={data.imo_no}
                              name="imo_no"
                              label="IMo No *"
                              {...sailorInfoHandler("imo_no")}
                              id="outlined-error"
                              error={!!sailorInfoErrors['imo_no']}
                              helperText={sailorInfoErrors['imo_no'] ? sailorInfoErrors['imo_no'].message : ''}
                            />
                        </Grid>
                    </Grid>
                 
                

                <Grid container spacing={3} marginTop={1}>
                    
                        <Grid item sm={4} xs={12}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={['DatePicker', 'DatePicker', 'DatePicker']}>
                                <DatePicker
                                  label="Start Date *"                                
                                  value={sdate}
                                  name="start_date"                         
                                  {...sailorInfoHandler("start_date")}
                                  slotProps={{ textField: { size: 'small' } }}
                                  onChange={(start_date) => {
                                    setSdate(dayjs(start_date))
                                  }}
                                />
                              </DemoContainer>
                            </LocalizationProvider>

                            {sailorInfoErrors.start_date && <FormHelperText error={!!sailorInfoErrors['start_date']}>{sailorInfoErrors.start_date.message}</FormHelperText>}
                        </Grid>
                        <Grid item sm={4} xs={12}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={['DatePicker', 'DatePicker', 'DatePicker']}>
                                <DatePicker
                                  label="End Date *"
                                  {...sailorInfoHandler("end_date")}                                                                   
                                  value={edate}
                                  name="end_date"
                                  slotProps={{ textField: { size: 'small' } }}
                                  onChange={(end_date) => {
                                    setEdate(dayjs(end_date)) 
                                  }}
                                />
                              </DemoContainer>
                            </LocalizationProvider>

                            {sailorInfoErrors.end_date && <FormHelperText error={!!sailorInfoErrors['end_date']}>{sailorInfoErrors.end_date.message}</FormHelperText>}
                        </Grid>
                        <Grid item sm={4} xs={12}>
                                <FormControl fullWidth>                              
                                <InputLabel id="demo-simple-select-label">Status </InputLabel>
                                
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    // defaultValue={data.status}
                                    label="Status"
                                    name="status"                                    
                                    {...sailorInfoHandler("status")}
                                    value={statusVal}                                    
                                    error={!!sailorInfoErrors['status']}
                                    helperText={sailorInfoErrors['status'] ? sailorInfoErrors['status'].message : ''}
                                    onChange={(status)=>{
                                      console.log(status)
                                    setStatusVal(status.target.value);
                                    }}
                                >
                                    <MenuItem value="InProgress">InProgress</MenuItem>
                                    <MenuItem value="Completed">Completed</MenuItem>
                                     
                                </Select>
                                {sailorInfoErrors.status && <FormHelperText error={!!sailorInfoErrors['status']}>{sailorInfoErrors.status.message}</FormHelperText>}
                            </FormControl>
                        </Grid>
                        
                    </Grid>

                       </Box>
                 
                  <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop:'30px' }}>
                        <Button variant="contained" color="primary" type="submit">
                          Update Sailor History
                        </Button>
               

                    </div>

              </form>                    
                 
                  {/* <ToastContainer /> */}
                </div>
             
              
            </>
      
        </Box>
      </div>
    </div>
  </section>

</div>
</div>
    </>
    );
}

export default UpdateSailor;