import React, { useState, useEffect, useContext } from "react";
import Topnav from "../../common/topNav";
import { Link ,useNavigate} from "react-router-dom";
// import Footer from "../../common/footer";
// import Banner from "../../components/banner";
import Ham from "../../assets/images/sailor/ham.svg";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AgentMenu from "../../components/AgentMenu";
import NoteContext from "../../context/NoteContext";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Button from '@mui/material/Button';
import { config } from "../../util/apiconfig";
import axios from 'axios';
import Navbar from "../Navbar";
import swal from 'sweetalert';
import Moment from 'react-moment';
import Switch from '@mui/material/Switch';

import { Grid, Box } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import handleApiError from "../../util/apiUtil";
import { error } from "jquery";


function ViewUser() {
    const obj = useContext(NoteContext);
    const [data, setData] = useState([]);
    const navigate=useNavigate();
    const label = { inputProps: { 'aria-label': 'Color switch demo' } };


    const getUserList = async () => {
        let companyData = await fetch(config.mongo_endpoint + "api/v1/employee/all", {
            method: "GET",
            headers: {
                "Authorization": obj.AgentToken,
            }
        });

        let resultCompanyData = await companyData.json();
        if (resultCompanyData.status === 'Success') {
            const users = resultCompanyData.data;
            const logintype = obj.type;
        const logincompany = localStorage.getItem('company_code');
        if (logintype === 0) {
            setData(users);
           
        }else{
            const filter=resultCompanyData.data.filter(res => res.company_code === logincompany);
            setData(filter);
        }
            
            
        }
        //   console.log(resultCompanyData)
    }

    useEffect(() => {
        getUserList();
        getCompanyList();
        const logintype=obj.type;
        const logincompany= localStorage.getItem('company_code');
        if(logintype !== 0){
            setCompanyName(logincompany);
            setReadonlyCompany(true);
        setDepartmentName('');
        DepartmentList(logincompany);
        }

    }, [])


    const deleteCompany = async (companyCode) => {
       // console.log(companyCode);
    
        const companyData = {
            company_code: companyCode,
        };

    
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this imaginary file!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willDelete) => {
            if (willDelete) {
                try {
                    const response = await axios.post(config.mongo_endpoint + "api/v1/company/delete", companyData, {
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": obj.AgentToken,
                        },
                    });
    
                    if (response.status === 200 && response.data.status === 'Success') {
                       
                        getUserList(); 
                        swal("Company Name Deleted....!", {
                            icon: "success",
                        });
                    } else {
                       
                        swal("Error", "Failed to delete company", "error");
                    }
                } catch (error) {
                   
                    handleApiError(error, navigate);
                }
            } else {
                swal("Cancelled");
            }
        });
    };
    const toggleUserStatus = async (resdata) => {
        const updatedUser = { ...resdata, is_active: !resdata.is_active };
        try {
            const response = await axios.get(config.mongo_endpoint + `api/v1/employee/accountAccess/${resdata.empid}`, updatedUser, {
                headers: {
                    "Authorization": obj.AgentToken,
                }
            });
            if (response.data.status === 'Success') {
                swal(response.data.message, {
                    icon: "success",
                });
                getUserList(); 
            } else {
                swal(response.data.message,{
                    icon: "warning",
                });
               // console.error("Failed to update user status:", response.data.message);
            }
        } catch (error) {
            handleApiError(error,navigate)
        }
    };

    async function editCompany(resdata) {
       // console.log("ROWEDIT**", resdata)
        obj.setUpdate_company(resdata)
        obj.setUpdatingCompanyFlag(1);
    }

    const [companyName, setCompanyName] = useState('');
    const [departmentName, setDepartmentName] = useState('');
    const [companyData, setCompanyData] = useState([]);
    const [companyValue, setCompanyValue] = useState('');
    const [departmentData, setDepartmentData] = useState([]);
    const [readonlyCompany, setReadonlyCompany] = useState(false); // State for readonly flag


    const handleCompanyChange = (event) => {
        setCompanyName(event.target.value);
        setDepartmentName('');
        DepartmentList(event.target.value);
    };

    const getCompanyList = async () => {
        try {
            const response = await axios.get(config.mongo_endpoint + "api/v1/company/getall", {
                headers: {
                    "Authorization": obj.AgentToken,
                }
            });
    
            const resultCompanyData = response.data;
    
            if (resultCompanyData.status === 'Success') {
                const companyList = resultCompanyData.data;
                setCompanyData(companyList); 
            }
          
        } catch (error) {
           
             handleApiError(error, navigate); 
        }
    };
    const DepartmentList = async (company) => {
        const companyCode = company;
    
        try {
            const response = await axios.get(`${config.mongo_endpoint}api/v1/department/bycompanycode?company_code=${companyCode}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": obj.AgentToken,
                },
            });
    
            const resultDepartmentData = response.data;
    
            if (resultDepartmentData.status === 'Success') {
                const departmentList = resultDepartmentData.data;
                setDepartmentData(departmentList);
            }
         
        } catch (error) {
          
             handleApiError(error, navigate); 
        }
    };
    const handledepartment = (event) => {
        console.log(event)
        const department = event.target.value
        setDepartmentName(department);

    }

    const handleSearch = () => {

        filterList();

    }


    const filterList = async () => {
        let companyData = await fetch(config.mongo_endpoint + "api/v1/employee/all", {
            method: "GET",
            headers: {
                "Authorization": obj.AgentToken,
                // "x-access-token" : "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyaWQiOiI2NGRhMjA1NWVlOWQyYzIxNTBhMWQyMzkiLCJpYXQiOjE2OTc2MTk2MjR9.AywLCafWb2JxXVUfDcwAaewGxQ9wFXj_-kUG2lyNz08"
            }
        });

        let resultCompanyData = await companyData.json();
        if (resultCompanyData.status === 'Success') {


            let filteredData = resultCompanyData.data;
            //console.log(filteredData,'data')

                if (companyName && departmentName) {
                    
                    filteredData = filteredData.filter(rep => {
                        return rep.company_code === companyName && rep.department_code === departmentName;
                    });
                } else if (companyName) {
                    
                    filteredData = filteredData.filter(rep => {
                        return rep.company_code === companyName ;
                    });
                } else if (departmentName) {
            
                    filteredData = filteredData.filter(rep => {
                        return rep.department_code === departmentName;
                    });
                }


            // const users = resultCompanyData.data.map(user => ({
            //     ...user,
            //     active: user.active === 1 ? true : false // Convert active status to boolean
            // }));
            setData(filteredData);
        }
        //   console.log(resultCompanyData)
    }
    return (
        <>

            <AgentMenu></AgentMenu>

            <div className="container-fluid pr-0" id="main-area">

                <Navbar />

                <div className="container-fluid">
                    <div className="d-flex border-bottom pb-15 pt-15">
                        <div className="me-auto ">
                            <h2 className="page-title">Manage Employee</h2>
                        </div>
                        <div>
                            <Link to="/AddUsers" className="nav-link" >
                                <Button variant="contained" color="success">
                                    Add Employee
                                </Button></Link></div>
                    </div>


                    <section className="voting-section">
                        <div className="container">
                            <div className="row">
                                <Box>

                                    <Grid container spacing={2}>
                                        <Grid item xs={3}>

                                            <Grid item xs={12}>
                                                <FormControl fullWidth style={{ marginTop: '15px' }}>
                                                    <InputLabel id="demo-simple-select-label">Company Name </InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        size="small"
                                                        
                                                        name="company_name"
                                                        label="Company Name *"
                                                        value={companyName}// Set defaultValue to the desired default value
                                                        onChange={handleCompanyChange}
                                                        readOnly={readonlyCompany}
                                                    >
                                                        <MenuItem value={0}>Select Company</MenuItem>
                                                        {companyData.map((res) => (
                                                            <MenuItem key={res.company_code} value={res.company_code}>{res.company_name}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>


                                        <Grid item xs={3}>
                                            <FormControl fullWidth style={{ marginTop: '15px' }}>
                                                <InputLabel id="demo-simple-select-label">Department </InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    size="small"
                                                    name="department_name"
                                                    label="Company Name *"

                                                    onChange={handledepartment}
                                                >
                                                    {departmentData.map((res) => (
                                                        <MenuItem value={res.department_code}>{res.department_name}</MenuItem>
                                                    ))}

                                                </Select>
                                            </FormControl>
                                        </Grid>



                                        <Grid container justifyContent="flex-end" >

                                            <Button
                                                type="submit"
                                                onClick={handleSearch}
                                                variant="contained"
                                                sx={{ marginTop: "20px" }} // Align to the right
                                            >
                                                Search
                                            </Button>
                                            &nbsp;
                                            &nbsp;
                                            &nbsp;
                                            {/*                                        
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                sx={{ marginTop: "20px" }} // Align to the right
                                            >
                                                Download PDF Report
                                            </Button> */}

                                        </Grid> &nbsp;

                                    </Grid>
                                </Box>
                            </div>
                        </div>
                    </section>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table" >
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" style={{ background: '#ddd' }}># </TableCell>
                                    <TableCell align="center" style={{ background: '#ddd' }}>Employee Name </TableCell>
                                    <TableCell align="center" style={{ background: '#ddd' }}>Company </TableCell>
                                    <TableCell align="center" style={{ background: '#ddd' }}>Department </TableCell>
                                    <TableCell align="center" style={{ background: '#ddd' }}>Phone</TableCell>
                                    <TableCell align="center" style={{ background: '#ddd' }}>Status</TableCell>
                                    <TableCell align="center" style={{ background: '#ddd' }}>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.map((resdata, index) => (
                                    <TableRow
                                        key={index}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell align="center" style={{ padding: '5px 15px' }}>{index + 1}</TableCell>
                                        <TableCell align="center" style={{ padding: '5px 15px' }}>{resdata.name}</TableCell>
                                        <TableCell align="center" style={{ padding: '5px 15px' }}>{resdata.company_name}</TableCell>
                                        <TableCell align="center" style={{ padding: '5px 15px' }}>{resdata.department_name}</TableCell>
                                        <TableCell align="center" style={{ padding: '5px 15px' }}>{resdata.phone}</TableCell>
                                        <TableCell align="center" style={{ padding: '5px 15px' }}>
                                            <Switch
                                                  checked={resdata.is_active === 1}
                                                onChange={() => toggleUserStatus(resdata)}
                                                color="success"
                                            />
                                        </TableCell>
                                        <TableCell align="center" style={{ padding: '5px 15px' }}>
                                            {/* <DeleteIcon onClick={()=>deleteCompany(resdata.company_code)} />  */}
                                            {/* <EditIcon onClick={() => {editAgent()}} /> */}
                                            <Link to="/UpdateUser" onClick={() => { editCompany(resdata) }}><EditIcon /></Link>
                                            {/* <VisibilityIcon></VisibilityIcon>                            */}

                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>






        </>
    );
}

export default ViewUser;