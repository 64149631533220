import React, { useState, useEffect, useContext } from "react";
import { Box } from "@mui/material";
import AgentMenu from "../../components/AgentMenu";
import NoteContext from "../../context/NoteContext";
import Button from '@mui/material/Button';
import { FormHelperText, Grid, TextField } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useForm } from "react-hook-form";
import 'react-phone-input-2/lib/material.css'
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import Navbar from "../Navbar";
import axios from 'axios';
import { config } from "../../util/apiconfig";
import swal from 'sweetalert';
import { Country, State, City } from 'country-state-city';
import handleApiError from "../../util/apiUtil";
const countrycitystateHandler = require('countrycitystatejson');






function AddUser() {
    // const { AgentId } = match.params;
    // const isAddMode = !AgentId;
    // =================== Voter Info Tab-1 Validation  ================

    const sailorInformation = yup.object().shape({
        company_code: yup.string().required("Company Code Required"),
        // company_code: yup.string().required("Company Code Required"),
        department_code: yup.string().required("Department Code Required"),
        name: yup.string().required("Name Required"),
        phone: yup.string().required("Phone Required"),
        password: yup.string().required("Password Required"),
        email: yup.string().required("Email Required"),
    })

    // =================== Voter Info Tab-1 Validation  ================
    // =================== Voter Info Yup Resolver Starts  ================
    const { register: sailorInfoHandler,
        handleSubmit: sailorInfoHandlerSubmit,
        reset: voterInfoReset,
        setValue,
        formState: { errors: sailorInfoErrors } } = useForm({
            resolver: yupResolver(sailorInformation),
            mode: 'OnSubmit',
        });






    const [readonlyCompany, setReadonlyCompany] = useState(false);
    const [companyName, setCompanyName] = useState('');


    const [data, setData] = useState([]);

    const getCompanyList = async () => {
        let companyData = await fetch(config.mongo_endpoint + "api/v1/company/getall", {
            method: "GET",
            headers: {

                "Authorization": obj.AgentToken,
            }
        });

        let resultCompanyData = await companyData.json();
        if (resultCompanyData.status === 'Success') {
            const companylist = resultCompanyData.data;
            setData(companylist);

        }
        // console.log(resultCompanyData)
    }

    useEffect(() => {

        getCompanyList();
        const logintype = obj.type;
        const logincompany = localStorage.getItem('company_code');
        if (logintype !== 0) {
            setCompanyName(logincompany);
            setValue('company_code',logincompany);
            setReadonlyCompany(true);
        }
    }, []);





    const obj = useContext(NoteContext)


    const navigate = useNavigate();
    const LandingScreen = () => {
        navigate("/ViewUsers");
    }


    const onSubmit = async (data) => {
        const userinfo = {
            company_code: data["company_code"],
            department_code: data["department_code"],
            name: data["name"],
            phone: data["phone"],
            Password: data["password"],
            email: data["email"],
        }
        console.log(userinfo)
        await axios.post(config.mongo_endpoint + 'api/v1/employee/add', userinfo,
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': obj.AgentToken
                }
            })
            .then(result => {
                console.log(result)
                if (result.status == 200 && result.data.status == "Success") {
                    // toast.success("UserInformation added Successfully.", {
                    //   position: toast.POSITION.TOP_CENTER,
                    //   theme: "colored",
                    // });  
                    swal("UserInformation added Successfully....!", {
                        icon: "success",
                    });
                    LandingScreen();

                } else {
                    // toast.success("Some Thing went wrong try again later.", {
                    //   position: toast.POSITION.TOP_CENTER,
                    //   theme: "colored",
                    // });  
                    swal("Some Thing went wrong try again later..!", {
                        icon: "warning",
                    });
                }


            })
            .catch(error => {
                // handle the error
                console.error(error);
                if (error) {

                    toast.error("Some thing When wrong. Please try again Later....", {
                        position: toast.POSITION.TOP_CENTER,
                        theme: "colored",
                    });

                }

            });

    }




    const [depData, setDepData] = useState([]);

    const handleCompany = (event) => {
        //console.log(event)
        setCompanyName(event.target.value)
        const company_code = event.target.value.split("|")

        return axios.get(config.mongo_endpoint + 'api/v1/department/bycompanycode',
            {
                headers: { 'Content-Type': 'multipart/form-data', 'Authorization': obj.AgentToken },
                params: {
                    "company_code": company_code
                }
            }).then(result => {
                // console.log(result)
                if (result.status == 200 && result.data.status == "Success") {
                    const departmentlist = result.data.data;
                    setDepData(departmentlist);

                } else {
                    //console.log("error");
                }
            })
            .catch(error => {
                // handle the error
                handleApiError(error, navigate)
                console.error(error);
                if (error) {
                    console.log("erro");
                }
            });

    }








    return (
        <>
            <AgentMenu></AgentMenu>
            <div className="container-fluid pr-0" id="main-area">
                <Navbar />
                <div className="container-fluid">
                    <div className="d-flex border-bottom pb-15 pt-15">
                        <div className="me">
                            <h2 className="page-title">Add Employee</h2>
                        </div>

                    </div>
                    <section className="voting-section">
                        <div className="container">
                            <div className="row">

                                {/* <h1>Voting</h1> */}
                                <Box sx={{ width: '100%' }}>
                                    <>
                                        <div className="mto stepper-content">

                                            <form onSubmit={sailorInfoHandlerSubmit(onSubmit)}>
                                                <Box mb={3.5}>
                                                    <Grid container spacing={3} marginTop={1}>


                                                        <Grid item sm={3} xs={12}>
                                                            <FormControl fullWidth>
                                                                <InputLabel id="demo-simple-select-label">Company Name *</InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    size="small"
                                                                    name="company_code"
                                                                    label="Company Code *"

                                                                    value={companyName}
                                                                    readOnly={readonlyCompany}
                                                                    {...sailorInfoHandler("company_code")}
                                                                    error={!!sailorInfoErrors['company_code']}
                                                                    helperText={sailorInfoErrors['company_code'] ? sailorInfoErrors['company_code'].message : ''}
                                                                    onChange={handleCompany}

                                                                >
                                                                    {/* <MenuItem value="Country">State</MenuItem> */}
                                                                    {
                                                                        data.map((list) => (
                                                                            <MenuItem value={`${list.company_code}`}>{list.company_name}</MenuItem>
                                                                        ))
                                                                    }

                                                                </Select>
                                                                {sailorInfoErrors.state && <FormHelperText error={!!sailorInfoErrors['company_code']}>{sailorInfoErrors.state.message}</FormHelperText>}
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item sm={3} xs={12}>
                                                            <FormControl fullWidth>
                                                                <InputLabel id="demo-simple-select-label">Department  *</InputLabel>
                                                                {/* {JSON.stringify(depData)} */}
                                                                <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    size="small"
                                                                    name="department_code"
                                                                    label="Department Code *"
                                                                    {...sailorInfoHandler("department_code")}
                                                                    error={!!sailorInfoErrors['department_code']}
                                                                    helperText={sailorInfoErrors['department_code'] ? sailorInfoErrors['department_code'].message : ''}
                                                                >
                                                                    {
                                                                        depData.map((list) => (
                                                                            <MenuItem value={`${list.department_code}`}>{list.department_name}</MenuItem>
                                                                        ))
                                                                    }

                                                                </Select>
                                                                {sailorInfoErrors.city && <FormHelperText error={!!sailorInfoErrors['department_code']}>{sailorInfoErrors.city.message}</FormHelperText>}
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item sm={3} xs={12}>
                                                            <TextField
                                                                fullWidth
                                                                size="small"
                                                                type="text"
                                                                name="name"
                                                                label="Employee Name *"
                                                                {...sailorInfoHandler("name")}
                                                                id="outlined-error"
                                                                error={!!sailorInfoErrors['name']}
                                                                helperText={sailorInfoErrors['name'] ? sailorInfoErrors['name'].message : ''}
                                                            />
                                                        </Grid>

                                                        <Grid item sm={3} xs={12}>
                                                            <TextField
                                                                fullWidth
                                                                size="small"
                                                                type="number"
                                                                name="phone"
                                                                label="Phone *"
                                                                {...sailorInfoHandler("phone")}
                                                                id="outlined-error"
                                                                error={!!sailorInfoErrors['phone']}
                                                                helperText={sailorInfoErrors['phone'] ? sailorInfoErrors['phone'].message : ''}
                                                            />
                                                        </Grid>

                                                        <Grid item sm={3} xs={12}>
                                                            <TextField
                                                                fullWidth
                                                                size="small"
                                                                type="email"
                                                                name="email"
                                                                label="Email *"
                                                                {...sailorInfoHandler("email")}
                                                                id="outlined-error"
                                                                error={!!sailorInfoErrors['email']}
                                                                helperText={sailorInfoErrors['email'] ? sailorInfoErrors['email'].message : ''}
                                                            />
                                                        </Grid>

                                                        <Grid item sm={3} xs={12}>
                                                            <TextField
                                                                fullWidth
                                                                size="small"
                                                                type="text"
                                                                name="password"
                                                                label="Password *"
                                                                {...sailorInfoHandler("password")}
                                                                id="outlined-error"
                                                                error={!!sailorInfoErrors['password']}
                                                                helperText={sailorInfoErrors['password'] ? sailorInfoErrors['password'].message : ''}
                                                            />
                                                        </Grid>
                                                    </Grid>



                                                </Box>

                                                <div sx={{ display: 'flex', flexDirection: 'row', padding: '10px 10px', background: "#f5f5f5" }}>

                                                    <Button type="submit" variant="contained" color="success" sx={{ marginTop: "20px", marginLeft: "18px" }}>
                                                        SUBMIT
                                                    </Button>
                                                </div>
                                            </form>


                                        </div>


                                    </>

                                </Box>
                            </div>
                        </div>
                    </section>

                </div>
            </div>
        </>
    );
}

export default AddUser;