import React, { useState, useEffect, useContext } from "react";
import AgentMenu from "../../components/AgentMenu";
import Navbar from "../Navbar";
import { Link } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { FormHelperText, Grid, TextField } from "@mui/material";
import { data } from "jquery";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import axios from 'axios';
import { config } from "../../util/apiconfig";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import swal from 'sweetalert';
import NoteContext from "../../context/NoteContext";
import AddIcon from '@mui/icons-material/Add';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ReactFormBuilder, ReactFormGenerator, ElementStore } from 'react-form-builder2';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { v1 as uuidv1 } from 'uuid';


function ManageForms() {
    const sailorInformation = yup.object().shape({
        formName: yup.string().required("First Form Name Required").matches(/^[A-Za-z\s]*$/, "Only Alphabets allowed"),
        Description: yup.string().required("Description Required").matches(/^[A-Za-z\s]*$/, "Only Alphabets allowed"),
    })

    const obj = useContext(NoteContext)
    const [open, setOpen] = React.useState(false);
    const [data, setData] = useState([])
    const [status, setStatus] = useState('Active')
    const [formdata, setformData] = useState([]);
    const [length, setLength] = useState(0)
    async function getManageFormListDet() {
        let data = await fetch(config.mongo_endpoint + 'api/v1/manageForm/viewall', {
            method: "GET",
            headers: {
                "Content-type": "application/json",
                "x-access-token": `${localStorage.getItem('AgentToken')}`
            }
        });
        console.log(data)
        let res = await data.json();
        if (res.status === "Success") {
            setData(res.data)
            // const len = res.data.length
            // setLength(len + 1)

        } else {
            alert(res.message);

        }

    }
    useEffect(() => {
        getManageFormListDet()
    }, [])

    const handleChange = (event) => {
setStatus(event.target.value)
    }

    const handleClickOpen = () => {
        setOpen(true);
        // setValue('formCode', length)
        setFormId("")
        setValue("formName", "")
        setValue("Description", "")
    };
    const handleClose = () => {
        setOpen(false);
    };

    const [formId, setFormId] = useState("")
    const [showPreview, setShowPreview] = useState(false)

    const { register: sailorInfoHandler,
        handleSubmit: sailorInfoHandlerSubmit,
        reset: voterInfoReset,
        setValue,
        value,
        formState: { errors: sailorInfoErrors } } = useForm({
            resolver: yupResolver(sailorInformation),
            mode: 'OnSubmit',
        });

    const navigate = useNavigate();
    const addFormBuilder = (curRow) => {
        navigate('/Formbuilder', { state: { Id: curRow._id } });
    };

    const viewForm = (curRow) => {
        setShowPreview(true)
        setFormId(curRow._id)
        let rec = fetch(config.mongo_endpoint + `api/v1/manageForm/inputFields/${curRow._id}`).then(resp => resp.json()).then(resp => {
            setformData(resp)
            console.log(rec)
        })
    }

    const closePreview = () => {
        setShowPreview(false)
        setFormId("")
    }


    async function editManageForm(row) {
        setOpen(true)
        setFormId(row._id)
        console.log("ROW**", row)
        setValue('formName', row.FormName)
        // setValue('formCode', row.FormCode)
        setValue('Description', row.Description)
    }


    const deleteManageForm = (FormId) => {
        console.log(FormId)

        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this imaginary file!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    axios.delete(config.mongo_endpoint + "api/v1/manageForm/delete", {
                        headers: { "Content-type": "application/json" },
                        data: { "FormId": FormId }
                    })
                    swal("Form Info Deleted....!", {
                        icon: "success",
                    });
                    getManageFormListDet()
                } else {
                    swal("Cancelled");
                }
            });

        console.log(localStorage.getItem('Token'));


    }

    const onSubmit = async (data) => {
        try {
            const customInfo = {
                _id: formId || undefined,
                "FormCode": uuidv1(),
                "FormName": data["formName"],
                "Description": data["Description"],
                "Status": status

            }
            await axios.post(`${config.mongo_endpoint}api/v1/manageForm/${formId == "" ? "add" : "update"}`, customInfo, { headers: { 'Content-Type': 'application/json' } })
                .then(result => {
                    console.log(result);
                    if (result.status == 200 && result.data.status == 'Success') {
                        // toast.success("Registration Completed Successfully.", {
                        //     position: toast.POSITION.TOP_CENTER,
                        //     theme: "colored",
                        // });
                        swal("Saved Successfully...!", {
                            icon: "success",
                        });
                        setOpen(false)
                        getManageFormListDet()
                    } else {
                        swal("Form name is already exist", {
                            icon: "error",
                        });
                    }
                })

        }
        catch (error) {
            console.error(error);
            // if (error)
            //  setLoader(false);
            if (error) {
                alert("Something went wrong. Please try again Later....")
            }
            console.log(error.code);

        }

    }
    
    const handleSubmit = (data) => {
        const Id = formId;
        // let formData = config.mongo_endpoint + `api/v1/manageForm/inputFields/${Id}`
        const inputValue = {data}
         axios.post(`${config.mongo_endpoint}api/v1/manageForm/inputValues/${Id}`, inputValue, { headers: { 'Content-Type': 'application/json' } })
            .then(result => {
                console.log(result);
                if (result.status == 200 && result.data.status == 'Success') {
                    // toast.success("Registration Completed Successfully.", {
                    //     position: toast.POSITION.TOP_CENTER,
                    //     theme: "colored",
                    // });
                    swal("Saved Successfully...!", {
                        icon: "success",
                    });
                    // setOpen(false)
                    // getManageFormListDet()
                } else {
                    swal("Form name is already exist", {
                        icon: "error",
                    });
                }
            })
    }

    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
        '& .MuiDialogContent-root': {
            padding: theme.spacing(2),
        },
        '& .MuiDialogActions-root': {
            padding: theme.spacing(1),
        },
    }));

    return (
        <>

            <AgentMenu></AgentMenu>

            <div className="container-fluid pr-0" id="main-area">

                <Navbar />
                <div className="container-fluid">
                    <div className="d-flex border-bottom pb-15 pt-15">
                        <div className="me-auto ">
                            <h2 className="page-title">Manage Forms </h2>
                        </div>
                        <div>

                            <Button variant="contained" color="success" onClick={handleClickOpen}>
                                Add Custom Form
                            </Button></div>
                    </div>

                    <BootstrapDialog
                        onClose={handleClose}
                        aria-labelledby="customized-dialog-title"
                        open={open}
                    >
                        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                            Custom Form
                        </DialogTitle>
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <DialogContent dividers>

                            <form onSubmit={sailorInfoHandlerSubmit(onSubmit)}>
                                <Grid container spacing={3} marginTop={1}>

                                    {/* <Grid item sm={12} xs={12}>

                                        <TextField
                                            size="small"
                                            fullWidth
                                            type="text"
                                            disabled
                                            name="formCode"
                                            label="Form Code *"
                                            ref={sailorInfoHandler}
                                            {...sailorInfoHandler("formCode")}
                                            id="outlined-error"
                                            error={!!sailorInfoErrors['formCode']}
                                            helperText={sailorInfoErrors['formCode'] ? sailorInfoErrors['formCode'].message : ''}
                                        />

                                    </Grid> */}
                                    <Grid item sm={12} xs={12}>
                                        <TextField
                                            size="small"
                                            fullWidth
                                            type="text"
                                            name="formName"
                                            label="Form Name *"
                                            ref={sailorInfoHandler}
                                            {...sailorInfoHandler("formName")}
                                            id="outlined-error"
                                            error={!!sailorInfoErrors['formName']}
                                            helperText={sailorInfoErrors['formName'] ? sailorInfoErrors['formName'].message : ''}
                                        />

                                    </Grid>

                                    <Grid item sm={12} xs={12}>
                                        <TextField
                                            fullWidth
                                            multiline
                                            minRows={3}
                                            maxRows={10}
                                            size="small"
                                            type="text"
                                            name="Description"
                                            label="Description"
                                            {...sailorInfoHandler("Description")}
                                            id="outlined-error"
                                            error={!!sailorInfoErrors['Description']}
                                            helperText={sailorInfoErrors['Description'] ? sailorInfoErrors['Description'].message : ''}
                                        />
                                    </Grid>
                                    
                                    <Grid item sm={12} xs={12}>
                                    <FormControl>
     
                                  
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={value}
        onChange={handleChange}
      >
       
        <FormControlLabel value='Active' control={<Radio />} label="Active" />
        <FormControlLabel value='In-Active' control={<Radio />} label="In-Active" />
      
      </RadioGroup>
    </FormControl>
    </Grid>

                                    <DialogActions style={{ marginLeft: '500px' }}>
                                        <Button autoFocus type="submit"  >
                                            Submit
                                        </Button>
                                    </DialogActions>


                                </Grid>
                            </form>

                        </DialogContent>

                    </BootstrapDialog>

                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table" >
                            <TableHead>
                                <TableRow>

                                    {/* <TableCell align="center" style={{ background: '#ddd' }}># </TableCell> */}
                                    <TableCell align="center" style={{ background: '#ddd' }}>Form Code</TableCell>
                                    <TableCell align="center" style={{ background: '#ddd' }}>Form Name</TableCell>
                                    <TableCell align="center" style={{ background: '#ddd' }}>description</TableCell>
                                    <TableCell align="center" style={{ background: '#ddd' }}>Status</TableCell>
                                    <TableCell align="center" style={{ background: '#ddd' }}>Action</TableCell>



                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.map((row, index) => (
                                    <TableRow
                                        key={index}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        {/* <TableCell align="center" style={{ padding: '5px 15px' }}> {index + 1}</TableCell> */}
                                        <TableCell align="center" style={{ padding: '5px 15px' }}> {row.FormCode}</TableCell>
                                        <TableCell align="center" style={{ padding: '5px 15px' }}> {row.FormName}</TableCell>
                                        <TableCell align="center" style={{ padding: '5px 15px' }}>{row.Description} </TableCell>
                                        <TableCell align="center" style={{ padding: '5px 15px' }}>{row.Status} </TableCell>

                                        <TableCell align="center" style={{ padding: '5px 15px' }}>
                                            <EditIcon onClick={() => { editManageForm(row) }} />
                                            <DeleteIcon onClick={() => { deleteManageForm(row._id) }} />
                                            <Button style={{ marginInlineEnd: '10px' }} size="small" variant="contained" color="success" onClick={() => { addFormBuilder(row) }} >Add Field </Button>
                                            <Button size="small" variant="contained" color="success" onClick={() => { viewForm(row) }} >Preview Form </Button>
                                        </TableCell>



                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>

                {showPreview &&
                    <div>
                        <div className="modal show d-block" role="dialog">
                            <div className="modal-dialog modal-lg" role="document">
                                <div className="modal-content" ></div>
                                <div className="modal-dialog">
                                    <div className="modal-content" style={{ paddingLeft: '30px' }}>
                                        <ReactFormGenerator
                                            download_path=""
                                            back_action="/"
                                            // back_name="Back"
                                            answer_data={{}}
                                             action_name=""
                                            // form_action="/"
                                            // form_method="POST"
                                            // onSubmit={handleSubmit}
                                            data={formdata} />

                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-default" data-dismiss="modal" onClick={closePreview}>Close</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}

export default ManageForms;