import React, { useEffect, useState } from "react";
import { config } from "../../util/apiconfig";
import AgentMenu from "../../components/AgentMenu";
import Navbar from "../Navbar";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import swal from 'sweetalert';
import axios from 'axios';

const static_data = {
    "_id": {
        "$oid": "6534c51e1c77cf79bcf2d65b"
    },
    "InputFields": [
        {
            "id": "1562B75F-14B6-4D79-9E18-B333DBAAF77D",
            "element": "TextInput",
            "text": "Text Input",
            "required": false,
            "canHaveAnswer": true,
            "canHavePageBreakBefore": true,
            "canHaveAlternateForm": true,
            "canHaveDisplayHorizontal": true,
            "canHaveOptionCorrect": true,
            "canHaveOptionValue": true,
            "canPopulateFromApi": true,
            "field_name": "text_input_434CC7A0-186B-4737-8C4C-63B69D2F726E",
            "label": "Placeholder label"
        },
        {
            "id": "089AEF5C-83E4-4AAF-879A-A5E25C96B909",
            "element": "EmailInput",
            "text": "Email",
            "required": false,
            "canHaveAnswer": true,
            "canHavePageBreakBefore": true,
            "canHaveAlternateForm": true,
            "canHaveDisplayHorizontal": true,
            "canHaveOptionCorrect": true,
            "canHaveOptionValue": true,
            "canPopulateFromApi": true,
            "field_name": "email_input_42977BD0-BD1B-422D-9E87-F47D4817EC58",
            "label": "E-Mail"
        },
        {
            "id": "B6E3955A-1499-4887-AD8C-34A5DA516862",
            "element": "NumberInput",
            "text": "Number Input",
            "required": false,
            "canHaveAnswer": true,
            "canHavePageBreakBefore": true,
            "canHaveAlternateForm": true,
            "canHaveDisplayHorizontal": true,
            "canHaveOptionCorrect": true,
            "canHaveOptionValue": true,
            "canPopulateFromApi": true,
            "field_name": "number_input_AD709926-1BA5-4742-B7EB-600E4B4EF8EB",
            "label": "Placeholder label"
        },
        {
            "id": "09D10330-B810-4E10-AA28-0377D8B8DD05",
            "element": "PhoneNumber",
            "text": "Phone Number",
            "required": false,
            "canHaveAnswer": true,
            "canHavePageBreakBefore": true,
            "canHaveAlternateForm": true,
            "canHaveDisplayHorizontal": true,
            "canHaveOptionCorrect": true,
            "canHaveOptionValue": true,
            "canPopulateFromApi": true,
            "field_name": "phone_input_911B1D3A-CA4B-478A-8229-B0970AF9CCC3",
            "label": "Phone Number"
        }
    ],
    "FormCode": "a3395670-70a6-11ee-ac48-4d83751faba5",
    "FormName": "Registration",
    "Description": "sd",
    "Status": "Active",
    "__v": 8,
    "InputValue": [
        [
            {
                "id": "1562B75F-14B6-4D79-9E18-B333DBAAF77D",
                "name": "text_input_434CC7A0-186B-4737-8C4C-63B69D2F726E",
                "custom_name": "text_input_434CC7A0-186B-4737-8C4C-63B69D2F726E",
                "value": "Uername"
            },
            {
                "id": "089AEF5C-83E4-4AAF-879A-A5E25C96B909",
                "name": "email_input_42977BD0-BD1B-422D-9E87-F47D4817EC58",
                "custom_name": "email_input_42977BD0-BD1B-422D-9E87-F47D4817EC58",
                "value": "asd@gmail.com"
            },
            {
                "id": "B6E3955A-1499-4887-AD8C-34A5DA516862",
                "name": "number_input_AD709926-1BA5-4742-B7EB-600E4B4EF8EB",
                "custom_name": "number_input_AD709926-1BA5-4742-B7EB-600E4B4EF8EB",
                "value": "23"
            },
            {
                "id": "09D10330-B810-4E10-AA28-0377D8B8DD05",
                "name": "phone_input_911B1D3A-CA4B-478A-8229-B0970AF9CCC3",
                "custom_name": "phone_input_911B1D3A-CA4B-478A-8229-B0970AF9CCC3",
                "value": "9898989893"
            }
        ],
        [
            {
                "id": "1562B75F-14B6-4D79-9E18-B333DBAAF77D",
                "name": "text_input_434CC7A0-186B-4737-8C4C-63B69D2F726E",
                "custom_name": "text_input_434CC7A0-186B-4737-8C4C-63B69D2F726E",
                "value": "Name"
            },
            {
                "id": "089AEF5C-83E4-4AAF-879A-A5E25C96B909",
                "name": "email_input_42977BD0-BD1B-422D-9E87-F47D4817EC58",
                "custom_name": "email_input_42977BD0-BD1B-422D-9E87-F47D4817EC58",
                "value": "qwe@gmail.com"
            },
            {
                "id": "B6E3955A-1499-4887-AD8C-34A5DA516862",
                "name": "number_input_AD709926-1BA5-4742-B7EB-600E4B4EF8EB",
                "custom_name": "number_input_AD709926-1BA5-4742-B7EB-600E4B4EF8EB",
                "value": "3"
            },
            {
                "id": "09D10330-B810-4E10-AA28-0377D8B8DD05",
                "name": "phone_input_911B1D3A-CA4B-478A-8229-B0970AF9CCC3",
                "custom_name": "phone_input_911B1D3A-CA4B-478A-8229-B0970AF9CCC3",
                "value": "8908989898"
            }
        ]
    ]
}


function CustomForm() {
    const [data, setData] = useState([]);
    const [show, setshow] = useState(false)
    const [Id, setId] = useState('')
    const [formName, setFormName] = useState('')
    const [form, setForm] = useState([])


    useEffect(() => {
        getManageFormListDet()
    }, [])

    async function getManageFormListDet() {
        let data = await fetch(config.mongo_endpoint + 'api/v1/manageForm/viewall', {
            method: "GET",
            headers: {
                "Content-type": "application/json",
                "x-access-token": `${localStorage.getItem('AgentToken')}`
            }
        });
        let res = await data.json();
        if (res.status === "Success") {
            setData(res.data)
        } else {
            alert(res.message);
        }
    }


    const showFormDetail = (rec) => {
        setFormName(rec.FormName)
        setId(rec._id)
        // setForm(static_data)
        console.log(rec)
        setForm(rec)
        // let data = fetch(config.mongo_endpoint + `api/v1/manageForm/inputFieldsAndValues/${rec._id}`).then(resp => resp.json()).then(resp => {
        //     const newArray = [...form, resp];
        //     console.log(resp.InputFields)
        //     setForm([...form, resp]);
        //    setForm([...form, resp]);
        // })

        setshow(true)

    }
    console.log(form)

    const navigate = useNavigate();
    const addForm = () => {
        navigate('/AddForm', { state: { Id: Id, FormName: formName } });
    };

    const editFormData = (curRow) => {
        navigate('/AddForm' , { state: { Id: Id, FormName: formName }});
    }

    // const deleteValues = (curRow) => {

    //     swal({
    //         title: "Are you sure?",
    //         text: "Once deleted, you will not be able to recover this imaginary file!",
    //         icon: "warning",
    //         buttons: true,
    //         dangerMode: true,
    //     })
    //         .then((willDelete) => {
    //             if (willDelete) {
    //                 axios.delete(config.mongo_endpoint + "api/v1/manageForm/inputValues/delete", {
    //                     headers: { "Content-type": "application/json" },
    //                     data: { "FormId": curRow._id, "inputValue": curRow.inputValue }
    //                 })
    //                 swal("Form Info Deleted....!", {
    //                     icon: "success",
    //                 });
    //                // getManageFormListDet()
    //             } else {
    //                 swal("Cancelled");
    //             }
    //         });

    //     console.log(localStorage.getItem('Token'));


    // }


    return (
        <>

            <AgentMenu></AgentMenu>

            <div class="container-fluid pr-0" id="main-area">
                <Navbar />
                <div className="container-fluid">
                    <div class="d-flex border-bottom pb-15 pt-15">
                        <div class="me-auto ">
                            <h2 class="page-title">Custom Form</h2>

                        </div>

                    </div>
                    {!show &&
                        <div class="row g-6 mb-6">
                            {data.map((rec) => (

                                <div class="col-xl-3 col-sm-6 col-12" >
                                    <div class="card  border-0" >
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col" >
                                                    <div key={rec._id} class="font-semibold text-sm d-block mb-2" onClick={() => showFormDetail(rec)}>{rec.FormName}</div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    }

                    {show &&
                        <div>


                            <div>

                                <Button variant="contained" color="success" style={{ marginBottom: '5px', marginTop: '5px', float: 'right' }} onClick={() => addForm()}>
                                    Add
                                </Button>
                                <Button variant="contained" color="success" style={{ marginBottom: '5px', marginTop: '5px', float: 'left' }} onClick={() => setshow(false)}>
                                    Back
                                </Button></div>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table" >
                                    <TableHead>
                                        <TableRow>
                                            {/* <pre>{JSON.stringify(form.InputFields)}</pre> */}

                                            {/* <TableCell align="center" style={{ background: '#ddd' }}># </TableCell> */}
                                            {
                                                form.InputFields.map(col => (
                                                    <TableCell key={col.field_name} align="center" style={{ background: '#ddd' }}>{col.label}</TableCell>

                                                ))

                                            }
                                            <TableCell align="center" style={{ background: '#ddd' }}>Actions</TableCell>



                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {/* {data.map((row, index) => ( */}
                                        <TableRow
                                            // key={index}
                                           
                                        >
                                            {/* <TableCell align="center" style={{ padding: '5px 15px' }}> {index + 1}</TableCell> */}
                                            {/* <TableCell align="center" style={{ padding: '5px 15px' }}> {row.FormName}</TableCell>
                                        <TableCell align="center" style={{ padding: '5px 15px' }}>{row.Description} </TableCell>
                                        <TableCell align="center" style={{ padding: '5px 15px' }}>{row.Status} </TableCell> */}

                                            <TableCell align="center" style={{ padding: '5px 15px' }}>

                                                {/* <EditIcon onClick={() => { editManageForm(row) }} />
                                            <DeleteIcon onClick={() => { deleteManageForm(row._id) }} />
                                            <Button style={{ marginInlineEnd: '10px' }} size="small" variant="contained" color="success" onClick={() => { addFormBuilder(row) }} >Add Field </Button>
                                            <Button size="small" variant="contained" color="success" onClick={() => { viewForm(row) }} >View Form </Button> */}
                                            </TableCell>



                                        </TableRow>

                                        {
                                            form.InputValue.map((row, rowIndex) => (
                                                <TableRow align="center" key={rowIndex}>
                                                    {
                                                        row.map(cellData => (
                                                            <TableCell style={{ textAlign: 'center' }} key={cellData.id}>{cellData.value}</TableCell>
                                                        ))
                                                    }
                                                    <TableCell  style={{ padding: '5px 15px', textAlign: 'center' }}>

                                                        <EditIcon onClick={() => { editFormData(row) }} />
                                                        <DeleteIcon />

                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        }
                                        {/* ))} */}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    }

                </div>
            </div>
        </>
    )


}



export default CustomForm