import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import ELogo from "../../assets/images/aes-logo.png";
import ELogo1 from "../../assets/images/aes-logo.png";
import Mico1 from "../../assets/images/sailor/dash-icon.svg";
import Mico2 from "../../assets/images/sailor/visitor.png"
import Visitors from "../../assets/images/sailor/visitor.png";
import Dashboard from "../../assets/images/sailor/dashboard.png";
import Report from "../../assets/images/sailor/report.png";
import Company from "../../assets/images/sailor/company.png";
import Logout from "../../assets/images/sailor/logout.png";
import Gate from "../../assets/images/sailor/gate.png";
import Employee from "../../assets/images/sailor/employee.png";
import Staff from "../../assets/images/sailor/staff1.png";
import Dep from "../../assets/images/sailor/departments.png"
import NoteContext from "../../context/NoteContext";
import { config } from "../../util/apiconfig";
import swal from 'sweetalert';
import axios from 'axios';
import Swal1 from 'sweetalert2';

function AgentMenu() {
    const obj = useContext(NoteContext);
    const navigate = useNavigate();
    //console.log(localStorage, 'local');
    const getSessionData = () => {
        const agentToken = localStorage.getItem('AgentToken');
        const userType = localStorage.getItem('Type');
        if ((agentToken == "" || agentToken == null) && (userType != "Admin" || userType != "Agent")) {
            localStorage.clear();
            navigate("/");

        }
    }
    useEffect(() => {
        getSessionData();
    }, [])

    useEffect(() => {

        const roleid=obj.type;
        if(roleid !== 0 && roleid !== 1)
            {
               // checkforVisitors();
                            // Set up interval to call checkVisitor every 10 minutes
                            const intervalId = setInterval(checkforVisitors, 1 * 60 * 1000); // 10 minutes
                            // Clean up interval on component unmount
                            return () => clearInterval(intervalId);

            }


      }, []);

async function checkforVisitors() {
      try {
        const company_data = {
          "company_code": localStorage.getItem('company_code'),
         
        }
        await axios.post(config.mongo_endpoint + 'api/v1/visitor/visitorwithoutcheckin', company_data, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization':obj.AgentToken,
          }
        })
          .then(result => {
            //console.log(result.data.count,'vc')
            if (result.status === 200 && result.data.status === "Success" && result.data.count > 0) {
                const visitorcount=result.data.count;
                // swal({
                //     title: `Visitors Waiting: ${visitorcount}`,
                //     text: "Please attend to the visitors who are currently waiting.",
                //     icon: "warning",
                //     buttons: {
                //         visitors: {
                //             text: "Visitors",
                //             value: true,
                //             visible: true,
                //             className: "btn-visitors",
                //             closeModal: true
                //         }
                //     },
                //     dangerMode: true,
                //     closeOnClickOutside: false,
                //     closeOnEsc: false,
                // })
                //     .then((willallow) => {
                //         if (willallow) {
                //             navigate("/ManageVisitors");
                            
                //         }
                //     });  

                    
                    Swal1.fire({
                        title: 'Visitors Waiting',
                        html: `<strong>${visitorcount} Visitor(s) are Waiting !</strong><br><br>Please attend to the visitors promptly..`,
                        // html: "3 Visitor(s) Waiting at AES Company -GATE-01",
                        icon: 'warning',
                        showCancelButton: false,
                        confirmButtonText: 'Visitors'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            // Handle confirmation
                            console.log('User confirmed');
                            navigate("/WaitingVisitors");
                        }
                    });

                    
      
            } else {
      
            }
          })
      
      }
      catch (error) {
        console.error(error);
        console.log(error.code);
      
      }
    }


    function logout() {
        // alert();
        swal({
            title: "Are you sure you want to logout?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    localStorage.clear()
                    obj.setToken('')
                    obj.setName('');
                    navigate("/");
                    swal("Logout Success....!", {
                        icon: "success",
                    });
                }
            });

    }


    return (
        <>

            <div className="sidebar">

                <div className="det-bar">
                    <div className="main-title">
                        <Link to="/">
                            <img
                                src={ELogo1}
                                className="img-fluid logo-txt"
                                alt=""
                                width="100"
                                height="93"
                                style={{ display: 'block', margin: 'auto' }}
                            />
                        </Link>
                    </div>


                    <ul>
                        <li className="active">
                            <Link to="/VisitorDashboard" className="nav-link" >
                                <img src={Dashboard} alt="" className="m-ic" />
                                <span>Dashboard</span>
                            </Link>
                        </li>
                        {/* 
                        <li> 
                            <Link to="/Pendingonboarding" className="nav-link" >
                                <img src={Mico2} alt="" className="m-ic" /><span>Pending onboarding</span>
                            </Link>
                        </li>
                       
                        <li> 
                            <Link to="/Approvedonboarding" className="nav-link" >
                                <img src={Mico2} alt="" className="m-ic" /><span>Approved onboarding</span>
                            </Link>
                        </li> */}




                      
                        {obj.type === 0   &&
                            <li>
                                <Link to="/Roles" className="nav-link" >
                                    <img src={Mico1} alt="" className="m-ic" /><span>Roles</span>
                                </Link>
                            </li>
                        }
                        
                        {obj.type === 0 &&
                            <li>
                                <Link to="/ManageCompany" className="nav-link" >
                                    <img src={Company} alt="" className="m-ic" /><span>Company</span>
                                </Link>
                            </li>
                        }
                         {(obj.type === 0 || obj.type === 1) &&
                            <li>
                                <Link to="/Departments" className="nav-link" >
                                    <img src={Dep} alt="" className="m-ic" /><span>Department</span>
                                </Link>
                            </li>
                        }
                       

                        {(obj.type === 0  || obj.type === 1) &&
                            <li>
                                <Link to="/ManageGate" className="nav-link" >
                                    <img src={Gate} alt="" className="m-ic" /><span>Gate</span>
                                </Link>
                            </li>
                        }
                       
                        {(obj.type === 0 || obj.type === 1) &&
                            <li>
                                <Link to="/ManageAgent" className="nav-link" >
                                    <img src={Staff} alt="" className="m-ic" /><span>Staff</span>
                                </Link>
                            </li>
                        }
                        { (obj.type === 0 || obj.type === 1) &&
                            <li>
                                <Link to="/ViewUsers" className="nav-link" >
                                    <img src={Employee} alt="" className="m-ic" /><span>Department Staff</span>
                                </Link>
                            </li>
                        }
                          <li>
                            <Link to="/ManageVisitors" className="nav-link" >
                                <img src={Visitors} alt="" className="m-ic" /><span>Visitors</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/VisitorReport" className="nav-link" >
                                <img src={Report} alt="" className="m-ic" /><span>Reports</span>
                            </Link>
                        </li>
                        {/* <li>
                            <Link to="/VisitorCheckout" className="nav-link" >
                                <img src={Report} alt="" className="m-ic" /><span>Visitor Checkout</span>
                            </Link>
                        </li> */}
                        {/* <li> 
                            <Link to="/ApplicationConfig" className="nav-link" >
                                <img src={Mico2} alt="" className="m-ic" /><span>Application Config</span>
                                </Link>
                        </li>
                        <li> 
                            <Link to="/ManageForms" className="nav-link" >
                                <img src={Mico2} alt="" className="m-ic" /><span>Manage Forms</span>
                            </Link>
                        </li> */}




                        <li>
                            <Link to="#" class="nav-link" onClick={() => { logout() }} >
                                <img src={Logout} alt="" className="m-ic" /><span>Log Out</span>
                            </Link>
                        </li>


                    </ul>
                </div>

            </div>

        </>
    );
}

export default AgentMenu;