import React, { useState, useEffect, useContext } from "react";
import { Box } from "@mui/material";
import AgentMenu from "../../components/AgentMenu";
import NoteContext from "../../context/NoteContext";
import Button from '@mui/material/Button';
import { FormHelperText, Grid, TextField } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useForm } from "react-hook-form";
import 'react-phone-input-2/lib/material.css'
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import Navbar from "../Navbar";
import axios from 'axios';
import { config } from "../../util/apiconfig";
import { Country, State, City }  from 'country-state-city';
import swal from "sweetalert";
import handleApiError from "../../util/apiUtil";
const countrycitystateHandler = require('countrycitystatejson');

function UpdateCompany() {
    // const { AgentId } = match.params;
    // const isAddMode = !AgentId;
    // =================== Voter Info Tab-1 Validation  ================

    const sailorInformation = yup.object().shape({
        company_name: yup.string().required("Company Name Required"),
        // company_code: yup.string().required("Company Code Required"),
        country: yup.string().required("Country Name Required"),
        state: yup.string().required("State Name Required"),
        city: yup.string().required("City Name Required"),
        address: yup.string().required("Address Required"),
    })

    // =================== Voter Info Tab-1 Validation  ================
    // =================== Voter Info Yup Resolver Starts  ================
    const { register: sailorInfoHandler,
        handleSubmit: sailorInfoHandlerSubmit,
        reset: voterInfoReset,
        setValue,
        formState: { errors: sailorInfoErrors } } = useForm({
            resolver: yupResolver(sailorInformation),
            mode: 'OnSubmit',
        });

    //   //console.log(sailorInfoErrors)

    const [activeStep, setActiveStep] = React.useState(0);
    const [Agree, setAgree] = useState('')
    const [loader, setLoader] = useState(false)
    const [user, setUser] = useState({});
    const [country_list, setCountrylist] = useState([]);
    const [state_list, setStatelist] = useState([]);
    const [city_list, setCitylist] = useState([]);
    const [country, setCountry] = useState('');
    const [stateItem, setStateItem] = useState('');
    const [cityItem, setCityItem] = useState('');
    const [updatedata, setUpdatedata] = useState('');
    const [activestatus, setActivestatus] = useState('');
 
    // useEffect(() => {
    //     getCountryList();
    //     getStateList();
    //     getCityList();
    // }, []);  

    const [countrycode, setCountrycode] = useState();
    const [citycode, setCitycode] = useState();
    
    
    
    let countryData = Country.getAllCountries();
    //console.log(countryData)


    const [stateData, setStateData] = useState([]);
    const [cityData, setCityData] = useState([]);  

    const obj = useContext(NoteContext)
    const [data, setData] = useState([]);
    const [update_company, setUpdate_company] = useState(obj.update_company)
    //console.log("UPDATECOMPANY=>", update_company)


    const navigate = useNavigate();
    const LandingScreen = () => {
        navigate("/ManageCompany");
    }

    useEffect(()=>{
        setValue('company_name',update_company.company_name);
        setValue('company_code',update_company.company_code);
        setUpdatedata(update_company.company_code);
        setValue('country',update_company.country);
        setValue('state',update_company.state);
        setValue('city',update_company.city);
        setValue('address',update_company.address);
        setCountry(update_company.country);
        setStateItem(update_company.state);
        setCityItem(update_company.city);
        setValue('active_status',update_company.active_status);
        setActivestatus(update_company.active_status);      
        const datacountry = update_company.country.split("|");
        //console.log(datacountry)
        setCountrycode(datacountry[0]);
        setStateData(State.getStatesOfCountry(datacountry[0]));
        const datastate = update_company.state.split("|");     
        setCityData(City.getCitiesOfState(datacountry[0], datastate[0]));
        setCityItem(update_company.city);             
    }, [])  

    //console.log(country, stateItem, cityItem)



    const onSubmit = async (data) => {
        try {
            const companyInformation = {
                companyName: data["company_name"],
                companyCode: updatedata,
                country: data["country"],
                state: data["state"],
                city: data["city"],
                address: data["address"],
                userId: localStorage.getItem('userId'),
                activeStatus:activestatus,            
            }
            //console.log(companyInformation)
           
            await axios.post(config.mongo_endpoint+'api/v1/company/update', companyInformation, { headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'x-access-token':obj.AgentToken,'Authorization':obj.AgentToken} })
          .then(result => {
              //console.log(result);
              if(result.status == 200 && result.data.status == 'Success'){
                //   toast.success("CompanyInformation Updated Successfully.", {
                //     position: toast.POSITION.TOP_CENTER,
                //     theme: "colored",
                //   });  
                swal("Company Information updated Successfully....!", {
                    icon: "success",
                });
                  LandingScreen();
                } else {
                //   toast.success("Duplicate Data Exist", {
                //     position: toast.POSITION.TOP_CENTER,
                //     theme: "colored",
                //   }); 
                  swal("Duplicate Data Exist....!", {
                    icon: "warning",
                }); 
                }
          })        
  
            
  
                  
                  
         }
         catch (error) {
          //console.error(error);
          // if (error)
          setLoader(false);
          handleApiError(error,navigate)
          if (error) {
            //alert("Something went wrong. Please try again Later....")
          }
          //console.log(error.code);
         
        }      

    }


    // const getCountryList = () => {
    //     let data = countrycitystateHandler.getCountries();
    //     //console.log(data);
    //     let obj = {};
    //     let countryListData = [];
    //     data.forEach((country, index) => {
    //         obj = {
    //             value: `${country.name}`,
    //             label: `${country.name}`
    //         };
    //         countryListData.push(obj);
    //     });
    //     setCountrylist(countryListData);
    // }

    // const getStateList = () => {
    //     let data = countrycitystateHandler.getStatesByShort('IN');
    //     //console.log(data);
    //     let obj = {};
    //     let stateListData = [];
    //     data.map((item, index) => {
    //         obj = {
    //             value: `${item}`,
    //             label: `${item}`
    //         };
    //         stateListData.push(obj);
    //     })
    //     setStatelist(stateListData);
    // }

    // const getCityList = () => {
    //     let data = countrycitystateHandler.getCities('IN', 'Tamil Nadu');
    //     //console.log(data);
    //     let obj = {};
    //     let cityData = [];
    //     data.map((item, index) => {
    //         obj = {
    //             value: `${item}`,
    //             label: `${item}`
    //         };
    //         cityData.push(obj);
    //     })
    //     setCitylist(cityData);
    // }    
   

    // const handleCountry = (event) =>{
    //     const datacountry = event.target.value.split("|")
    //     //console.log(datacountry)
    //     setCountrycode(datacountry[0]);
    //     setStateData(State.getStatesOfCountry(datacountry[0]))
    // }
    // //console.log(stateData)
    
    
    // const handleState = (event) =>{
    //     const datastate = event.target.value.split("|")     
    //     setCityData(City.getCitiesOfState(countrycode, datastate[0])); 
    //     //console.log(City.getCitiesOfState(countrycode, datastate[0])); 
    // }
    // //console.log(cityData)
    
    
    
    
    // const handleCity = (event) =>{
    //     //console.log(event)
    //     setCitycode(event);
    // }


    const handlecountrychange = (event) =>{
     setCountry(event.target.value)
     //console.log(event.target.value)
     const datacountry = event.target.value.split("|")
        //console.log(datacountry)
        setCountrycode(datacountry[0]);
        setStateData(State.getStatesOfCountry(datacountry[0]))                                                                                                                                               

    }

    const handlestatechange = (event) =>{
        setStateItem(event.target.value)
        //console.log(event.target.value)      
        const datastate = event.target.value.split("|")     
        setCityData(City.getCitiesOfState(countrycode, datastate[0])); 
        //console.log(City.getCitiesOfState(countrycode, datastate[0]));       
    }

    const handlecitychange = (event) =>{        
        setCitycode(event);
        setCityItem(event.target.value)
    }



    return (
        <>
            <AgentMenu></AgentMenu>
            <div className="container-fluid pr-0" id="main-area">
                <Navbar />
                <div className="container-fluid">
                    <div className="d-flex border-bottom pb-15 pt-15">
                        <div className="me">
                            <h2 className="page-title">Update Company</h2>
                        </div>

                    </div>
                    <section className="voting-section">
                        <div className="container">
                            <div className="row">

                                {/* <h1>Voting</h1> */}
                                <Box sx={{ width: '100%' }}>
                                    <>
                                        <div className="mto stepper-content"> 

                                            <form onSubmit={sailorInfoHandlerSubmit(onSubmit)}>
                                                <Box mb={3.5}>
                                                    <Grid container spacing={3} marginTop={1}>
                                                        <Grid item sm={3} xs={12}>
                                                        <input type="hidden" name="active_status" value="hiddenValue" {...sailorInfoHandler("active_status")} />
                                                            <TextField
                                                                fullWidth
                                                                size="small"
                                                                type="text"                                                               
                                                                name="company_name"
                                                                label="Company Name *"
                                                                {...sailorInfoHandler("company_name")}
                                                                id="outlined-error"
                                                                error={!!sailorInfoErrors['company_name']}
                                                                helperText={sailorInfoErrors['company_name'] ? sailorInfoErrors['company_name'].message : ''}
                                                            />
                                                        </Grid>

                                                        {/* <Grid item sm={3} xs={12}>
                                                            <TextField
                                                                fullWidth
                                                                size="small"
                                                                type="text"                                                                
                                                                name="company_code"
                                                                label="Company Code"
                                                                {...sailorInfoHandler("company_code")}
                                                                id="outlined-error"                                                                
                                                                error={!!sailorInfoErrors['company_code']}
                                                                helperText={sailorInfoErrors['company_code'] ? sailorInfoErrors['company_code'].message : ''}
                                                            />
                                                        </Grid> */}
                                                        <Grid item sm={3} xs={12}>
                                                            <FormControl fullWidth>
                                                                <InputLabel id="demo-simple-select-label">Country *</InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    size="small"
                                                                    name="country"
                                                                    value={country}
                                                                    label="Country *"
                                                                    {...sailorInfoHandler("country")}
                                                                    error={!!sailorInfoErrors['country']}
                                                                    helperText={sailorInfoErrors['country'] ? sailorInfoErrors['country'].message : ''}
                                                                    // onChange={(country)=>{
                                                                    //     setCountry(country.target.value)                                                                                                                                                
                                                                    // }}
                                                                    onChange={handlecountrychange}                                                                   
                                                                >
                                                                    {/* <MenuItem value="Country">Country</MenuItem> */}
                                                                    {
                                                                        countryData.map((list) => (                                                                           
                                                                            <MenuItem value={`${list.isoCode}|${list.name}`}>{list.name}</MenuItem>
                                                                        ))
                                                                    }

                                                                </Select>
                                                                {sailorInfoErrors.country && <FormHelperText error={!!sailorInfoErrors['country']}>{sailorInfoErrors.country.message}</FormHelperText>}
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item sm={3} xs={12}>
                                                            <FormControl fullWidth>                                                                                                                  
                                                                <InputLabel id="demo-simple-select-label">State *</InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    size="small"
                                                                    name="state"
                                                                    value={stateItem}                                                                  
                                                                    label="State *"
                                                                    {...sailorInfoHandler("state")}
                                                                    error={!!sailorInfoErrors['state']}
                                                                    helperText={sailorInfoErrors['state'] ? sailorInfoErrors['state'].message : ''}                                                                    
                                                                    // onChange={(state)=>{
                                                                    //     setStateItem(state.target.value)
                                                                    // }}
                                                                    onChange={handlestatechange}
                                                                >
                                                                    {/* <MenuItem value="Country">State</MenuItem> */}
                                                                    {
                                                                        stateData.map((list) => (
                                                                            <MenuItem value={`${list.isoCode}|${list.name}`}>{list.name}</MenuItem>
                                                                        ))
                                                                    }

                                                                </Select>
                                                                {sailorInfoErrors.state && <FormHelperText error={!!sailorInfoErrors['state']}>{sailorInfoErrors.state.message}</FormHelperText>}
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item sm={3} xs={12}>
                                                            <FormControl fullWidth>                                                                                                                   
                                                                <InputLabel id="demo-simple-select-label">City *</InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    size="small"
                                                                    name="city"
                                                                    value={cityItem}                                                                
                                                                    label="City *"
                                                                    {...sailorInfoHandler("city")}
                                                                    error={!!sailorInfoErrors['city']}
                                                                    helperText={sailorInfoErrors['city'] ? sailorInfoErrors['city'].message : ''}                                                                  
                                                                    // onChange={(city)=>{
                                                                    //     setCityItem(city.target.value)
                                                                    // }}
                                                                    onChange={handlecitychange}
                                                                >
                                                                    {/* <MenuItem value="City">City</MenuItem> */}
                                                                    {
                                                                        cityData.map((list) => (                                                                          
                                                                            <MenuItem value={`${list.name}`}>{list.name}</MenuItem>
                                                                        ))
                                                                    }

                                                                </Select>
                                                                {sailorInfoErrors.city && <FormHelperText error={!!sailorInfoErrors['city']}>{sailorInfoErrors.city.message}</FormHelperText>}
                                                            </FormControl>
                                                        </Grid>                                                                                                              
                                                    </Grid>

                                                    <Grid container spacing={3} marginTop={1}>                                                                                            
                                                        <Grid item sm={6} xs={12}>
                                                            <TextField
                                                                fullWidth
                                                                name="address"                                                               
                                                                label="Address*"
                                                                multiline
                                                                minRows={3}
                                                                maxRows={10}
                                                                {...sailorInfoHandler("address")}
                                                                id="outlined-error"
                                                                error={!!sailorInfoErrors['address']}
                                                                helperText={sailorInfoErrors['address'] ? sailorInfoErrors['address'].message : ''}
                                                            />
                                                        </Grid>

                                                    </Grid>

                                                </Box>

                                                <div sx={{ display: 'flex', flexDirection: 'row', padding: '10px 10px', background: "#f5f5f5" }}>

                                                    <Button type="submit" variant="contained" color="success" sx={{ marginTop: "20px", marginLeft: "18px" }}>
                                                        SUBMIT
                                                    </Button>
                                                </div>
                                            </form>


                                        </div>


                                    </>

                                </Box>
                            </div>
                        </div>
                    </section>

                </div>
            </div>
        </>
    );
}

export default UpdateCompany;