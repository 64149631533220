import React, { useState } from "react";
import AgentMenu from "../../components/AgentMenu";
import Navbar from "../Navbar";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { ReactFormGenerator } from 'react-form-builder2';
import { config } from "../../util/apiconfig";
import swal from 'sweetalert';
import axios from 'axios';

function AddForm() {
    const location = useLocation();
    const [formData, setFormData] = useState([])
    const [formValuesData, setFormValuesData] = useState([])

    useEffect(() => {
        let rec = fetch(config.mongo_endpoint + `api/v1/manageForm/inputFields/${location.state.Id}`).then(resp => resp.json()).then(resp => {
            setFormData(resp.InputFields)
            setFormValuesData(resp.InputValue)
           
        })
        console.log(formValuesData)
    }, [])


    const handleSubmit = (data) => {
        const Id = location.state.Id;

        const inputValue = { data }
        axios.post(`${config.mongo_endpoint}api/v1/manageForm/inputValues/${Id}`, inputValue, { headers: { 'Content-Type': 'application/json' } })
            // .then(result => {
            //     console.log(result);
            .then((response) => {
                if (response.data.status == 'Success') {
                    swal("Saved Successfully...!", {
                        icon: "success",
                    });
                    navigate(-1)
                } else {
                    swal("Failed...!", {
                        icon: "error",
                    });
                }

            });

        // })

    }

    const navigate = useNavigate();

    return (
        <>

            <AgentMenu></AgentMenu>

            <div class="container-fluid pr-0" id="main-area">
                <Navbar />
                <div className="container-fluid">
                    <div class="d-flex border-bottom pb-15 pt-15">
                        <div class="me-auto ">
                            <h2 class="page-title"> {'Add ' + location.state.FormName}</h2>
                        </div>
                        <pre>{JSON.stringify(formValuesData)}</pre>
                    </div>
                   
                    <ReactFormGenerator
                        download_path=""
                        back_action="/"
                         back_name="Back"
                        answer_data={formValuesData}
                        action_name="Save"
                        // form_action="/"
                        // form_method="POST"
                        onSubmit={handleSubmit}
                         data={formData} 
                        />
                </div>
            </div>
        </>
    )

}

export default AddForm