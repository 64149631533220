export const config = {
"mongo_endpoint" : "https://vms.aesproducts.com/vmsapi",
//"mongo_endpoint" : "http://localhost:5019/",
//"mongo_endpoint" : "http://3.108.132.228:5019/",
"aadhar_api" : "http://3.108.132.228:5008",
"voter_api" : "http://3.108.132.228:5009",
"face_match_api": "http://3.108.132.228:5010",
"geo_api":"http://3.108.132.228:5011",
"passport_api":"http://3.108.132.228:5006",
"secret_key":"xrNCa3I2sHkR6Ha7ApB6H4NvVbJB8Oas1ZGrYVfbYZQ=",
// "imagePath":"http://3.108.132.228:5013/",
"imagePath" : "http://localhost:5013/",
"companyCode":"C00001",

}